import React from 'react';
import './HeroTwo.css';

const HeroSection =  ({ onScrollToProjectOptimization, onScrollToValueProposition }) => {
  return (
    <div className='hero-wrap'> 
    <div className="hero-container21">
      <h1>Create your AI Brief,</h1>
      <h1>Tap the world’s top 1% of talent,</h1>
      <h1>Deliver breakthrough results.</h1>

      <div className="hero-buttons21">
        <button className="use-moccet-ai21" onClick={onScrollToProjectOptimization}>Use Moccet AI</button>
        <button className="learn-more21" onClick={onScrollToValueProposition}>Learn More</button>
      </div>
    </div>
    </div>
  );
};

export default HeroSection;
