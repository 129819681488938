import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./AboutMoccet.css";

const AboutMoccet = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="aboutMoccetContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="aboutHeaderSection">
          <h1 className="aboutPageTitle">ABOUT MOCCET</h1>
        </div>

        {/* Origins & Vision */}
        <section className="originsSection">
          <h2 className="sectionSubtitle">Origins &amp; Vision</h2>
          <p className="sectionText">
            Moccet began as a Harvard Innovation Labs initiative to reinvent the way
            companies manage projects: from concept ideation to final milestone.
            Rather than offering yet another “filter-based” freelance site, we built
            an AI-driven ecosystem that handles scoping, pricing, coordination, and
            oversight in one place. Our mission is to ensure that any bold idea can
            quickly become a well-structured endeavor.
          </p>
        </section>

        {/* Core Principles */}
        <section className="corePrinciplesSection">
          <h2 className="sectionSubtitle">Core Principles</h2>
          <ul className="principlesList">
            <li>
              <strong>Integrity &amp; Innovation:</strong> We combine rigorous
              compliance with U.S. law (including relevant labor and consumer
              statutes) with cutting-edge AI features.
            </li>
            <li>
              <strong>Trust &amp; Transparency:</strong> We commit to fair rates and
              proactive disclaimers, so users know exactly what they’re getting.
            </li>
            <li>
              <strong>High-Talent &amp; High-Impact:</strong> Our curated network of
              specialists is selected for domain excellence. We disclaim liability
              for incomplete or unsatisfactory deliverables, but we strive to provide
              top-level professionals.
            </li>
          </ul>
        </section>

        {/* Legal Compliance */}
        <section className="aboutLegalSection">
          <h2 className="sectionSubtitle">Legal Compliance</h2>
          <p className="sectionText">
            Moccet is a U.S.-based entity that respects data protection laws,
            including certain state privacy regulations (where applicable). Users are
            responsible for using the platform in accordance with local, state,
            federal, and international laws relevant to their project’s nature.
          </p>
        </section>

        {/* Our Future */}
        <section className="futureSection">
          <h2 className="sectionSubtitle">Our Future</h2>
          <p className="sectionText">
            We plan to expand AI capabilities while refining security. However, we
            disclaim any forward-looking statements regarding performance, adoption,
            or success under the safe harbor protections of the Private Securities
            Litigation Reform Act of 1995.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutMoccet;
