import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Changed BrowserRouter to HashRouter
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import SignIn from './components/auth/SignIn';
import PaymentPage from './components/stripe/PaymentPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import StudentProtectedRoute from './components/auth/StudentProtectedRoute';
import BusinessProtectedRoute from './components/auth/BusinessProtectedRoute';
import ProjectBoard from './components/projects/ProjectBoard';
import BusinessDash from './pages/BusinessDash';
import ApplicationsList from './components/projects/ApplicationsList';
import BusinessChatPage from './components/chat/BusinessChatPage';
import StudentChatPage from './components/chat/StudentChatPage';
import StudentChats from './components/chat/StudentChats';
import StudentDash from './pages/StudentDash';
import BusinessChats from './components/chat/BusinessChats';
import MergedSignUp from './components/auth/MergedSignUp';
import StudentProfile from './pages/StudentProfile';
import BusinessProfile from './pages/BusinessProfile';
import MyStudentProjects from './pages/MyStudentProjects';
import ConfirmOptimization from './components/projects/ConfirmOptimization';
import SetBudget from './components/projects/SetBudget';
import SuccessPage from './components/projects/SuccessPage';
import MyBusinessProjects from './components/projects/MyBusinessProjects';
import ProjectOptimization from './components/projects/ProjectOptimization';
import BusinessSignUp from './components/auth/BusinessSignUp';
import Booking from './components/dashboards/Booking';
import ReferAFriend from './pages/ReferAFriend';
import BlogList from './pages/BlogList';
import BlogDetail from './pages/BlogDetail';
import TsAndCs from './pages/TsandCs';
import { HelmetProvider } from 'react-helmet-async';
import NewsDashboard from './pages/NewsDashboard';
import Features from './components/Footer/Features';
import Integrations from './components/Footer/Integrations';
import CaseStudies from './components/Footer/CaseStudies';
import APIReference from './components/Footer/APIReference';
import AboutMoccet from './components/Footer/AboutMoccet';
import Careers from './components/Footer/Careers';
import ContactUs from './components/Footer/ContactUs';
import Documentation from './components/Footer/Documentation';
import Guides from './components/Footer/Guides';
import Partners from './components/Footer/Partners';
import Press from './components/Footer/Press';
import Responsible from './components/Footer/Responsible';
import Security from './components/Footer/Security';
import Compliance from './components/Footer/Compliance';
import NotificationsPage from './pages/NotificationsPage';
import TalentSkills from './pages/TalentSkills';
import GlobalCursor from './components/GlobalCursor';
import './App.css';
import UploadCV from './UploadCV';
import BuildProfile from './pages/BuildProfile';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <HelmetProvider>
    <Router>
    <GlobalCursor />
      <NavBar />
        <Routes>
          <Route path="/project-optimization" element={<ProjectOptimization />} />
          <Route path="/confirm-optimization" element={<ConfirmOptimization />} />
          <Route path="/set-budget" element={<SetBudget />} />
          <Route path="/success" element={<BusinessProtectedRoute><SuccessPage /></BusinessProtectedRoute>} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/myprojects" element={<MyStudentProjects/>}/>
          <Route path="/signup" element={<MergedSignUp/>}/>
          <Route path="/business-signup" element={<BusinessSignUp />} />
          <Route path="/payment" element={<ProtectedRoute><Elements stripe={stripePromise}><PaymentPage /></Elements></ProtectedRoute>} />
          <Route path="/student-dashboard" element={<StudentProtectedRoute><StudentDash /></StudentProtectedRoute>} />
          <Route path="/student-chats" element={<StudentProtectedRoute><StudentChats /></StudentProtectedRoute>} />
          <Route path="/student-profile" element={<StudentProtectedRoute><StudentProfile /></StudentProtectedRoute>} />
          <Route path="/my-projects" element={<StudentProtectedRoute><MyStudentProjects /></StudentProtectedRoute>} />
          <Route path="/project-board" element={<StudentProtectedRoute><ProjectBoard /></StudentProtectedRoute>} />
          <Route path="/business-profile" element={<BusinessProtectedRoute><BusinessProfile /></BusinessProtectedRoute>} />
          <Route path="/business-dashboard" element={<BusinessProtectedRoute><BusinessDash /></BusinessProtectedRoute>} />
          <Route path="/business-projects" element={<BusinessProtectedRoute><MyBusinessProjects /></BusinessProtectedRoute>} />
          <Route path="/applications" element={<BusinessProtectedRoute><ApplicationsList /></BusinessProtectedRoute>} />
          <Route path="/chat/:chatId" element={<BusinessProtectedRoute><BusinessChatPage /></BusinessProtectedRoute>} />
          <Route path="/business-chats" element={<BusinessProtectedRoute><BusinessChats /></BusinessProtectedRoute>} />
          <Route path="/student-chat/:chatId" element={<StudentProtectedRoute><StudentChatPage /></StudentProtectedRoute>} />
          <Route path="/blogs" element={<BlogList />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        {/* Possibly a redirect or default path */}
        <Route path="*" element={<BlogList />} />
          <Route path="/refer-a-friend" element={<ReferAFriend/>}/>
          <Route path="/terms" element={<TsAndCs />} />
          <Route path="/news" element={<NewsDashboard />} /> 
          <Route path="/features" element={<Features />} /> 
          <Route path="/integrations" element={<Integrations />} /> 
          <Route path="/case-studies" element={<CaseStudies />} /> 
          <Route path="/API-reference" element={<APIReference />} /> 
          <Route path="/about" element={<AboutMoccet />} /> 
          <Route path="/careers" element={<Careers />} /> 
          <Route path="/contact-us" element={<ContactUs />} /> 
          <Route path="/documentation" element={<Documentation />} /> 
          <Route path="/guides" element={<Guides />} /> 
          <Route path="/partners" element={<Partners />} /> 
          <Route path="/press" element={<Press />} /> 
          <Route path="/responsible" element={<Responsible />} /> 
          <Route path="/security" element={<Security />} /> 
          <Route path="/compliance" element={<Compliance />} /> 
          <Route path="/notifications" element={<NotificationsPage />} /> 
          <Route path="/talent-skills" element={<TalentSkills />} /> 
          <Route path="/uploadcv" element={<UploadCV />} /> 
          <Route path="/buildprofile" element={<BuildProfile />} />
        </Routes>
    </Router>
    </HelmetProvider>
  );
}

export default App;
