import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap"; // <-- GSAP for animations
import "./CaseStudies.css";

const CaseStudies = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);
  const caseCardsRef = useRef([]); // Will store refs for each case study card

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      // Fade in the entire main content
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );

      // Stagger each case card
      gsap.fromTo(
        caseCardsRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
          stagger: 0.1, // each card animates 0.1s apart
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="caseStudiesContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="caseHeaderSection">
          <h1 className="casePageTitle">CASE STUDIES</h1>
        </div>

        {/* Overview */}
        <section className="caseOverviewSection">
          <h2 className="sectionSubtitle">Overview</h2>
          <p className="sectionText">
            Moccet’s Case Studies page highlights scenarios—some real, some
            illustrative—where organizations utilized our platform to accelerate
            or upgrade projects. These examples help potential users understand
            best practices while seeing Moccet’s potential benefits firsthand.
          </p>
        </section>

        {/* Fictional or Real Stories */}
        <section className="caseStoriesSection">
          <h2 className="sectionSubtitle">Fictional or Real Stories</h2>

          {/* Case Study 1 */}
          <div
            className="caseCard"
            ref={(el) => (caseCardsRef.current[0] = el)}
          >
            <h3 className="caseTitle">1. Software Launch at a Global Enterprise</h3>
            <p className="caseBefore">
              <strong>Before:</strong> Disorganized scoping, unpredictable
              budgets.
            </p>
            <p className="caseAfter">
              <strong>After:</strong> Moccet’s AI cut planning time by 40%,
              enabling them to deliver a pilot in 6 weeks instead of 10.
            </p>
            <p className="caseDisclaimer">
              <strong>Disclaimer:</strong> The success metrics reported are
              either user-supplied or approximate. We do not guarantee similar
              outcomes for other organizations.
            </p>
          </div>

          {/* Case Study 2 */}
          <div
            className="caseCard"
            ref={(el) => (caseCardsRef.current[1] = el)}
          >
            <h3 className="caseTitle">2. Niche Startup’s MVP</h3>
            <p className="caseProblem">
              <strong>Problem:</strong> Inexperienced founders needed top-tier
              devs and quick cost clarity.
            </p>
            <p className="caseSolution">
              <strong>Solution:</strong> Automated scoping, plus a curated
              micro-team of 2 specialists.
            </p>
            <p className="caseOutcome">
              <strong>Outcome:</strong> Secure test release in a fraction of the
              expected time.
            </p>
            <p className="caseLiabilityNote">
              <strong>Liability Note:</strong> Projects can fail for reasons
              beyond Moccet’s oversight. Past performance does not guarantee
              future results.
            </p>
          </div>
        </section>

        {/* Confidentiality & Permissions */}
        <section className="caseConfidentialitySection">
          <h2 className="sectionSubtitle">Confidentiality &amp; Permissions</h2>
          <p className="sectionText">
            No confidential or proprietary data from clients is published
            without explicit written authorization, consistent with 18 U.S.C.
            §§ 1831–1839 (trade secret protections).
          </p>
        </section>

        {/* General Disclaimers */}
        <section className="caseDisclaimersSection">
          <h2 className="sectionSubtitle">General Disclaimers</h2>
          <p className="sectionText">
            These narratives are not promises of success or financial gain; each
            project depends on user diligence, adherence to regulatory mandates,
            and alignment with market conditions.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CaseStudies;
