import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./Responsible.css";

const Responsible = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="responsibleContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="responsibleHeaderSection">
          <h1 className="responsiblePageTitle">RESPONSIBLE USE</h1>
        </div>

        {/* Philosophy */}
        <section className="philosophySection">
          <h2 className="sectionSubtitle">Philosophy</h2>
          <p className="sectionText">
            Our Responsible Use guidelines exist to keep Moccet’s environment
            healthy, legal, and fair for all participants—clients, experts, and
            staff. By accessing or contributing to the platform, you affirm
            acceptance of these rules.
          </p>
        </section>

        {/* Prohibited Conduct */}
        <section className="prohibitedConductSection">
          <h2 className="sectionSubtitle">Prohibited Conduct</h2>

          <div className="prohibitedItem">
            <h3 className="prohibitedTitle">Illegal or Unethical Activities</h3>
            <p className="prohibitedDescription">
              No user may employ Moccet for tasks violating civil, criminal, or
              regulatory statutes, including but not limited to fraud, illicit
              data scraping, or facilitating hacking.
            </p>
          </div>

          <div className="prohibitedItem">
            <h3 className="prohibitedTitle">Harassment or Discrimination</h3>
            <p className="prohibitedDescription">
              Hate speech, threats, or systematic harassment based on protected
              characteristics are banned. Violations result in immediate account
              suspension.
            </p>
          </div>

          <div className="prohibitedItem">
            <h3 className="prohibitedTitle">Privacy Breaches</h3>
            <p className="prohibitedDescription">
              Do not upload or store personal data about third parties without
              consent, or attempt to gain unauthorized access to others’ Moccet
              accounts.
            </p>
          </div>
        </section>

        {/* Moderation & Enforcement */}
        <section className="moderationEnforcementSection">
          <h2 className="sectionSubtitle">Moderation &amp; Enforcement</h2>
          <p className="sectionText">
            Moccet may remove content or suspend accounts unilaterally if it
            deems usage violates these rules. We disclaim liability for any lost
            opportunities, business disruption, or other damages tied to user
            removal.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Responsible;
