import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./ContactUs.css";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="contactUsContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="contactUsHeaderSection">
          <h1 className="contactUsPageTitle">CONTACT US</h1>
        </div>

        {/* How to Reach Us */}
        <section className="contactReachSection">
          <h2 className="sectionSubtitle">How to Reach Us</h2>
          <p className="sectionText">
            For customer support, implementation questions, or feedback, please
            email <a href="mailto:info@moccet.com">info@moccet.com</a>. We aim to
            answer within 1–2 business days, but no guarantee of response time is
            implied or offered.
          </p>
        </section>

        {/* Scope of Assistance */}
        <section className="contactScopeSection">
          <h2 className="sectionSubtitle">Scope of Assistance</h2>
          <ul className="contactScopeList">
            <li>Clarifications on platform usage or account settings</li>
            <li>Technical troubleshooting or bug reports</li>
            <li>
              General guidance on best practices, always subject to disclaimers
              that Moccet is not a law firm or consulting agency
            </li>
          </ul>
        </section>

        {/* Data Handling */}
        <section className="contactDataSection">
          <h2 className="sectionSubtitle">Data Handling</h2>
          <p className="sectionText">
            Any message or materials you send become subject to our Privacy
            Policy. Although we endeavor to keep communication confidential, we
            cannot be responsible for transmissions that occur over unsecured
            networks, user-end vulnerabilities, or force majeure events.
          </p>
        </section>

        {/* No Formal Advice */}
        <section className="contactNoAdviceSection">
          <h2 className="sectionSubtitle">No Formal Advice</h2>
          <p className="sectionText">
            Emails from Moccet support are purely for user assistance. They do
            not constitute legal, financial, or compliance advice.
          </p>
        </section>
      </div>
    </div>
  );
};

export default ContactUs;
