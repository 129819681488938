import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MarketingVid.css';
import backgroundVideo from '../../videos/moccet1.mp4'; // Import the video file

const Marketing = () => {
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div className={`dashboard-container1234 ${loaded ? 'loaded' : ''}`}>
      {/* Background Video */}
      <video autoPlay loop muted playsInline className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className='d123'>
      <div className={`upper-section11`}>
        <div className="welcome-message11">
          <h2>
            It starts here
          </h2>
          <p>
          It starts here is our way of saying there’s no limit to what you can create when the path is clear and the best minds are ready to help.
          </p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Marketing;
