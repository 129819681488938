import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import './ProjectOptimization.css';
import arrow from '../../images/arrow1.png';

const API_BASE_URL =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

const placeholders = [
  'Describe your project.',
  'What’s your next big idea?',
  'Need an MVP for your startup?',
  'New marketing campaign?',
  'Looking for a web developer?',
  'Type your vision. It starts here.',
];

const ProjectOptimization = () => {
  const location = useLocation();
  const { businessProblem = '', description = '' } = location.state || {};

  const initialProjectDescription = [businessProblem, description]
    .filter(Boolean)
    .join('\n\n');

  const [projectDescription, setProjectDescription] = useState(initialProjectDescription);
  const [loading, setLoading] = useState(false);

  // For rotating placeholders
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // Track whether input is focused
  const [isFocused, setIsFocused] = useState(false);

  const navigate = useNavigate();

  // Cycle placeholders
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPlaceholderIndex((prev) => (prev + 1) % placeholders.length);
      }, 400);
      setTimeout(() => {
        setIsAnimating(false);
      }, 800);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // Handle focus & blur
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    // If user leaves the input box empty, show the placeholder again
    if (!projectDescription.trim()) {
      setIsFocused(false);
    }
  };

  // Handle "Optimize" logic
  const handleOptimize = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}/api/upload`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectDescription }),
      });
      const data = await response.json();
      const assistantResponse = data.assistantResponse || 'No response.';
      const summary = assistantResponse.substring(0, 100);

      const ownerId = auth.currentUser?.uid || '';
      const isCollaborating = false;
      const collaboratingBusinessId = null;
      const studentId = ownerId;
      const createdAt = new Date();

      navigate('/confirm-optimization', {
        state: {
          assistantResponse,
          projectDescription,
          summary,
          ownerId,
          isCollaborating,
          collaboratingBusinessId,
          studentId,
          createdAt,
        },
      });
    } catch (error) {
      console.error('Error optimizing:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle pressing Enter in the input
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      handleOptimize();
    }
  };

  // --- LOADING SCREEN ---
  if (loading) {
    return (
      <div className="loading-screen53">
        <div className="loading-innner"></div>
        {/* Header */}
        <div className="loading-header53">
          <h2 className="loading-brief-title53">/Your Brief</h2>
          <p className="loading-subtitle53">
            Powered by <span>Moccet AI</span>
          </p>
        </div>

        {/* Main prompt */}
        <h2 className="loading-prompt53">
          Please hold while Moccet’s AI gathers insights and refines <span>your</span> project
        </h2>

        {/* Dots animation */}
        <div className="dots-container53">
          <div className="dot53 dot1"></div>
          <div className="dot53 dot2"></div>
          <div className="dot53 dot3"></div>
          <div className="dot53 dot4"></div>
          <div className="dot53 dot5"></div>
        </div>

        {/* Info text */}
        <p className="loading-info53">
          Did you know Moccet’s AI integrates hundreds of data points and 
          in-depth market analysis to optimise your project, delivering 
          unparalleled value for you and maximising returns.
        </p>
      </div>
    );
  }

  // --- MAIN CONTENT ---
  return (
    <div className="project-optimization-container53">
      {/* Show big background text if there's input */}
      {projectDescription && (
        <div className="background-text53">
          {projectDescription}
        </div>
      )}

      <div className="heading-wrapper53">
        <h2 className="moccet-ai-title53">/Moccet AI</h2>
      </div>

      <div className="input-bubble53">
        <input
          type="text"
          className="single-line-input53"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
          spellCheck="true"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}  // <-- Press Enter to submit
        />

        {/* Placeholder logic:
            Show the placeholder only if there's NO user text AND the input isn't focused. */}
        {!isFocused && !projectDescription.trim() && (
          <div className="placeholder-wrapper53">
            <div className={`placeholder-text53 ${isAnimating ? 'animate' : ''}`}>
              {placeholders[currentPlaceholderIndex]}
            </div>
          </div>
        )}

        <button className="arrow-button53" onClick={handleOptimize}>
          <img src={arrow} alt="arrow" className="arrow123" />
        </button>
      </div>
    </div>
  );
};

export default ProjectOptimization;
