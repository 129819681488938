// src/components/NewsDashboard.jsx

import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from '../firebaseConfig'; // adjust to your Firebase config path

function NewsDashboard() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // Query the 'latestArticles' collection, optionally order by published date
        const articlesRef = collection(db, "latestArticles");
        const q = query(articlesRef, orderBy("publishedAt", "desc"));
        const querySnapshot = await getDocs(q);

        const fetched = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setArticles(fetched);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };
    fetchArticles();
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <h2>Latest Tech & Finance News</h2>
      {articles.map((article) => (
        <div key={article.id} style={{ border: "1px solid #ccc", padding: 12, marginBottom: 8 }}>
          <h3>{article.title}</h3>
          <p><strong>Source:</strong> {article.source}</p>
          <p>{article.description}</p>
          <p><strong>Published:</strong> {new Date(article.publishedAt).toLocaleString()}</p>
          {article.url && (
            <a href={article.url} target="_blank" rel="noreferrer">
              Read Full Article
            </a>
          )}
        </div>
      ))}
    </div>
  );
}

export default NewsDashboard;
