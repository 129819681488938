import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./Documentation.css";

const Documentation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="documentationContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="documentationHeaderSection">
          <h1 className="documentationPageTitle">DOCUMENTATION</h1>
        </div>

        {/* Intent & Usage */}
        <section className="documentationIntentSection">
          <h2 className="sectionSubtitle">Intent &amp; Usage</h2>
          <p className="sectionText">
            Our in-depth documentation clarifies how to use each Moccet feature or
            setting. While we aim for accurate, timely instructions, Moccet cannot
            warrant the completeness or reliability of such materials.
          </p>
        </section>

        {/* Scope of Coverage */}
        <section className="documentationScopeSection">
          <h2 className="sectionSubtitle">Scope of Coverage</h2>
          <ul className="documentationScopeList">
            <li>AI-based scoping workflows</li>
            <li>Budgeting methodology</li>
            <li>Talent matching best practices</li>
            <li>Collaboration tool instructions</li>
            <li>Common troubleshooting tips</li>
          </ul>
        </section>

        {/* Limitations */}
        <section className="documentationLimitationsSection">
          <h2 className="sectionSubtitle">Limitations</h2>
          <ul className="documentationLimitationsList">
            <li>Not official legal advice, tax guidance, or professional engineering counsel.</li>
            <li>
              Subject to revision or removal based on code updates, legislative
              changes, or product evolution.
            </li>
          </ul>
        </section>

        {/* User Discretion */}
        <section className="documentationUserDiscretionSection">
          <h2 className="sectionSubtitle">User Discretion</h2>
          <p className="sectionText">
            We encourage readers to treat the guidelines as foundational knowledge,
            verifying them against their own industry standards or consults with
            specialized advisors for compliance or licensing obligations.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Documentation;
