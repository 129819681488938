import React, { useEffect, useRef, useState } from 'react';
import './GlobalCursor.css';

/**
 * Determines whether a color string is "transparent" or has zero alpha.
 */
function isTransparent(color) {
  if (!color) return true;
  return (
    color === 'transparent' ||
    color.includes('rgba(0, 0, 0, 0)') ||
    /rgba?\(\d+,\s*\d+,\s*\d+,\s*0\)/.test(color)
  );
}

/**
 * Approximate whether an RGB color is dark or light.
 */
function isColorDark(color) {
  // Extract R, G, B from 'rgb(...)' or 'rgba(...)'
  const rgbaMatch = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(,\s*[\d.]+)?\)/);
  if (!rgbaMatch) {
    // Fall back to "light" if can't parse
    return false;
  }
  const r = parseInt(rgbaMatch[1], 10);
  const g = parseInt(rgbaMatch[2], 10);
  const b = parseInt(rgbaMatch[3], 10);

  // Basic perceived brightness formula
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness < 128; // if < 128 => dark
}

/**
 * Climb the ancestor chain to find the first non-transparent background color.
 * Returns '' if none found.
 */
function getEffectiveBackgroundColor(el) {
  let current = el;
  while (current && current !== document.documentElement) {
    const bgColor = window.getComputedStyle(current).backgroundColor;
    if (bgColor && !isTransparent(bgColor)) {
      return bgColor;
    }
    current = current.parentElement;
  }
  return '';
}

export default function GlobalCursor() {
  const ringRef = useRef(null);

  // Track whether to show the custom cursor (desktop only).
  const [showCustomCursor, setShowCustomCursor] = useState(false);

  // Whether the ring is in "light" mode (white ring) or "dark" mode (black ring).
  const [useLightColor, setUseLightColor] = useState(false);

  // Whether we’re over a clickable element (enlarged ring).
  const [isPointerActive, setIsPointerActive] = useState(false);

  // Check screen size on mount & whenever the window resizes
  useEffect(() => {
    function handleResize() {
      setShowCustomCursor(window.innerWidth >= 1400);
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // run once on mount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Only attach event listeners if we are showing the cursor
  useEffect(() => {
    if (!showCustomCursor) return;

    // 1) Mouse move handler: position the ring & check background color
    const handleMouseMove = (e) => {
      if (!ringRef.current) return;

      // Position the ring
      ringRef.current.style.left = `${e.clientX}px`;
      ringRef.current.style.top = `${e.clientY}px`;

      // Detect background color to decide black vs white ring
      const element = document.elementFromPoint(e.clientX, e.clientY);
      if (element) {
        const bgColor = getEffectiveBackgroundColor(element);
        if (bgColor) {
          setUseLightColor(isColorDark(bgColor));
        } else {
          setUseLightColor(false);
        }
      }
    };

    // 2) Hover handlers for clickable elements
    const handleEnter = () => setIsPointerActive(true);
    const handleLeave = () => setIsPointerActive(false);

    // Attach global mousemove
    window.addEventListener('mousemove', handleMouseMove);

    // Attach hover to anything "clickable"
    const clickableSelectors = 'a, button, [role="button"], .clickable';
    const clickableElements = document.querySelectorAll(clickableSelectors);
    clickableElements.forEach((el) => {
      el.addEventListener('mouseenter', handleEnter);
      el.addEventListener('mouseleave', handleLeave);
    });

    // Cleanup on unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      clickableElements.forEach((el) => {
        el.removeEventListener('mouseenter', handleEnter);
        el.removeEventListener('mouseleave', handleLeave);
      });
    };
  }, [showCustomCursor]);

  if (!showCustomCursor) {
    // On smaller screens, return null => default cursor
    return null;
  }

  // Combine classes based on current state
  const ringClasses = [
    'cursor-ring',
    useLightColor ? 'light' : 'dark',
    isPointerActive ? 'pointer-active' : '',
  ].join(' ');

  return <div ref={ringRef} className={ringClasses} />;
}
