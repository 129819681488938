import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { sendPasswordResetEmail } from 'firebase/auth';
import './BusinessProfile.css';

const BusinessProfile = () => {
  const [userDetails, setUserDetails] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [pastProjects, setPastProjects] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserDetails(data);
            setBusinessName(data.businessName || '');
            setPastProjects(data.pastProjects || '');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSave = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          businessName,
          pastProjects,
        });
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          businessName,
          pastProjects,
        }));
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleChangePassword = () => {
    const user = auth.currentUser;
    if (user) {
      sendPasswordResetEmail(auth, user.email)
        .then(() => {
          alert('Password reset email sent!');
        })
        .catch((error) => {
          console.error('Error sending password reset email:', error);
        });
    }
  };

  return (
    <div className="business-profile-container1">
      <div className="content1">
        <h1 className="business-name1">{userDetails.businessName || 'BusinessName'}</h1>
        <h2 className="account-title1">Account Details</h2>

        <div className="account-details1">
          <div className="detail-row1">
            <p className="detail-label1">Company Name</p>
            {isEditing ? (
              <input
                type="text"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                className="detail-input1"
              />
            ) : (
              <p className="detail-value1">{userDetails.businessName || 'businessname'}</p>
            )}
          </div>

          <div className="detail-row1">
            <p className="detail-label1">Email Address</p>
            <p className="detail-value1">{auth.currentUser?.email || 'useremail'}</p>
          </div>

          <div className="detail-row1">
            <p className="detail-label1">Password</p>
            <p className="change-password1" onClick={handleChangePassword}>
              Change Password
            </p>
          </div>

          <div className="detail-row1">
            <p className="detail-label1">Past Projects</p>
            {isEditing ? (
              <input
                type="text"
                value={pastProjects}
                onChange={(e) => setPastProjects(e.target.value)}
                className="detail-input1"
              />
            ) : (
              <p className="detail-value1">{userDetails.pastProjects || 'marketing'}</p>
            )}
          </div>
          <p className="edit-details1" onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Cancel' : 'Edit Details'}
          </p>
          {isEditing && (
            <p className="save1" onClick={handleSave}>
              Save Changes
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;
