import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SetBudget.css';
import { auth } from '../../firebaseConfig';
import { gsap, Power3 } from 'gsap';

/* 
   Key function that merges fields into a single textual "brief" 
   for your advanced AI logic on the server side.
*/
function createProjectBrief({ projectObjective, projectSummary, deliverables, dataInsights, timeline }) {
  return `
    Objective: ${projectObjective || ''}
    Summary: ${projectSummary || ''}
    Deliverables: ${deliverables || ''}
    Insights: ${dataInsights || ''}
    Timeline: ${timeline || ''}
  `.trim();
}

// If you're in dev, API_BASE_URL = localhost, else prod
const API_BASE_URL =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

const SetBudget = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // The data passed from ConfirmOptimization.js or previous step
  const projectData = location.state || {};

  // We'll store the relevant fields from projectData in local state
  const [parsedData, setParsedData] = useState({
    projectObjective: projectData.projectObjective || '',
    projectSummary: projectData.projectSummary || '',
    deliverables: projectData.deliverables || '',
    dataInsights: projectData.dataInsights || '',
    timeline: projectData.timeline || ''
  });

  // Price states
  const [calculatedPrice, setCalculatedPrice] = useState(null);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [priceError, setPriceError] = useState('');

  // Random "projects uploaded" text
  const [randomProjects, setRandomProjects] = useState(50);

  // GSAP refs
  const containerRef = useRef(null);
  const topRowRef = useRef(null);
  const fieldRefs = useRef([]);
  const bottomRef = useRef(null);

  // On mount, ensure we scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);  // <-- This forces the page to start at the top.

    // random integer between 50 and 200
    const randomVal = Math.floor(Math.random() * 151) + 50;
    setRandomProjects(randomVal);

    // GSAP animations
    const tl = gsap.timeline({ defaults: { ease: Power3.out } });
    gsap.set(containerRef.current, { autoAlpha: 0, scale: 0.95 });
    tl.to(containerRef.current, { autoAlpha: 1, scale: 1, duration: 0.5 });

    if (topRowRef.current) {
      tl.from(
        topRowRef.current.children,
        { y: -50, opacity: 0, stagger: 0.15, duration: 0.7 },
        '-=0.3'
      );
    }
    if (fieldRefs.current.length > 0) {
      tl.from(
        fieldRefs.current,
        { y: 50, opacity: 0, stagger: 0.2, duration: 0.6 },
        '-=0.2'
      );
    }
    if (bottomRef.current) {
      tl.from(bottomRef.current, { y: 50, opacity: 0, duration: 0.7 });
    }
  }, []);

  // Whenever parsedData changes, fetch a new price
  useEffect(() => {
    const hasData =
      parsedData.projectObjective ||
      parsedData.projectSummary ||
      parsedData.deliverables ||
      parsedData.dataInsights ||
      parsedData.timeline;

    if (hasData) {
      fetchPrice();
    }
    // eslint-disable-next-line
  }, [parsedData]);

  const fetchPrice = async () => {
    setLoadingPrice(true);
    setPriceError('');

    try {
      const brief = createProjectBrief(parsedData);
      const response = await fetch(`${API_BASE_URL}/api/calculate-price`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ brief })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error calculating price');
      }

      const data = await response.json();
      setCalculatedPrice(data); // => { totalPrice, talentPayout, moccetCommission }
    } catch (err) {
      console.error('fetchPrice error:', err);
      setPriceError(err.message);
    } finally {
      setLoadingPrice(false);
    }
  };

  // onChange for textareas
  const handleChange = (field, value) => {
    setParsedData((prev) => ({ ...prev, [field]: value }));
  };

  // On "Let’s get started" => Payment or Biz Signup
  const handleNext = () => {
    const route = auth.currentUser ? '/payment' : '/business-signup';
    navigate(route, {
      state: {
        ...parsedData,
        estimatedPrice: calculatedPrice?.totalPrice || 0,
        moccetCommission: calculatedPrice?.moccetCommission || 0,
        talentPayout: calculatedPrice?.talentPayout || 0
      }
    });
  };

  return (
    <div className="setbudget-wrapper" ref={containerRef}>
      <div className="setbudget-page">
        {/* LEFT COLUMN */}
        <div className="sb-left-col">
          <div className="sb-header-left" ref={topRowRef}>
            <p className="sb-brief-title">/Your Brief</p>
            <p className="sb-powered">
              Powered by <span>Moccet AI</span>
            </p>
          </div>

          {/* Field Blocks */}
          <div
            className="sb-field-block"
            ref={(el) => (fieldRefs.current[0] = el)}
          >
            <p className="sb-field-label">/Objective</p>
            <div className="sb-field-input">
              <textarea
                placeholder="What do you aim to achieve?"
                value={parsedData.projectObjective}
                onChange={(e) => handleChange('projectObjective', e.target.value)}
              />
            </div>
          </div>

          <div
            className="sb-field-block"
            ref={(el) => (fieldRefs.current[1] = el)}
          >
            <p className="sb-field-label">/Your Project</p>
            <div className="sb-field-input">
              <textarea
                placeholder="Describe your project..."
                value={parsedData.projectSummary}
                onChange={(e) => handleChange('projectSummary', e.target.value)}
              />
            </div>
          </div>

          <div
            className="sb-field-block"
            ref={(el) => (fieldRefs.current[2] = el)}
          >
            <p className="sb-field-label">/Deliverables</p>
            <div className="sb-field-input">
              <textarea
                placeholder="Deliverables..."
                value={parsedData.deliverables}
                onChange={(e) => handleChange('deliverables', e.target.value)}
              />
            </div>
          </div>

          <div
            className="sb-field-block"
            ref={(el) => (fieldRefs.current[3] = el)}
          >
            <p className="sb-field-label">/Market Data</p>
            <div className="sb-field-input">
              <textarea
                placeholder="Relevant data, insights, or stats..."
                value={parsedData.dataInsights}
                onChange={(e) => handleChange('dataInsights', e.target.value)}
              />
            </div>
          </div>

          <div
            className="sb-field-block"
            ref={(el) => (fieldRefs.current[4] = el)}
          >
            <p className="sb-field-label">/Timeline</p>
            <div className="sb-field-input">
              <textarea
                placeholder="Expected timeline..."
                value={parsedData.timeline}
                onChange={(e) => handleChange('timeline', e.target.value)}
              />
            </div>
          </div>

          <p className="sb-note" ref={bottomRef}>
            Clear deliverables and in-app chat keep you in the loop—no second-guessing, 
            no hidden steps. Our transparent milestones let you see every move, so you can watch 
            your project come to life with total clarity and zero wasted effort.
          </p>
        </div>

        {/* RIGHT COLUMN */}
        <div className="sb-right-col">
          <div className="sb-steps-row" ref={topRowRef}>
            <div className="sb-connect-step">
              <p className="sb-connect-title">/Connect to Talent</p>
              <p className="sb-step-label">Step Two</p>
            </div>
            <div className="sb-deliver-step">
              <p className="sb-deliver-title">/Deliver Project</p>
              <p className="sb-step-label">Step Three</p>
            </div>
          </div>

          <div className="sb-secure-info" ref={topRowRef}>
            <p>
              <strong>
                All funds remain in secure hold until your project is completed to your satisfaction. 
                If we can’t find the perfect match—or if your agreed deliverables aren’t fully met—
                enjoy a full refund, guaranteed.
              </strong>
            </p>
            <p>
              Ready to move forward? Tap “Let’s Get Started” to finalize your brief. 
              Moccet’s AI will find the perfect specialist—or micro-team of up to three—to handle each 
              stage seamlessly. You stay focused on your vision; we handle the details.
            </p>
          </div>

          <div className="sb-estimated-cost">
            <p className="sb-cost-label">Project Cost</p>

            {loadingPrice && <p>Calculating price...</p>}
            {priceError && <p style={{ color: 'red' }}>Error: {priceError}</p>}

            {!loadingPrice && !priceError && calculatedPrice && (
              <>
                <p className="sb-cost-amount">
                  ${calculatedPrice.totalPrice.toFixed(2)} USD
                </p>
              </>
            )}

            {!loadingPrice && !priceError && !calculatedPrice && (
              <p>—</p>
            )}

            <button className="sb-start-btn" onClick={handleNext}>
              Let’s get started
            </button>

            <p className="sb-projects-uploaded">
              {randomProjects} projects uploaded in the last hour
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetBudget;
