import React, { useState } from "react"
import { useLocation } from "react-router-dom"  // if using react-router
import "./BuildProfile.css"

function BuildProfile() {
  const location = useLocation()
  const parsedData = location.state?.parsedData || {}

  const [addedItems, setAddedItems] = useState({})
  const recognizedKeys = ["education", "skills", "workExperience"]

  const categories = recognizedKeys
    .filter((key) => Array.isArray(parsedData[key]))
    .map((key) => ({ key, label: `/${key}` }))

  const renderDetailText = (categoryKey, detailItem) => {
    if (categoryKey === "education") {
      const inst = detailItem.institution || "Unknown Institution"
      const level = detailItem.accreditation?.educationLevel
        ? `(${detailItem.accreditation.educationLevel})`
        : ""
      return `${inst} ${level} - ${detailItem.rawText || ""}`
    } else if (categoryKey === "skills") {
      const nm = detailItem.name || "Unnamed Skill"
      const lastUsed = detailItem.lastUsed ? ` (Last used: ${detailItem.lastUsed})` : ""
      return `${nm}${lastUsed}`
    } else if (categoryKey === "workExperience") {
      const jt = detailItem.jobTitle || "Unknown Title"
      const org = detailItem.organization ? `@ ${detailItem.organization}` : ""
      return `${jt} ${org} - ${detailItem.rawText || ""}`
    } else {
      return JSON.stringify(detailItem)
    }
  }

  const handleAddClick = (categoryKey, detailItem) => {
    // For demonstration, we'll just track it locally
    // You might also store in Firestore
    setAddedItems((prev) => {
      const existing = prev[categoryKey] || []
      return { ...prev, [categoryKey]: [...existing, detailItem] }
    })
  }

  return (
    <div className="build-profile-container">
      <div className="step-navigation">
        <div className="step-item active">
          <p>/Upload CV</p>
          <span>Step One</span>
        </div>
        <div className="step-item current">
          <p>/Build Profile</p>
          <span>Step Two</span>
        </div>
        <div className="step-item">
          <p>/Verify Details</p>
          <span>Step Three</span>
        </div>
      </div>

      <div className="section-container">
        {categories.map((cat) => (
          <div key={cat.key} className="category-row">
            <h3>{cat.label}</h3>
            <div className="detail-boxes">
              {parsedData[cat.key].map((item, idx) => {
                const detailText = renderDetailText(cat.key, item)
                return (
                  <div className="detail-cell" key={idx}>
                    <div className="detail-text">{detailText}</div>
                    <button
                      className="add-button"
                      onClick={() => handleAddClick(cat.key, item)}
                    >
                      +
                    </button>
                  </div>
                )
              })}

              {(addedItems[cat.key] || []).map((added, idx2) => {
                const addedText = renderDetailText(cat.key, added)
                return (
                  <div className="detail-cell added" key={`added-${idx2}`}>
                    <div className="detail-text">{addedText} (added)</div>
                  </div>
                )
              })}

              <div className="detail-cell placeholder">
                <button className="add-button">+</button>
              </div>
              <div className="detail-cell placeholder">
                <button className="add-button">+</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="footer-actions">
        <button className="save-button">Save progress</button>
        <button className="verify-button">Step 3: Verify Details</button>
      </div>
    </div>
  )
}

export default BuildProfile
