import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { blogsData } from "./BlogsData";
import { motion, AnimatePresence } from "framer-motion";
import LoaderOverlay from "./LoaderOverlay";
import SEO from "../components/SEO"; // <-- Import your SEO component
import "./BlogList.css";

const BlogList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [nearBottom, setNearBottom] = useState(false);

  // Simulate loading for 3s
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  // Check scroll to hide fade near bottom
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.innerHeight + window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      if (docHeight - scrolled <= 60) setNearBottom(true);
      else setNearBottom(false);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // immediate check
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Helper: first sentence only
  const getOneSentence = (text) => {
    if (!text) return "";
    const sentences = text.split(".");
    return sentences[0].trim() + ".";
  };

  // -------------------------------------------------------------------
  // SEO Configuration
  // -------------------------------------------------------------------

  // Title for the /blogs listing page
  const pageTitle = "Latest Blogs from Moccet – React, Coding, and Web Development";

  // Description for the listing page
  const pageDescription =
    "Explore the latest blogs and articles on Moccet, React, JavaScript, and modern web development trends. Stay updated and sharpen your coding skills.";

  // Keywords: aiming for relevant search terms
  const pageKeywords =
    "Moccet, React blogs, coding tutorials, JavaScript, web development, programming tips, frontend development, technology insights";

  // Example structured data for a blog listing (CollectionPage or Blog)
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    name: "Moccet Blogs",
    description: pageDescription,
    url: "https://your-website.com/blogs", // replace with your actual domain
    hasPart: blogsData.map((blog) => ({
      "@type": "BlogPosting",
      headline: blog.title,
      url: `https://your-website.com/blog/${blog.id}`, // or your actual route
      datePublished: blog.date,
      description: blog.excerpt,
    })),
  };

  return (
    <div className="blogListContainer">
      {/* 
        1) Insert the SEO component above the main content 
        2) Pass in title, description, keywords, and structuredData
      */}
      <SEO
        title={pageTitle}
        description={pageDescription}
        keywords={pageKeywords}
        structuredData={structuredData}
      />

      <AnimatePresence>{isLoading && <LoaderOverlay />}</AnimatePresence>

      <AnimatePresence>
        {!isLoading && (
          <motion.div
            className="mainContent"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            {/* Title + Subheader */}
            <div className="blogMainHeader">
              <h1>Blogs</h1>
            </div>

            {/* Blog list */}
            <div className="listWrapper">
              {blogsData.map((blog) => (
                <motion.div
                  key={blog.id}
                  className="blogCard"
                  onClick={() => navigate(`/blog/${blog.id}`)}
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.2, ease: "easeOut" }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <h2 className="blogTitleText">/{blog.title}</h2>
                  <div className="excerptBubble">
                    <p className="excerptText">{getOneSentence(blog.excerpt)}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* The fixed fade at bottom of screen (1/3) 
          fade disappears near bottom of the page */}
      <div className={`bottomScreenFade ${nearBottom ? "hideFade" : ""}`} />
    </div>
  );
};

export default BlogList;
