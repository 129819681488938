// TalentSkills.js
import React, { useEffect, useState } from "react";
import { db, auth } from "../firebaseConfig";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { skillCategories } from "./SkillsCategory"; // from the snippet above

const TalentSkills = () => {
  const [step, setStep] = useState(1);

  // Step 1: Broad categories
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Step 2: Subdomains
  const [selectedSubdomains, setSelectedSubdomains] = useState({}); 
  // example shape: { "Software Development": ["Frontend Development", "Backend Development"], ... }

  // Step 3: Skills with rating
  const [skillsRating, setSkillsRating] = useState({}); 
  // shape: { "React": { rating: 8 }, "Photoshop": { rating: 5 }, ... }

  useEffect(() => {
    // On mount, load existing skill data from Firestore (if user has any).
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, "users", user.uid);
      getDoc(userRef).then((snap) => {
        if (snap.exists()) {
          const data = snap.data();
          if (data.skillData) {
            // skillData might have structure like:
            // { selectedCategories, selectedSubdomains, skillsRating }
            setSelectedCategories(data.skillData.selectedCategories || []);
            setSelectedSubdomains(data.skillData.selectedSubdomains || {});
            setSkillsRating(data.skillData.skillsRating || {});
          }
        }
      });
    }
  }, []);

  // Step 1: Toggle categories
  const handleCategoryChange = (cat) => {
    if (selectedCategories.includes(cat)) {
      // remove
      setSelectedCategories(selectedCategories.filter((c) => c !== cat));
      // also remove subdomains from that category
      setSelectedSubdomains((prev) => {
        const newSub = { ...prev };
        delete newSub[cat];
        return newSub;
      });
    } else {
      // add
      setSelectedCategories([...selectedCategories, cat]);
    }
  };

  // Step 2: Toggle subdomains
  const handleSubdomainChange = (category, subdomain) => {
    setSelectedSubdomains((prev) => {
      const newSub = { ...prev };
      if (!newSub[category]) {
        newSub[category] = [];
      }
      if (newSub[category].includes(subdomain)) {
        // remove it
        newSub[category] = newSub[category].filter((s) => s !== subdomain);
      } else {
        newSub[category].push(subdomain);
      }
      return newSub;
    });
  };

  // Step 3: Toggle or rate a specific skill
  const handleSkillRatingChange = (skillName, rating) => {
    setSkillsRating((prev) => ({
      ...prev,
      [skillName]: { rating: Number(rating) },
    }));
  };

  // Step 4: Save to Firestore
  const saveSkillData = async () => {
    const user = auth.currentUser;
    if (!user) return;
    const userRef = doc(db, "users", user.uid);

    await updateDoc(userRef, {
      skillData: {
        selectedCategories,
        selectedSubdomains,
        skillsRating,
      },
    });
    alert("Skills saved!");
  };

  // Helper: Get subdomains for chosen categories
  const relevantSubdomains = skillCategories.filter((catObj) =>
    selectedCategories.includes(catObj.category)
  );

  // Helper: Gather all the skills from selected subdomains
  const relevantSkills = [];
  relevantSubdomains.forEach((catObj) => {
    catObj.subdomains.forEach((subObj) => {
      if (selectedSubdomains[catObj.category]?.includes(subObj.name)) {
        // push all skills from subObj
        subObj.skills.forEach((skill) => {
          if (!relevantSkills.includes(skill)) {
            relevantSkills.push(skill);
          }
        });
      }
    });
  });

  // Render steps
  return (
    <div style={{ padding: "20px" }}>
      <h2>Talent Skills</h2>

      {step === 1 && (
        <div>
          <h3>Select Categories</h3>
          <p>Choose all that apply to you:</p>
          {skillCategories.map((catObj) => (
            <div key={catObj.category}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(catObj.category)}
                  onChange={() => handleCategoryChange(catObj.category)}
                />
                {catObj.category}
              </label>
            </div>
          ))}
          <button onClick={() => setStep(2)}>Next</button>
        </div>
      )}

      {step === 2 && (
        <div>
          <h3>Select Subdomains</h3>
          {relevantSubdomains.map((catObj) => (
            <div key={catObj.category}>
              <h4>{catObj.category}</h4>
              {catObj.subdomains.map((subObj) => (
                <div key={subObj.name}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedSubdomains[catObj.category]?.includes(subObj.name) || false}
                      onChange={() => handleSubdomainChange(catObj.category, subObj.name)}
                    />
                    {subObj.name}
                  </label>
                </div>
              ))}
            </div>
          ))}
          <button onClick={() => setStep(1)}>Back</button>
          <button onClick={() => setStep(3)}>Next</button>
        </div>
      )}

      {step === 3 && (
        <div>
          <h3>Rate Your Skills</h3>
          {relevantSkills.length === 0 && <p>No skills selected yet.</p>}
          {relevantSkills.map((skill) => {
            const rating = skillsRating[skill]?.rating || 0;
            return (
              <div key={skill} style={{ marginBottom: "10px" }}>
                <label>{skill}</label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  value={rating}
                  onChange={(e) => handleSkillRatingChange(skill, e.target.value)}
                  style={{ width: "50px", marginLeft: "10px" }}
                />
              </div>
            );
          })}
          <button onClick={() => setStep(2)}>Back</button>
          <button onClick={saveSkillData}>Save All</button>
        </div>
      )}
    </div>
  );
};

export default TalentSkills;
