import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap"; // <-- GSAP for animations
import "./APIReference.css";

const APIReference = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);
  const endpointCardsRef = useRef([]); // Will store refs for each API endpoint section

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      // Fade in the entire main content
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );

      // Stagger each endpoint card
      gsap.fromTo(
        endpointCardsRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
          stagger: 0.1, // each card animates 0.1s apart
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="apiReferenceContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="apiHeaderSection">
          <h1 className="apiPageTitle">API REFERENCE</h1>
        </div>

        {/* Overview */}
        <section className="apiOverviewSection">
          <h2 className="sectionSubtitle">Overview</h2>
          <p className="sectionText">
            For developers seeking programmatic access, Moccet’s API allows you
            to incorporate scoping, budgeting, milestone updates, and AI
            interactions into your own systems. This fosters flexibility—but it
            also raises potential compliance concerns, especially under evolving
            U.S. AI regulations.
          </p>
        </section>

        {/* Core API Endpoints */}
        <section className="apiEndpointsSection">
          <h2 className="sectionSubtitle">Core API Endpoints</h2>

          {/* Endpoint 1 */}
          <div
            className="apiEndpointCard"
            ref={(el) => (endpointCardsRef.current[0] = el)}
          >
            <h3 className="apiEndpointTitle">1. Scoping &amp; Budgeting</h3>
            <p className="apiEndpointDescription">
              Retrieve AI-generated breakdowns of tasks, cost estimates, and
              proposed schedules.
            </p>
            <p className="apiEndpointLegal">
              Moccet disclaims liability for financial, legal, or compliance
              outcomes derived from user reliance on these estimates.
            </p>
          </div>

          {/* Endpoint 2 */}
          <div
            className="apiEndpointCard"
            ref={(el) => (endpointCardsRef.current[1] = el)}
          >
            <h3 className="apiEndpointTitle">2. Talent Search &amp; Allocation</h3>
            <p className="apiEndpointDescription">
              Programmatically trigger a match request for specialized talent.
            </p>
            <p className="apiEndpointLegal">
              No assurance is given that any requested expert is available or
              will satisfy user requirements.
            </p>
          </div>

          {/* Endpoint 3 */}
          <div
            className="apiEndpointCard"
            ref={(el) => (endpointCardsRef.current[2] = el)}
          >
            <h3 className="apiEndpointTitle">3. Project Updates</h3>
            <p className="apiEndpointDescription">
              Pull or push milestone changes, status notifications, or final
              project completion data.
            </p>
            <p className="apiEndpointLegal">
              Users are solely responsible for the accuracy and completeness of
              data transmitted to or from their own systems.
            </p>
          </div>
        </section>

        {/* Usage Requirements */}
        <section className="usageRequirementsSection">
          <h2 className="sectionSubtitle">Usage Requirements</h2>
          <ul className="usageList">
            <li>
              <strong>Security Keys:</strong> You must protect your API
              credentials. Any unauthorized usage arising from user negligence
              is outside Moccet’s liability scope.
            </li>
            <li>
              <strong>Compliance:</strong> Implementation must respect
              intellectual property laws, plus any restrictions on the export of
              technological data under U.S. export control laws (e.g., EAR,
              ITAR).
            </li>
          </ul>
        </section>

        {/* Rate Limits & Termination */}
        <section className="rateLimitsSection">
          <h2 className="sectionSubtitle">Rate Limits &amp; Termination</h2>
          <p className="sectionText">
            Moccet reserves the right to limit requests or terminate API access
            to preserve system integrity. In such an event, Moccet is not liable
            for direct, indirect, or punitive damages, nor for business
            interruptions or lost profits.
          </p>
        </section>

        {/* Disclaimer of Warranties */}
        <section className="disclaimerSection">
          <h2 className="sectionSubtitle">Disclaimer of Warranties</h2>
          <p className="sectionText">
            This API reference is provided “as is,” with no guarantees regarding
            uptime, data accuracy, or integration continuity. Any code examples
            or references are purely illustrative.
          </p>
        </section>
      </div>
    </div>
  );
};

export default APIReference;
