import React, { useRef, useLayoutEffect } from 'react';

/**
 * Uncontrolled text area with auto-resize on input.
 * - `initialValue`: the starting text
 * - `placeholder`: placeholder text
 * - `onBlur(value)`: callback fired when user leaves the textarea 
 */
function UncontrolledResizableTextarea({ initialValue = '', placeholder, onBlur }) {
  const textareaRef = useRef(null);

  // On mount (and if initialValue changes), set the default text
  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.value = initialValue;
      autoResize(textareaRef.current);
    }
  }, [initialValue]);

  // Auto-resize on each keystroke
  const handleInput = (e) => {
    autoResize(e.target);
  };

  // When user leaves the field, pass final text to parent
  const handleBlur = () => {
    if (onBlur) {
      onBlur(textareaRef.current.value);
    }
  };

  return (
    <textarea
      ref={textareaRef}
      placeholder={placeholder}
      onInput={handleInput}
      onBlur={handleBlur}
      style={{
        width: '100%',
        minHeight: 80,
        boxSizing: 'border-box',
        resize: 'none',
      }}
    />
  );
}

function autoResize(el) {
  if (!el) return;
  el.style.height = 'auto';
  el.style.height = el.scrollHeight + 'px';
}

export default UncontrolledResizableTextarea;
