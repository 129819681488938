import React, { useState, useRef, useEffect } from 'react';
import { motion, useInView } from 'framer-motion';
import './Testimonial.css';

const Testimonial = () => {
  // Data for each testimonial
  const testimonials = [
    {
      id: 0,
      titleLabel: '/moccet x Nuqoot',
      subText: 'A Faster Way to Build a More Personal Way to Give',
      heading: 'At Moccet, we believe bold visions should feel as simple as clicking a button',
      paragraphs: [
        `For SendNuqoot, the founders had one goal: modernize how Arab communities 
        give money, capturing the warmth of tradition with a digital twist. By harnessing 
        our AI-led scoping and precisely matching them to a Harvard-backed developer 
        experienced in payments, SendNuqoot.com went from concept to a fully working 
        MVP in just three weeks.`,
        `Today, hundreds of users send personalized digital cards and funds for milestones 
        big and small—all because Moccet was there to refine the idea, recruit the perfect 
        talent, and handle every step on the journey.`,
        `“It’s not simply ‘faster development’; it’s making genuine human connection 
        feel as effortless online as it does in person.”`
      ]
    },
    {
      id: 1,
      titleLabel: '/moccet x The Wellness',
      subText: 'Shaping the Future of Personalized Healthcare',
      heading:
        'At The Wellness—a next-generation London health center—our vision was to redefine how patients engage with their health...',
      paragraphs: [
        `Moccet brought that vision to life in record time.
        They guided us from a rough concept to a dynamic platform 
        that is now transforming thousands of patient journeys, 
        every day. By instantly matching us to a specialist with 
        real-world experience in health-tech and AI personalization,
        Moccet ensured our new system was more than innovative—it's 
        become the gold standard for private healthcare in London.`,
        `The result? Streamlined, evidence-based plans that meet each patient precisely 
        where they are, proving once again that the right talent plus an unbeatable
        process can spark a lasting healthcare revolution.`
      ]
    },
    {
      id: 2,
      titleLabel: '/moccet x Harvard Innovation Labs',
      subText: 'Accelerating Classroom Innovation',
      heading:
        'At Harvard’s renowned Innovation Lab, a group of students needed a powerful way to bring their bold ideas to life...',
      paragraphs: [
        `By tapping Moccet’s network of top creative talent, the team found an MIT filmmaker who understood their vision right away. No guesswork, no endless searches—just immediate alignment and an effortless creative flow. Two weeks later, they premiered a polished video that inspired their classmates and professors alike.`,
        `“It felt like having a studio on speed dial,” one student remarked. “We said, ‘Here’s our concept,’ and Moccet took it from there—pairing us with someone who shared our passion for innovation and rigor. The result was a level of storytelling we never thought possible in a standard course project.”`,
        `Today, that video continues to spark conversations beyond the classroom, highlighting how a good idea becomes a movement when backed by the right talent.`
      ]
    }
  ];
  
    const [activeIndex, setActiveIndex] = useState(0);
    const testimonialRef = useRef(null);
  
    // Using Framer Motion's useInView to trigger animations when in viewport
    const isInView = useInView(testimonialRef, { once: true, margin: '-50px' });
  
    const handleTitleClick = (index) => {
      setActiveIndex(index);
    };
  
    return (
      // Wrap everything in a motion.div that fades/zooms in on scroll
      <motion.div
        className="testimonial-wrapper"
        ref={testimonialRef}
        initial={{ opacity: 0, y: 50, scale: 0.95 }}
        animate={isInView ? { opacity: 1, y: 0, scale: 1 } : {}}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <div className="highlight-header">
          <h1>/Highlights</h1>
          <p>Some of the recent projects built using Moccet</p>
        </div>
  
        <div className="testimonial-container">
          {/* Left column */}
          <div className="testimonial-titles">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={testimonial.id}
                className={`testimonial-title ${index === activeIndex ? 'active' : ''}`}
                onClick={() => handleTitleClick(index)}
                // Add a small hover interaction (scale or x-translate)
                whileHover={{ x: 5 }}
                transition={{ type: 'spring', stiffness: 200 }}
              >
                <div className="title-label">{testimonial.titleLabel}</div>
                <div className="title-subtext">{testimonial.subText}</div>
              </motion.div>
            ))}
          </div>
  
          {/* Right column: slider */}
          <motion.div className="testimonial-slider">
            <motion.div
              className="slides-wrapper"
              animate={{ x: `-${activeIndex * 33.8}%` }}
              transition={{ duration: 0.6, ease: [0.65, 0, 0.35, 1] }} // custom cubic-bezier
            >
              {testimonials.map((testimonial) => (
                <motion.div
                  className="slide"
                  key={testimonial.id}
                  // Each slide can fade+slide in when it becomes active if you want
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={
                    testimonial.id === activeIndex
                      ? { opacity: 1, scale: 1 }
                      : { opacity: 0.5, scale: 0.95 }
                  }
                  transition={{ duration: 0.5 }}
                >
                  <h2>{testimonial.heading}</h2>
                  {testimonial.paragraphs.map((para, i) => (
                    <p key={i}>{para}</p>
                  ))}
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    );
  };
  
  export default Testimonial;
  