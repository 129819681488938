// src/BusinessDash.jsx

import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import FlipMove from 'react-flip-move';

import BusinessChats from '../components/chat/BusinessChats';
import BusinessChatPage from '../components/chat/BusinessChatPage';
import ProjectOptimization from '../components/projects/ProjectOptimization';
import MyBusinessProjects from '../components/projects/MyBusinessProjects';
import BusinessProfile from './BusinessProfile';

import defaultProjectIcon from '../images/icon.png';
import { blogsData } from './BlogsData'; 

import './BusinessDash.css';

const BusinessDash = () => {
  const [loaded, setLoaded] = useState(false);
  const [displayName, setDisplayName] = useState('Moccet Creator');
  const [expanded, setExpanded] = useState(false);
  const [activeHeading, setActiveHeading] = useState('dashboard');
  const [view, setView] = useState('dashboard');
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [ongoingProjects, setOngoingProjects] = useState([]);

  const [latestBlogs, setLatestBlogs] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [sections, setSections] = useState([
    { id: 'dashboard', label: '/Dashboard' },
    { id: 'collaborate', label: '/Collaborate' },
    { id: 'upload', label: '/Upload a New Project' },
    { id: 'blogs', label: '/Blogs' }
  ]);

  // ===============================
  // formatTimeAgo helper
  // ===============================
  const formatTimeAgo = (timestamp) => {
    if (!timestamp) return '';
    const now = Date.now();
    const diff = now - timestamp;
    const mins = Math.floor(diff / 60000);
    if (mins < 1) return 'just now';
    if (mins < 60) return `${mins} mins`;
    const hours = Math.floor(mins / 60);
    if (hours < 24) return `${hours} hrs`;
    const days = Math.floor(hours / 24);
    return `${days} day${days > 1 ? 's' : ''}`;
  };

  // For toggling a chat from the "Collaborate" view
  const handleChatSelected = (chatId) => {
    setSelectedChatId(chatId);
    setView('collaborate');
    setActiveHeading('collaborate');
  };

  // ===============================
  // For ongoing projects => open the chat
  // ===============================
  const handleProjectChatSelected = async (proj) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      // If we already store the chatRoomId in the doc:
      if (proj.chatRoomId) {
        setSelectedChatId(proj.chatRoomId);
        setView('collaborate');
        setActiveHeading('collaborate');
        return;
      }

      // Otherwise, find a chat doc that has both businessId + studentId
      if (!proj.studentId) {
        console.log('No studentId on project. Cannot find chat room.');
        return;
      }

      // Query chatRooms with participant = user.uid
      const q = query(
        collection(db, 'chatRooms'),
        where('participants', 'array-contains', user.uid)
      );
      const snap = await getDocs(q);

      let foundRoomId = null;
      snap.forEach((docSnap) => {
        const data = docSnap.data();
        // If participants also includes the student's ID => that is the room
        if (data.participants.includes(proj.studentId)) {
          foundRoomId = docSnap.id;
        }
      });

      if (foundRoomId) {
        setSelectedChatId(foundRoomId);
        setView('collaborate');
        setActiveHeading('collaborate');
      } else {
        console.log('No chat room found for this project’s student:', proj.studentId);
      }
    } catch (err) {
      console.error('Error opening project chat:', err);
    }
  };

  // Fade in
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 500);
    return () => clearTimeout(timer);
  }, []);

  // On mount, get top 3 from blogsData
  useEffect(() => {
    const topThree = blogsData.slice(0, 3);
    setLatestBlogs(topThree);
  }, []);

  const truncateTitle = (title = '', maxWords = 5) => {
    const words = title.trim().split(/\s+/);
    if (words.length <= maxWords) return title;
    return words.slice(0, maxWords).join(' ');
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramSection = searchParams.get('section');
    if (paramSection) {
      if (sections.some((s) => s.id === paramSection)) {
        handleHeadingClick(paramSection);
      } else if (paramSection === 'account') {
        setView('account');
        setActiveHeading('');
      }
    }
    // eslint-disable-next-line
  }, [location.search]);

  // Fetch user name
  useEffect(() => {
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          if (data.businessName) {
            setDisplayName(data.businessName);
          } else if (data.firstName) {
            setDisplayName(data.firstName);
          }
        }
      }
    };
    fetchUserName();
  }, []);

  // Fetch ongoing projects
  useEffect(() => {
    const fetchOngoingProjects = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        const q = query(
          collection(db, 'applications'),
          where('businessId', '==', user.uid),
          where('isCollaborating', '==', true)
        );
        const snap = await getDocs(q);

        const results = snap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setOngoingProjects(results);
      } catch (error) {
        console.error('Error fetching ongoing projects:', error);
      }
    };
    fetchOngoingProjects();
  }, []);

  // Slash heading click => reorder + set view
  const handleHeadingClick = (sectionId) => {
    setActiveHeading(sectionId);
    setSections((prev) => {
      const updated = [...prev];
      const index = updated.findIndex((s) => s.id === sectionId);
      if (index > -1) {
        const [item] = updated.splice(index, 1);
        updated.unshift(item);
      }
      return updated;
    });

    if (sectionId === 'blogs') {
      navigate('/blogs');
    } else if (sectionId === 'dashboard') {
      setView('dashboard');
      setSelectedChatId(null);
    } else if (sectionId === 'collaborate') {
      setView('collaborate');
      setSelectedChatId(null);
    } else if (sectionId === 'upload') {
      setView('upload');
      setSelectedChatId(null);
    } else if (sectionId === 'current') {
      setView('projects');
      setSelectedChatId(null);
    } else {
      setView('dashboard');
      setSelectedChatId(null);
    }
  };

  // Toggle expand sidebar
  const handleDotsClick = () => {
    setExpanded(!expanded);
  };

  // "Touch base" => setView("collaborate")
  const handleTouchBase = () => {
    setView('collaborate');
    setActiveHeading('collaborate');
    setSelectedChatId(null);
  };

  // Bottom link => show account
  const handleShowAccount = () => {
    setActiveHeading('');
    setView('account');
  };

  // Terms => navigate
  const handleShowTerms = () => {
    navigate('/terms');
  };

  // Logout => sign out
  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  return (
    <div
      className={`business-dash-container ${loaded ? 'loaded' : ''} ${
        expanded ? 'expanded-mode' : ''
      }`}
    >
      {/* LEFT SIDEBAR */}
      <aside className={`sidebar ${expanded ? 'collapsed' : ''}`}>
        <FlipMove typeName={null} duration={400} easing="ease-in-out">
          {sections.map((section) => (
            <div key={section.id} className="section">
              <h2
                className={`slash-heading ${
                  activeHeading === section.id ? 'clicked' : ''
                }`}
                onClick={() => handleHeadingClick(section.id)}
              >
                {section.label}
              </h2>

              {section.id === 'blogs' && (
                <div className="blog-previews">
                  {latestBlogs.map((blog, index) => (
                    <p
                      key={blog.id}
                      className={`blog-preview-item ${
                        index === 1 ? 'second-item' : ''
                      } ${index === 2 ? 'third-item' : ''}`}
                      onClick={() => navigate(`/blog/${blog.id}`)}
                    >
                      /{truncateTitle(blog.title, 5)}
                    </p>
                  ))}
                </div>
              )}

              {section.id === 'upload' && (
                <button
                  className="card-button31"
                  onClick={() => handleHeadingClick('upload')}
                >
                  Use Moccet AI
                </button>
              )}
            </div>
          ))}
        </FlipMove>
      </aside>

      {/* RIGHT MAIN CONTENT */}
      <section className={`main-content ${expanded ? 'fullwidth' : ''}`}>
        <div className="nav-dots" onClick={handleDotsClick}>
          <div className="dot purple-dot" />
          <div className="dot grey-dot" />
          <div className="dot grey-dot" />
        </div>

        {view === 'collaborate' && (
          <>
            {!selectedChatId ? (
              <div className="chats-fullwidth">
                <BusinessChats onChatSelected={handleChatSelected} />
              </div>
            ) : (
              <div className="chats-fullwidth">
                <BusinessChatPage chatId={selectedChatId} />
              </div>
            )}
          </>
        )}

        {view === 'upload' && (
          <div className="project-fullwidth">
            <ProjectOptimization />
          </div>
        )}

        {view === 'projects' && <MyBusinessProjects />}

        {view === 'account' && (
          <div className="account-fullwidth">
            <BusinessProfile />
          </div>
        )}

        {view === 'dashboard' && (
          <>
            <h2 className="welcome-title">Welcome Back, {displayName}</h2>
            <div className="ongoing-projects-section">
              <h3 className="ongoing-title1">Upload a project</h3>
            </div>
            <button
              className="card-button312"
              onClick={() => handleHeadingClick('upload')}
            >
              Use Moccet AI
            </button>

            {/* Ongoing Projects (click => open chat) */}
            <div className="ongoing-projects-section">
              <h3 className="ongoing-title">Your ongoing projects</h3>
              {ongoingProjects.length > 0 ? (
                ongoingProjects.map((proj) => {
                  const isUnread = proj.unreadCount && proj.unreadCount > 0;
                  const finalLastMessage = proj.lastMessage || 'Tap to see project';
                  return (
                    <div
                      key={proj.id}
                      className={`chat-item${isUnread ? ' unread' : ''}`}
                      onClick={() => handleProjectChatSelected(proj)}
                    >
                      <div className="icon-bubble">
                        <img
                          src={defaultProjectIcon}
                          alt="Project Icon"
                          className="chat-icon"
                        />
                        {isUnread && (
                          <span className="unread-badge">
                            {proj.unreadCount}
                          </span>
                        )}
                      </div>

                      <div className="chat-text-container">
                        <div className="chat-header-row">
                          <span className="chat-user">
                            {proj.studentName || 'No Student'}
                          </span>
                          <span className="chat-time">
                            {proj.lastMessageTime
                              ? formatTimeAgo(proj.lastMessageTime)
                              : ''}
                          </span>
                        </div>
                        <div className="chat-project">
                          {proj.projectName || 'Unnamed Project'}
                        </div>
                        <p className="chat-last-message">{finalLastMessage}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No ongoing projects found.</p>
              )}
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default BusinessDash;
