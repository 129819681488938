import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./Guides.css";

const Guides = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="guidesContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="guidesHeaderSection">
          <h1 className="guidesPageTitle">GUIDES AND TUTORIALS</h1>
        </div>

        {/* Purpose */}
        <section className="guidesPurposeSection">
          <h2 className="sectionSubtitle">Purpose</h2>
          <p className="sectionText">
            Our Guides and Tutorials simplify complex setups, from advanced
            project management integrations to unique AI workflows. They are
            high-level how-tos, accompanied by disclaimers that your ultimate
            results will depend on external variables.
          </p>
        </section>

        {/* Illustrative Examples */}
        <section className="guidesExamplesSection">
          <h2 className="sectionSubtitle">Illustrative Examples</h2>

          <div className="guideItem">
            <h3 className="guideTitle">
              1. “Launching a Marketing Campaign with Moccet”
            </h3>
            <p className="guideDescription">
              Step-by-step from scoping creative assets to budget alignment.
            </p>
          </div>

          <div className="guideItem">
            <h3 className="guideTitle">
              2. “Streamlining Software Prototypes”
            </h3>
            <p className="guideDescription">
              AI recommendations for coding tasks, plus tips on version control
              best practices.
            </p>
          </div>
        </section>

        {/* Proactive Liability Shift */}
        <section className="guidesLiabilitySection">
          <h2 className="sectionSubtitle">Proactive Liability Shift</h2>
          <ul className="guidesLiabilityList">
            <li>
              The user, not Moccet, remains accountable for verifying that any
              custom usage or adaptation meets local regulations, licensing
              constraints, or professional certification requirements.
            </li>
            <li>
              We disclaim liability for indirect or incidental damage from
              misapplied tutorials.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Guides;
