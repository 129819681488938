import React, { useState, useEffect } from "react"
import Uppy from "@uppy/core"
import { Dashboard } from "@uppy/react"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { storage } from "./firebaseConfig"
import { useNavigate } from "react-router-dom" // if you want to navigate after parse
import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "./UploadCV.css"

function UploadCV() {
  const navigate = useNavigate()

  // Upload progress states
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)

  // Error, results, and "parsing in progress" states
  const [errorMessage, setErrorMessage] = useState("")
  const [analysisResults, setAnalysisResults] = useState(null)
  const [parsing, setParsing] = useState(false)

  // Initialize Uppy
  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["application/pdf"]
    },
    autoProceed: false
  })

  useEffect(() => {
    // Listens to Uppy "complete" event, which fires when user finishes selecting the file(s)
    uppy.on("complete", async (result) => {
      const { successful } = result
      if (successful.length > 0) {
        const file = successful[0]

        // Reset states
        setIsUploading(true)
        setErrorMessage("")
        setUploadProgress(0)
        setAnalysisResults(null)

        // Upload to Firebase
        const storageRef = ref(storage, `cvUploads/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file.data)

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Track upload progress
            const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setUploadProgress(prog.toFixed(0))
          },
          (error) => {
            console.error("Firebase upload error:", error)
            setIsUploading(false)
            setErrorMessage("Error uploading file to Firebase.")
          },
          async () => {
            // Upload successful
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            setIsUploading(false)
            setUploadSuccess(true)

            // Now parse the PDF -> call Affinda function
            try {
              setParsing(true) // show "Parsing..." spinner/message
              const functionURL =
                "https://us-central1-moccet-bdae2.cloudfunctions.net/parseResumeWithAffinda"

              const res = await fetch(functionURL, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ fileURL: downloadURL })
              })

              if (!res.ok) {
                const errText = await res.text()
                throw new Error(`parseResumeWithAffinda error: ${errText}`)
              }

              const data = await res.json()
              setAnalysisResults(data)

              // Navigate to build profile with parsed data
              setParsing(false)
              navigate("/buildprofile", { state: { parsedData: data } })
            } catch (err) {
              console.error("Error analyzing CV:", err)
              setParsing(false)
              setErrorMessage("Failed to parse CV with Affinda.")
            }
          }
        )
      }
    })

    // Cleanup when component unmounts
    return () => {
      uppy.close && uppy.close()
    }
  }, [uppy, navigate])

  return (
    <div className="upload-cv-container">
      <div className="upload-cv-content">
        <h1>Upload Your CV</h1>

        <Dashboard
          uppy={uppy}
          proudlyDisplayPoweredByUppy={false}
          note="PDF format only"
        />

        {isUploading && (
          <div className="upload-progress">
            <p>Uploading... {uploadProgress}%</p>
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          </div>
        )}

        {uploadSuccess && (
          <p className="upload-success-message">Upload Complete!</p>
        )}

        {/* Show a "Parsing..." message or spinner while waiting for Affinda to return data */}
        {parsing && (
          <p className="parsing-message">
            Parsing your resume, please wait...
          </p>
        )}

        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  )
}

export default UploadCV
