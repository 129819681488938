// src/components/BusinessChats/BusinessChats.jsx

import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from 'firebase/firestore';
import './BusinessChats.css';  // Make sure to import our updated CSS
import icon from '../../images/icon.png';

function BusinessChats({ onChatSelected }) {
  const [chats, setChats] = useState([]);
  const [businessName, setBusinessName] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          await fetchBusinessName(user);
          await fetchChats(user);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // ===============================
  // Fetch the BUSINESS user's name
  // ===============================
  const fetchBusinessName = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        if (userData.role === 'business') {
          setBusinessName(userData.businessName || '');
        }
      }
    } catch (error) {
      console.error('Error fetching business name:', error);
    }
  };

  // ===============================
  // Fetch the chatRooms
  // ===============================
  const fetchChats = async (user) => {
    try {
      const q = query(
        collection(db, 'chatRooms'),
        where('participants', 'array-contains', user.uid)
      );
      const snapshot = await getDocs(q);

      // Build an array of chats, extracting the newest message if any
      let chatsList = snapshot.docs.map((docSnap) => {
        const chatData = docSnap.data();

        // Extract unread count for this user
        const unreadCount = chatData.unreadCounts?.[user.uid] || 0;

        // If chatData.messages is an array, find the newest one
        let lastMessage = null;
        let lastMessageMs = 0;        
        let lastMessageSenderId = '';

        if (Array.isArray(chatData.messages) && chatData.messages.length > 0) {
          // Find the newest message by reduce
          const newestMsgObj = chatData.messages.reduce((prev, curr) => {
            let currMs = 0;
            if (curr.timestamp?.toMillis) {
              currMs = curr.timestamp.toMillis();
            } else if (curr.timestamp instanceof Date) {
              currMs = curr.timestamp.getTime();
            }
            return (currMs > prev.ms)
              ? { msg: curr, ms: currMs }
              : prev;
          }, { msg: null, ms: 0 });

          lastMessage = newestMsgObj.msg?.message || null;
          lastMessageMs = newestMsgObj.ms;
          lastMessageSenderId = newestMsgObj.msg?.senderId || '';
        }

        return {
          id: docSnap.id,
          ...chatData,
          unreadCount,
          lastMessage,
          lastMessageMs,
          lastMessageSenderId,
        };
      });

      // Sort by lastMessageMs descending
      chatsList.sort((a, b) => b.lastMessageMs - a.lastMessageMs);

      console.log('Sorted Business Chats =>', chatsList);
      setChats(chatsList);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  // ===============================
  // Format "time ago" from lastMessageMs
  // ===============================
  const formatTimeAgo = (timestamp) => {
    if (!timestamp) return '';
    const now = Date.now();
    const diff = now - timestamp;
    const mins = Math.floor(diff / 60000);
    if (mins < 1) return 'just now';
    if (mins < 60) return `${mins} mins`;
    const hours = Math.floor(mins / 60);
    if (hours < 24) return `${hours} hrs`;
    const days = Math.floor(hours / 24);
    return `${days} day${days > 1 ? 's' : ''}`;
  };

  // If we’re still loading
  if (loading) return <p>Loading your chats...</p>;

  return (
    <div className="chats-container">
      <h1>{businessName}</h1>
      <h2>Collaborate</h2>

      <div className="chat-items-container">
        {chats.length > 0 ? (
          chats.map((chat) => {
            const handleClick = () => {
              if (onChatSelected) {
                onChatSelected(chat.id);
              } else {
                navigate(`/chat/${chat.id}`);
              }
            };

            // If the last message was from the business => "You:"
            // else => "studentName:"
            let prefix = '';
            if (chat.lastMessage) {
              if (chat.lastMessageSenderId === auth.currentUser?.uid) {
                prefix = 'You: ';
              } else {
                prefix = (chat.studentName || 'Student') + ': ';
              }
            }
            const finalLastMessage = chat.lastMessage ? prefix + chat.lastMessage : '';

            return (
              <div
                key={chat.id}
                className={`chat-item ${chat.unreadCount > 0 ? 'unread' : ''}`}
                onClick={handleClick}
              >
                {/* ICON BUBBLE with badge */}
                <div className="icon-bubble">
                  <img src={icon} alt="User Icon" className="chat-icon" />
                  {chat.unreadCount > 0 && (
                    <span className="unread-badge">
                      {chat.unreadCount}
                    </span>
                  )}
                </div>

                <div className="chat-text-container">
                  <div className="chat-header-row">
                    <span className="chat-user">
                      {chat.studentName || 'Unnamed Student'}
                    </span>
                    <span className="chat-time">
                      {formatTimeAgo(chat.lastMessageMs)}
                    </span>
                  </div>
                  <div className="chat-project">
                    {chat.projectName || 'Unnamed Project'}
                  </div>
                  {finalLastMessage && (
                    <p className="chat-last-message">{finalLastMessage}</p>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p>No chats found.</p>
        )}
      </div>
    </div>
  );
}

export default BusinessChats;
