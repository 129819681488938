import React, { useState } from 'react';
import { auth } from '../../firebaseConfig';
import {
  signInWithEmailAndPassword,
  getIdTokenResult,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './SignIn.css';
import apple from '../../images/apple.png';
import google from '../../images/google.png';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setError('');
    setResetMessage('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      
      // Force refresh token to get custom claims
      await auth.currentUser.getIdToken(true);
      const idTokenResult = await getIdTokenResult(auth.currentUser);

      // Navigate based on role in the token
      if (idTokenResult.claims.role === 'student') {
        navigate('/student-dashboard');
      } else if (idTokenResult.claims.role === 'business') {
        navigate('/business-dashboard');
      } else {
        navigate('/');
      }
    } catch (err) {
      setError('Error signing in. Please check your credentials and try again.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignIn();
    }
  };

  const handlePasswordReset = async () => {
    setError('');
    setResetMessage('');

    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setResetMessage('Password reset email sent. Please check your inbox.');
    } catch (err) {
      setError('Error sending password reset email. Please try again.');
    }
  };

  // -- New: Google Sign In --
  const handleGoogleSignIn = async () => {
    setError('');
    setResetMessage('');
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);

      // Force refresh token again
      await auth.currentUser.getIdToken(true);
      const idTokenResult = await getIdTokenResult(auth.currentUser);

      if (idTokenResult.claims.role === 'student') {
        navigate('/student-dashboard');
      } else if (idTokenResult.claims.role === 'business') {
        navigate('/business-dashboard');
      } else {
        navigate('/');
      }
    } catch (err) {
      setError('Error signing in with Google. Please try again.');
    }
  };

  // -- New: Apple Sign In --
  const handleAppleSignIn = async () => {
    setError('');
    setResetMessage('');
    try {
      const provider = new OAuthProvider('apple.com');
      await signInWithPopup(auth, provider);

      // Force refresh token again
      await auth.currentUser.getIdToken(true);
      const idTokenResult = await getIdTokenResult(auth.currentUser);

      if (idTokenResult.claims.role === 'student') {
        navigate('/student-dashboard');
      } else if (idTokenResult.claims.role === 'business') {
        navigate('/business-dashboard');
      } else {
        navigate('/');
      }
    } catch (err) {
      setError('Error signing in with Apple. Please try again.');
    }
  };

  return (
    <div className="signup-container">
      <h2>Log In</h2>

      {/* Error / Reset Messages */}
      {error && <p className="error-text">{error}</p>}
      {resetMessage && <p className="success-text">{resetMessage}</p>}

      {/* Email & Password */}
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <input
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={handleKeyDown}
      />

      {/* Sign In button */}
      <button className="continue-button" onClick={handleSignIn}>
        Sign In
      </button>

      {/* Forgot Password link */}
      <p className="forgot-password" onClick={handlePasswordReset}>
        Forgot my password
      </p>

      {/* Divider line to separate password fields from social sign-in */}
      <hr className="divider" />

      {/* Social sign in buttons */}
      <button className="social-button apple-button" onClick={handleAppleSignIn}>
        <img src={apple} alt="Apple Logo" className="social-logo" />
        Continue with Apple
      </button>

      <button className="social-button google-button" onClick={handleGoogleSignIn}>
        <img src={google} alt="Google Logo" className="social-logo" />
        Continue with Google
      </button>
    </div>
  );
}

export default SignIn;
