import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./Press.css";

const Press = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="pressContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="pressHeaderSection">
          <h1 className="pressPageTitle">PRESS &amp; NEWS</h1>
        </div>

        {/* Introduction */}
        <section className="pressIntroSection">
          <h2 className="sectionSubtitle">Introduction</h2>
          <p className="sectionText">
            Our Press &amp; News section includes recent announcements, product
            updates, and external coverage. It serves as an informational hub
            but does not create any warranty or binding representation about
            Moccet’s services, partnerships, or expansions.
          </p>
        </section>

        {/* Content Categories */}
        <section className="newsCategoriesSection">
          <h2 className="sectionSubtitle">Content Categories</h2>

          <div className="categoryItem">
            <h3 className="categoryTitle">1. Announcements</h3>
            <p className="categoryDescription">
              Product launches, major AI improvements, new solutions.
            </p>
          </div>

          <div className="categoryItem">
            <h3 className="categoryTitle">2. Media Mentions</h3>
            <p className="categoryDescription">
              Summaries or links to external articles featuring Moccet. All
              external content is subject to potential inaccuracies.
            </p>
          </div>

          <div className="categoryItem">
            <h3 className="categoryTitle">3. Conference &amp; Event Updates</h3>
            <p className="categoryDescription">
              High-level recaps of speaking engagements or industry panels.
            </p>
          </div>
        </section>

        {/* Forward-Looking Statements */}
        <section className="forwardLookingSection">
          <h2 className="sectionSubtitle">Forward-Looking Statements</h2>
          <p className="sectionText">
            Some statements here may reflect objectives or anticipated
            developments. These are projections only, subject to numerous
            unknown factors, including technological changes, legal evolutions,
            or strategic revisions. Under no circumstances will Moccet accept
            liability for reliance on these statements.
          </p>
        </section>

        {/* Corrections & Updates */}
        <section className="correctionsSection">
          <h2 className="sectionSubtitle">Corrections &amp; Updates</h2>
          <p className="sectionText">
            We reserve the right to amend or correct published details if new
            data emerges. We disclaim liability for actions taken based on older
            posts.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Press;
