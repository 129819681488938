import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap"; // <-- GSAP for animations
import "./Integrations.css";

const Integrations = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);
  const integrationCardsRef = useRef([]); // Will store refs for each integration card

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      // Fade in the entire main content
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );

      // Stagger the integration cards
      gsap.fromTo(
        integrationCardsRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
          stagger: 0.1, // each card animates 0.1s apart
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="integrationsContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (initially hidden, then animated with GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="integrationsHeaderSection">
          <h1 className="integrationsPageTitle">INTEGRATIONS</h1>
        </div>

        {/* Overview */}
        <section className="integrationsOverviewSection">
          <h2 className="sectionSubtitle">Overview</h2>
          <p className="sectionText">
            Moccet’s Integrations page outlines how our platform links with
            external applications, software tools, or APIs to enhance users’
            workflows. Each connection is governed by the respective
            third-party’s service terms, which may impose obligations or
            restrictions outside Moccet’s control.
          </p>
        </section>

        {/* Common Integration Types */}
        <section className="integrationsListSection">
          <h2 className="sectionSubtitle">Common Integration Types</h2>

          {/* Integration 1 */}
          <div
            className="integrationCard"
            ref={(el) => (integrationCardsRef.current[0] = el)}
          >
            <h3 className="integrationTitle">
              1. Productivity &amp; Project Management Apps (Trello, Asana, Jira)
            </h3>
            <p className="integrationBenefit">
              <strong>Benefit:</strong> Automated task synchronization.
            </p>
            <p className="integrationLegal">
              <strong>Legal Consideration:</strong> We disclaim liability for
              incomplete data transfer or outages caused by third-party
              maintenance, or for user reliance on unverified data within these
              integrations.
            </p>
          </div>

          {/* Integration 2 */}
          <div
            className="integrationCard"
            ref={(el) => (integrationCardsRef.current[1] = el)}
          >
            <h3 className="integrationTitle">
              2. Communication Tools (Slack, Teams, Zoom)
            </h3>
            <p className="integrationBenefit">
              <strong>Benefit:</strong> In-app conferencing, messaging, and
              file-sharing.
            </p>
            <p className="integrationLegal">
              <strong>Legal Consideration:</strong> Moccet is not accountable
              for any breach, interception, or mismanagement of information that
              occurs within external messaging systems, each of which has
              separate privacy and security standards.
            </p>
          </div>

          {/* Integration 3 */}
          <div
            className="integrationCard"
            ref={(el) => (integrationCardsRef.current[2] = el)}
          >
            <h3 className="integrationTitle">
              3. Version Control &amp; Repository Platforms (GitHub, GitLab)
            </h3>
            <p className="integrationBenefit">
              <strong>Benefit:</strong> Merge Moccet’s AI-suggested changes
              seamlessly.
            </p>
            <p className="integrationLegal">
              <strong>Legal Consideration:</strong> Users bear full
              responsibility for code merges, licensing, and ensuring compliance
              with relevant open-source or proprietary regulations. Moccet
              disclaims liability for intellectual property disputes arising
              from automated code modifications or merges that contravene any
              licensing terms.
            </p>
          </div>
        </section>

        {/* Data & Privacy Protections */}
        <section className="dataPrivacySection">
          <h2 className="sectionSubtitle">Data &amp; Privacy Protections</h2>
          <ul className="dataPrivacyList">
            <li>
              While Moccet seeks to maintain secure token-based authentication
              methods, any data traveling to and from third-party services is
              subject to each service’s confidentiality standards.
            </li>
            <li>
              No encryption mechanism is infallible, and we disclaim liability
              under 47 U.S.C. § 230 for any security lapses that originate
              outside Moccet’s direct systems.
            </li>
          </ul>
        </section>

        {/* Liability Release */}
        <section className="liabilityReleaseSection">
          <h2 className="sectionSubtitle">Liability Release</h2>
          <p className="sectionText">
            By using Moccet’s integrations, you acknowledge and accept that
            Moccet is not liable for the unavailability, incorrect operation, or
            privacy breaches within third-party tools, consistent with
            disclaimers in our Master Terms of Service.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Integrations;
