// src/pages/BusinessChatPage.jsx
import React, { useState, useEffect, useRef } from 'react';
import { db, auth, storage } from '../../firebaseConfig'; 
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  setDoc,
  onSnapshot,
  query,
  where,
  increment,
  getDocs,
  collection,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import './BusinessChatPage.css';

// Example images – adapt to your own
import paperclipIcon from '../../images/paperclip.png';
import sendIcon from '../../images/send.png';
import brandPlaceholder from '../../images/icon.png';     // business avatar placeholder
import studentPlaceholder from '../../images/icon1.png';  // student avatar placeholder

function BusinessChatPage({ chatId }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  // For file input + preview
  const fileInputRef = useRef(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewDataUrl, setPreviewDataUrl] = useState('');

  // “Reply to” logic
  const [replyingTo, setReplyingTo] = useState(null);

  // Show time + “Reply” on bubble click
  const [activeMessageIndex, setActiveMessageIndex] = useState(null);

  // Logged-in user info
  const [currentUserFirstName, setCurrentUserFirstName] = useState('');
  const [otherUserFirstName, setOtherUserFirstName] = useState('');
  const [studentName, setStudentName] = useState('');
  const [projectName, setProjectName] = useState('');

  // For auto-scrolling
  const messagesEndRef = useRef(null);

  // Fullscreen image overlay + zoom
  const [expandedImageUrl, setExpandedImageUrl] = useState(null);
  const [zoomScale, setZoomScale] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);

  // Drag & drop overlay
  const [dragOver, setDragOver] = useState(false);

  // ================================
  // TIME FORMAT
  // ================================
  const formatRelativeTime = (dateObj) => {
    if (!dateObj) return '';
    const now = new Date();
    const diffMs = now - dateObj;
    const diffMins = Math.floor(diffMs / 60000);

    if (diffMins < 1) {
      return 'sent just now';
    } else if (diffMins < 60) {
      return `sent ${diffMins} minute${diffMins !== 1 ? 's' : ''} ago`;
    } else {
      const diffHours = Math.floor(diffMins / 60);
      if (diffHours < 24) {
        return `sent ${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
      } else {
        const diffDays = Math.floor(diffHours / 24);
        if (diffDays < 7) {
          return diffDays === 1 ? 'sent 1 day ago' : `sent ${diffDays} days ago`;
        } else {
          return dateObj.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          });
        }
      }
    }
  };

  // Identify "other user"
  const getOtherUserId = () => {
    const user = auth.currentUser;
    if (!user) return null;
    const parts = chatId?.split('_') || [];
    return parts.find((id) => id !== user.uid);
  };

  // Check if message is last in consecutive group
  const isLastInSenderGroup = (msgs, index) => {
    if (index === msgs.length - 1) return true;
    const currentSender = msgs[index].senderId;
    const nextSender = msgs[index + 1].senderId;
    return currentSender !== nextSender;
  };

  // Show time + “Reply” for 3s if user clicks
  const handleMessageClick = (index) => {
    setActiveMessageIndex(index);
    setTimeout(() => {
      setActiveMessageIndex(null);
    }, 3000);
  };

  // ================================
  // FETCH CHAT DETAILS
  // ================================
  const fetchChatDetails = async (chatData = {}) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const userDocSnap = await getDoc(doc(db, 'users', user.uid));
      if (userDocSnap.exists()) {
        setCurrentUserFirstName(userDocSnap.data().firstName || '');
      }

      let otherUserId = null;
      const messagesArray = chatData.messages || [];
      if (messagesArray.length > 0) {
        const firstMsg = messagesArray[0];
        if (firstMsg.senderId && firstMsg.senderId !== user.uid) {
          otherUserId = firstMsg.senderId;
        }
      }
      if (!otherUserId) {
        const parts = chatId?.split('_') || [];
        otherUserId = parts.find((id) => id !== user.uid);
      }

      // If we have a student user ID, fetch details
      if (otherUserId) {
        const otherUserDocSnap = await getDoc(doc(db, 'users', otherUserId));
        if (otherUserDocSnap.exists()) {
          setOtherUserFirstName(otherUserDocSnap.data().firstName || '');
          setStudentName(otherUserDocSnap.data().firstName || 'Unnamed Student');
        }

        // Possibly fetch the project from "applications"
        const appsRef = collection(db, 'applications');
        const q = query(
          appsRef,
          where('businessId', '==', user.uid),
          where('studentId', '==', otherUserId),
          where('isCollaborating', '==', true)
        );
        const appsSnapshot = await getDocs(q);
        if (!appsSnapshot.empty) {
          const appData = appsSnapshot.docs[0].data();
          setProjectName(appData.projectName || 'Unnamed Project');
          setStudentName(appData.studentName || 'Unnamed Student');

          const chatRef = doc(db, 'chatRooms', chatId);
          await updateDoc(chatRef, {
            projectName: appData.projectName,
            studentName: appData.studentName,
          });
        } else {
          setProjectName('Unnamed Project');
          setStudentName('Unnamed Student');
        }
      }
    } catch (error) {
      console.error('Error fetching chat details:', error);
    }
  };

  // ================================
  // LISTEN FOR CHAT CHANGES
  // ================================
  useEffect(() => {
    if (!chatId) return;

    const chatRef = doc(db, 'chatRooms', chatId);
    const unsubscribe = onSnapshot(chatRef, async (docSnap) => {
      if (!docSnap.exists()) {
        await setDoc(chatRef, { messages: [], unreadCounts: {} });
        return;
      }

      const chatData = docSnap.data() || {};
      setMessages(chatData.messages || []);

      // Mark unread=0 for the business user
      const user = auth.currentUser;
      if (user) {
        await updateDoc(chatRef, {
          [`unreadCounts.${user.uid}`]: 0,
        });
      }

      if (chatData.projectName && chatData.studentName) {
        setProjectName(chatData.projectName);
        setStudentName(chatData.studentName);
      } else {
        fetchChatDetails(chatData);
      }
    });

    return () => unsubscribe();
  }, [chatId]);

  // ================================
  // REPLY LOGIC
  // ================================
  const handleReply = (message, idx) => {
    setReplyingTo({
      id: idx,
      text: message.message,
      senderName:
        message.senderId === auth.currentUser?.uid
          ? currentUserFirstName
          : otherUserFirstName,
    });
  };

  // ================================
  // SEND TEXT MESSAGE
  // ================================
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const user = auth.currentUser;
    if (!user) return;

    const chatRef = doc(db, 'chatRooms', chatId);
    const msgObj = {
      senderId: user.uid,
      message: newMessage,
      timestamp: new Date(),
      replyTo: replyingTo
        ? {
            id: replyingTo.id,
            text: replyingTo.text,
            senderName: replyingTo.senderName,
          }
        : null,
    };

    try {
      const otherUserId = getOtherUserId();
      await updateDoc(chatRef, {
        messages: arrayUnion(msgObj),
      });
      if (otherUserId) {
        await updateDoc(chatRef, {
          [`unreadCounts.${otherUserId}`]: increment(1),
        });
      }

      setNewMessage('');
      setReplyingTo(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // ================================
  // SEND IMAGE
  // ================================
  const handleSendImage = async (file) => {
    try {
      const user = auth.currentUser;
      if (!user || !file) return;

      const safeName = file.name || `imageFile`;
      const storageRef = ref(storage, `chatImages/${chatId}/${safeName}-${Date.now()}`);

      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      const chatRef = doc(db, 'chatRooms', chatId);
      const msgObj = {
        senderId: user.uid,
        imageUrl: downloadURL,
        timestamp: new Date(),
        replyTo: replyingTo
          ? {
              id: replyingTo.id,
              text: replyingTo.text,
              senderName: replyingTo.senderName,
            }
          : null,
      };

      const otherUserId = getOtherUserId();
      await updateDoc(chatRef, {
        messages: arrayUnion(msgObj),
      });
      if (otherUserId) {
        await updateDoc(chatRef, {
          [`unreadCounts.${otherUserId}`]: increment(1),
        });
      }

      setReplyingTo(null);
    } catch (error) {
      console.error('Error sending image:', error);
    }
  };

  // =================================
  // DRAG & DROP + PREVIEW
  // =================================
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };
  const handleDragLeave = (e) => {
    // if we're truly leaving the container
    if (e.target === e.currentTarget) {
      setDragOver(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    if (!e.dataTransfer.files || !e.dataTransfer.files.length) return;
    const file = e.dataTransfer.files[0];
    if (!file) return;

    setPreviewFile(file);
    const reader = new FileReader();
    reader.onload = (evt) => {
      setPreviewDataUrl(evt.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCancelPreview = () => {
    setPreviewFile(null);
    setPreviewDataUrl('');
  };
  const handleConfirmPreview = () => {
    if (!previewFile) return;
    handleSendImage(previewFile);
    setPreviewFile(null);
    setPreviewDataUrl('');
  };

  // ==================================
  // FULLSCREEN IMAGE + ZOOM
  // ==================================
  const handleImageClick = (url) => {
    // reset zoom
    setZoomScale(1);
    setTranslateX(0);
    setTranslateY(0);
    setExpandedImageUrl(url);
  };
  const closeExpandedImage = () => {
    setExpandedImageUrl(null);
  };

  const toggleZoom = () => {
    setZoomScale((prev) => (prev === 1 ? 2 : 1));
    if (zoomScale === 2) {
      setTranslateX(0);
      setTranslateY(0);
    }
  };

  const handleImageMouseDown = (e) => {
    if (zoomScale === 1) return;
    setIsDragging(true);
    setDragStartX(e.clientX - translateX);
    setDragStartY(e.clientY - translateY);
  };

  const handleImageMouseMove = (e) => {
    if (!isDragging) return;
    setTranslateX(e.clientX - dragStartX);
    setTranslateY(e.clientY - dragStartY);
  };

  const handleImageMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  // Scroll to bottom on new messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div
      className={`business-chat-container31 ${dragOver ? 'drag-over' : ''}`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {/* The drag overlay if dragOver is true */}
      {dragOver && (
        <div className="drag-overlay">
          <div className="drag-overlay-content">
            <div className="drag-plus-sign">+</div>
            <p>Drag and drop your image</p>
          </div>
        </div>
      )}

      <div className="business-chat-header31">
        {studentName}
      </div>

      <div className="business-messages-wrapper31">
        {messages.map((msg, idx) => {
          const isSender = msg.senderId === auth.currentUser?.uid;

          let dateObj = null;
          if (msg.timestamp?.toDate) {
            dateObj = msg.timestamp.toDate();
          } else if (msg.timestamp) {
            dateObj = new Date(msg.timestamp);
          }
          const dateString = dateObj ? formatRelativeTime(dateObj) : '';

          const rowClass = `chat-item31 message-row31 ${
            isSender ? 'sender31' : 'recipient31'
          }`;

          const showMetaByDefault = isLastInSenderGroup(messages, idx);
          const showMeta = showMetaByDefault || activeMessageIndex === idx;

          return (
            <div key={idx} className={rowClass}>
              {!isSender && (
                <img
                  src={studentPlaceholder}
                  alt="Student"
                  className="chat-icon31"
                />
              )}
              {isSender && (
                <img
                  src={brandPlaceholder}
                  alt="Business"
                  className="chat-icon31"
                />
              )}

              <div className="chat-message-wrapper31">
                <div
                  className="chat-bubble31"
                  onClick={() => handleMessageClick(idx)}
                >
                  <span className="chat-user31">
                    {isSender ? currentUserFirstName : otherUserFirstName}
                  </span>

                  {msg.replyTo && (
                    <div className="quoted-message31">
                      <strong>{msg.replyTo.senderName}:</strong> {msg.replyTo.text}
                    </div>
                  )}

                  {msg.imageUrl && (
                    <img
                      src={msg.imageUrl}
                      alt="Attachment"
                      className="chat-image-attachment"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleImageClick(msg.imageUrl);
                      }}
                    />
                  )}

                  {msg.message && (
                    <p className="chat-message-text31">{msg.message}</p>
                  )}
                </div>

                {showMeta && (
                  <div className="chat-meta-row31">
                    <div className="chat-date31">{dateString}</div>
                    <p
                      className="reply-btn31"
                      onClick={() => handleReply(msg, idx)}
                    >
                      Reply
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {replyingTo && (
        <div className="replying-to-bar31">
          <div className="replying-to-text31">
            Replying to <strong>{replyingTo.senderName}:</strong> “
            {replyingTo.text}”
          </div>
          <p className="cancel-reply-btn31" onClick={() => setReplyingTo(null)}>
            &times;
          </p>
        </div>
      )}

      {/* The bottom input bar (outer container) */}
      <div className="business-chat-input-bar31">
        {/* Inner wrapper with max-width: 1000px + margin: auto */}
        <div className="input-bar-inner31">

          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setPreviewFile(file);
                const reader = new FileReader();
                reader.onload = (evt) => {
                  setPreviewDataUrl(evt.target.result);
                };
                reader.readAsDataURL(file);
              }
              e.target.value = null;
            }}
          />

          <p className="attach-btn31" type="button" onClick={() => fileInputRef.current.click()}>
            <img src={paperclipIcon} alt="Attach" />
          </p>

          {/* If we have a preview file => show preview container */}
          {previewDataUrl ? (
            <div className="preview-container31">
              <img src={previewDataUrl} alt="Preview" className="preview-image31" />
              <div className="preview-buttons31">
                <button onClick={handleConfirmPreview}>Send</button>
                <button onClick={handleCancelPreview}>Cancel</button>
              </div>
            </div>
          ) : (
            // Otherwise show normal text input + send button
            <>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={`Chat with ${studentName}...`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
              />
              <p className="send-btn31" type="button" onClick={handleSendMessage}>
                <img src={sendIcon} alt="Send" />
              </p>
            </>
          )}
        </div>
      </div>

      {/* Fullscreen overlay for expanded image if needed */}
      {expandedImageUrl && (
        <div className="image-modal-overlay" onClick={closeExpandedImage}>
          <div
            className="image-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={expandedImageUrl}
              alt="Full size"
              className="image-modal-img"
              style={{
                transform: `scale(${zoomScale}) translate(${translateX}px, ${translateY}px)`,
                cursor: zoomScale > 1 ? 'grab' : 'zoom-in',
              }}
              onClick={toggleZoom}
              onMouseDown={(e) => {
                if (zoomScale > 1) {
                  setIsDragging(true);
                  setDragStartX(e.clientX - translateX);
                  setDragStartY(e.clientY - translateY);
                }
              }}
              onMouseMove={(e) => {
                if (!isDragging) return;
                setTranslateX(e.clientX - dragStartX);
                setTranslateY(e.clientY - dragStartY);
              }}
              onMouseUp={() => setIsDragging(false)}
              onMouseLeave={() => setIsDragging(false)}
            />
            <button className="image-modal-close" onClick={closeExpandedImage}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BusinessChatPage;
