import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import {
  collection,
  query,
  getDocs,
  addDoc,
  doc,
  getDoc,
  where
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './ProjectBoard.css';
import submitted from '../../images/submitted.png';
import studentPlaceholder from '../../images/icon.png';

function ProjectBoard({ isSidebarOpen }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submittedProjects, setSubmittedProjects] = useState([]);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Fade in effect
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // Fetch which projects user has already submitted
  useEffect(() => {
    const fetchSubmittedProjects = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const appsQuery = query(
        collection(db, 'applications'),
        where('studentId', '==', user.uid)
      );
      const appsSnapshot = await getDocs(appsQuery);
      const submittedIds = appsSnapshot.docs.map((docSnap) => docSnap.data().projectId);
      setSubmittedProjects(submittedIds);
    };
    fetchSubmittedProjects();
  }, []);

  // Ensure user is logged in & fetch projects
  useEffect(() => {
    const checkUserAndFetch = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/signin');
      } else {
        await fetchProjects();
      }
    };

    const fetchProjects = async () => {
      try {
        const q = query(collection(db, 'projects'));
        const querySnapshot = await getDocs(q);
        const projectsList = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setProjects(projectsList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    checkUserAndFetch();
  }, [navigate]);

  // Apply for a project
  const applyForProject = async (project) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        alert('You need to be signed in to apply for projects.');
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const { firstName, lastName, university, degree, year } = userDoc.data();

        // Create an application
        await addDoc(collection(db, 'applications'), {
          projectId: project.id,
          projectName: project.title,
          studentId: user.uid,
          studentName: `${firstName} ${lastName}`,
          university,
          degree,
          year,
          businessId: project.ownerId,
          appliedAt: new Date(),
          isCollaborating: false,
          message: '',
        });

        // Create a notification for the business
        await addDoc(collection(db, 'notifications'), {
          businessId: project.ownerId,
          studentId: user.uid,
          projectId: project.id,
          message: `A student has applied for your project: ${project.title}`,
          createdAt: new Date(),
          read: false,
        });

        setSubmittedProjects([...submittedProjects, project.id]);
      }
    } catch (error) {
      console.error('Error applying for the project:', error);
      alert('Failed to apply for the project. Please try again later.');
    }
  };

  if (loading) return <p>Loading projects...</p>;

  // The key: Conditionally add "sidebar-open" if isSidebarOpen === true
  return (
    <div
      className={`project-board-container ${loaded ? 'loaded' : ''} ${
        isSidebarOpen ? 'sidebar-open' : ''
      }`}
    >
      <h1 className="project-board-heading">Project Board</h1>

      {/* 3-col layout by default, overridden to 2-col if sidebar-open */}
      <div className="project-board-grid">
        {projects.map((project) => {
          const businessName = project.businessName || 'Unknown Business';
          const projectBudget = project.budget
            ? `$${Number(project.budget).toLocaleString()}`
            : '$0.00';
          const projectSummary = project.projectSummary || 'No description available';
          const matchScore = project.matchScore ?? 85; // fallback

          return (
            <div key={project.id} className="project-card1">
              <h2 className="slash-heading">/{project.title}</h2>

              <div className="business-box">
                <div className="business-logo-container">
                  <div className="business-initial">
                    <img
                      src={studentPlaceholder}
                      alt="Student"
                      className="chat-icon79"
                    />
                  </div>
                </div>
                <div className="business-info">
                  <p className="business-name">{businessName}</p>
                </div>
                <p className="business-budget">{projectBudget}</p>
              </div>

              <p className="match-score">moccet match score {matchScore}</p>

              {submittedProjects.includes(project.id) ? (
                <button className="apply-button applied" disabled>
                  Submitted
                </button>
              ) : (
                <button className="apply-button" onClick={() => applyForProject(project)}>
                  Apply
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProjectBoard;
