// src/components/TsAndCs.jsx
import React, { useEffect } from "react";
import "./TsAndCs.css";

const TsAndCs = () => {
  // 1) On mount, scroll to the top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="tsFullBg"> 
      <div className="tsContainer">
        <h1 className="tsHeading">Terms &amp; Conditions</h1>

        <section className="tsSection">
          <h2>1. INTRODUCTION</h2>
          <p>
            <strong>1.1. Parties.</strong> This Terms &amp; Conditions document (hereafter, “Terms”) 
            is a binding agreement between <strong>Moccet LLC</strong> (hereafter, “<strong>Moccet</strong>,” 
            “we,” “us,” or “our”), any Business entity seeking services or talent (hereafter, 
            “<strong>Business(es)</strong>”), and any Student or individual providing talent 
            (hereafter, “<strong>Student(s)</strong>,” “Freelancer(s),” “Talent,” or “User(s)”). 
            Collectively, the parties may be referred to as “You” or “Users.”
          </p>
          <p>
            <strong>1.2. Purpose.</strong> Moccet operates a platform at 
            <em> moccet.com</em> (hereafter, “<strong>Platform</strong>” or “Site”) that connects 
            Businesses with Students or Talent for completion of various online projects. These 
            Terms govern your usage of the Platform and all associated services, including but not 
            limited to the discovery of talent, posting of projects, project management tools, and 
            communication features.
          </p>
          <p>
            <strong>1.3. Acceptance &amp; Modifications.</strong> By accessing or using our Platform, 
            you acknowledge that you have read, understood, and agree to be bound by these Terms. 
            We may modify or revise these Terms at any time, for any reason, in our sole discretion, 
            by posting an updated version on our Site. Your continued use of the Platform after 
            posting of revisions constitutes your acceptance of such revisions.
          </p>
        </section>

        <section className="tsSection">
          <h2>2. ACCOUNT REGISTRATION &amp; ELIGIBILITY</h2>
          <p>
            <strong>2.1. Account Creation.</strong> To use certain features of the Platform, you must 
            create an account with Moccet. You agree to provide accurate and current information, 
            maintain the security of your password, and accept all risks of unauthorized access 
            to your account data.
          </p>
          <p>
            <strong>2.2. Age &amp; Authority.</strong> You represent and warrant that you are at least 
            18 years old (or the age of majority in your jurisdiction) and possess the legal 
            authority to enter into these Terms on behalf of yourself or any entity you represent. 
            If you are under 18, or under the legal age in your jurisdiction, you must not use the 
            Platform without verifiable parental or guardian consent, consistent with all applicable 
            laws.
          </p>
          <p>
            <strong>2.3. Business vs. Student Users.</strong> If you are creating an account on 
            behalf of a Business, you represent that you have the legal authority to bind that 
            Business to these Terms. If you are using the Platform as a Student or Talent provider, 
            you represent that you have all required permissions (e.g., from your educational 
            institution) to perform freelance services, if applicable.
          </p>
        </section>

        <section className="tsSection">
          <h2>3. SCOPE OF SERVICES &amp; PLATFORM USAGE</h2>
          <p>
            <strong>3.1. Role of Moccet.</strong> Moccet is a neutral venue that facilitates the 
            introduction of Businesses and Students. We do not directly employ any Student and 
            are not responsible for any project deliverables, final outcomes, or potential 
            third-party claims arising from your engagements.
          </p>
          <p>
            <strong>3.2. No Guarantee of Success.</strong> We do not guarantee that Businesses will 
            find suitable Talent, nor that Students will find suitable projects, nor do we guarantee 
            that projects will be completed successfully, on time, or meet specific standards.
          </p>
          <p>
            <strong>3.3. User Communications.</strong> You agree that any communications made via 
            the Platform shall be professional, lawful, and respectful. Moccet reserves the right 
            to monitor or remove any communication that violates these Terms or applicable law.
          </p>
          <p>
            <strong>3.4. Prohibited Conduct.</strong> You will not:
          </p>
          <ul>
            <li>Use the Platform to infringe the intellectual property or privacy rights of others.</li>
            <li>Post or transmit fraudulent, libelous, or offensive content.</li>
            <li>Engage in any activity that disrupts or tampers with the Platform’s security or 
              functionality.</li>
            <li>Harvest or collect information about other users without their consent.</li>
          </ul>
        </section>

        <section className="tsSection">
          <h2>4. BUSINESS OBLIGATIONS</h2>
          <p>
            <strong>4.1. Project Listings.</strong> Businesses may post project briefs or job 
            listings. Businesses acknowledge that such postings must be truthful, accurate, and 
            lawful, and must not solicit illegal activities.
          </p>
          <p>
            <strong>4.2. Payments &amp; Fees.</strong> Businesses agree to pay all fees associated 
            with hiring a Student or Talent, including any Moccet platform fees if applicable. All 
            payment terms are set by our payment provider or as stated on the Site. Moccet is not 
            responsible for reimbursement or payment disputes between Businesses and Students.
          </p>
          <p>
            <strong>4.3. Confidential Information.</strong> Businesses are responsible for managing 
            their confidential information. If a Business requires an NDA with a Student, the 
            Business must independently arrange for such NDA. Moccet is not liable for any accidental 
            or unauthorized disclosure of project details or business secrets.
          </p>
          <p>
            <strong>4.4. Compliance &amp; Indemnity.</strong> Businesses shall comply with all 
            applicable employment laws, tax obligations, and third-party contractual obligations. 
            Businesses agree to indemnify, defend, and hold harmless Moccet from any claims arising 
            from their project postings, including but not limited to intellectual property 
            infringement or regulatory violations.
          </p>
        </section>

        <section className="tsSection">
          <h2>5. STUDENT OBLIGATIONS</h2>
          <p>
            <strong>5.1. Representation &amp; Skills.</strong> Students represent that they have the 
            necessary skills, rights, and authority to perform services they advertise or accept via 
            the Platform.
          </p>
          <p>
            <strong>5.2. Academic Standing.</strong> If Students are currently enrolled in an 
            educational institution, it is their responsibility to ensure no institutional policies 
            prohibit their freelance or external work. Moccet is not liable for any academic 
            sanctions or policy violations.
          </p>
          <p>
            <strong>5.3. Quality of Work.</strong> Students acknowledge that they are solely 
            responsible for the quality, timeliness, and completeness of their deliverables. Moccet 
            bears no responsibility for reviewing or verifying the correctness of any Student’s work.
          </p>
          <p>
            <strong>5.4. Fees &amp; Earnings.</strong> Students agree that any compensation is 
            determined solely between the Student and Business. Moccet may charge a platform or 
            transaction fee for facilitating payments or providing other services. Students are 
            responsible for all applicable income taxes, withholding taxes, and any other tax 
            obligations.
          </p>
          <p>
            <strong>5.5. Legal Compliance.</strong> Students must ensure compliance with all laws 
            relevant to freelance or contractual work. Students agree to indemnify Moccet from any 
            legal claims arising out of their services or project engagements.
          </p>
        </section>

        <section className="tsSection">
          <h2>6. INTELLECTUAL PROPERTY &amp; CONTENT</h2>
          <p>
            <strong>6.1. User-Generated Content.</strong> Any content Users upload or post shall 
            remain the property of its respective owners, except that Users grant Moccet a 
            worldwide, non-exclusive, royalty-free license to use, store, display, and reproduce 
            such content for the operation of the Platform.
          </p>
          <p>
            <strong>6.2. Moccet IP.</strong> The Site design, layout, name, logo, and all software 
            or systems used to operate the Platform are proprietary to Moccet. You may not duplicate, 
            copy, or reuse any portion of the HTML/CSS, design elements, or trademarks without 
            express written permission.
          </p>
          <p>
            <strong>6.3. Project IP Ownership.</strong> The allocation of intellectual property 
            rights for completed projects or deliverables is solely between Business and Student. 
            Moccet disclaims any interest in such IP, unless separately agreed in writing.
          </p>
        </section>

        <section className="tsSection">
          <h2>7. PAYMENT PROCESSING &amp; REFUNDS</h2>
          <p>
            <strong>7.1. Third-Party Payment.</strong> Payments may be processed by third-party 
            payment processors. Moccet does not store payment information and is not liable for any 
            errors, data breaches, or disputes related to these third-party services.
          </p>
          <p>
            <strong>7.2. Refunds.</strong> Moccet does not guarantee refunds for any project, fees, 
            or services. Any refund disputes must be resolved directly between the Business and 
            Student. Moccet has no obligation to mediate or enforce any refund claims unless it 
            chooses to do so in its sole discretion.
          </p>
        </section>

        <section className="tsSection">
          <h2>8. DISCLAIMER OF WARRANTIES</h2>
          <p>
            <strong>8.1. As-Is.</strong> Moccet provides the Platform “as is,” with all faults, and 
            without warranty of any kind. We disclaim all implied warranties, including but not 
            limited to warranties of merchantability, fitness for a particular purpose, and 
            non-infringement.
          </p>
          <p>
            <strong>8.2. Platform Availability.</strong> We do not guarantee that the Platform will 
            be uninterrupted, error-free, or secure. We are not liable for any downtime or data loss.
          </p>
          <p>
            <strong>8.3. Reliance on Talent.</strong> Moccet does not endorse or guarantee the 
            competence, reliability, or qualifications of any Student or Business. Users assume 
            all risks in dealing with other Users.
          </p>
        </section>

        <section className="tsSection">
          <h2>9. LIMITATION OF LIABILITY</h2>
          <p>
            <strong>9.1. No Consequential Damages.</strong> To the fullest extent permitted by law, 
            Moccet shall not be liable for any indirect, punitive, incidental, special, 
            consequential, or exemplary damages, including lost profits, data loss, or business 
            interruption.
          </p>
          <p>
            <strong>9.2. Liability Cap.</strong> In no event shall Moccet’s aggregate liability 
            exceed the lesser of the total fees actually paid by you to Moccet in the six (6) 
            months preceding the claim or one hundred U.S. dollars ($100).
          </p>
          <p>
            <strong>9.3. User Responsibilities.</strong> You understand and agree that Moccet cannot 
            be held liable for the actions, omissions, or content of any Business or Student.
          </p>
        </section>

        <section className="tsSection">
          <h2>10. INDEMNIFICATION</h2>
          <p>
            <strong>10.1. General Indemnity.</strong> You agree to indemnify, defend, and hold 
            harmless Moccet, its officers, directors, employees, agents, and affiliates from any 
            and all claims, damages, losses, liabilities, suits, judgments, and litigation costs 
            arising out of or in any way related to (a) your breach of these Terms, (b) your use 
            or misuse of the Platform, (c) your interactions or disputes with other Users, or 
            (d) your violation of any law or the rights of a third party.
          </p>
        </section>

        <section className="tsSection">
          <h2>11. TERMINATION &amp; SUSPENSION</h2>
          <p>
            <strong>11.1. By User.</strong> You may stop using our Platform and, if desired, close 
            your account at any time.
          </p>
          <p>
            <strong>11.2. By Moccet.</strong> We may suspend or terminate your account for any 
            violation of these Terms or applicable law, or if we believe it is necessary to protect 
            Moccet or any of its Users.
          </p>
          <p>
            <strong>11.3. Effect of Termination.</strong> Upon termination, you lose all access to 
            the Platform. Any provisions that by their nature should survive termination will remain 
            in effect.
          </p>
        </section>

        <section className="tsSection">
          <h2>12. DISPUTE RESOLUTION &amp; GOVERNING LAW</h2>
          <p>
            <strong>12.1. Arbitration.</strong> Any dispute arising out of or relating to these Terms 
            or your use of the Platform shall be finally resolved by binding arbitration administered 
            by a reputable arbitration organization. The arbitration shall be held in the county of 
            Moccet’s principal place of business or another mutually agreed location.
          </p>
          <p>
            <strong>12.2. Class Action Waiver.</strong> You agree to resolve any disputes on an 
            individual basis; you will not bring a claim as a plaintiff or class member in a class, 
            consolidated, or representative action.
          </p>
          <p>
            <strong>12.3. Governing Law.</strong> These Terms are governed by the laws of the State 
            in which Moccet LLC is registered, without regard to conflict of law principles.
          </p>
        </section>

        <section className="tsSection">
          <h2>13. MISCELLANEOUS</h2>
          <p>
            <strong>13.1. Entire Agreement.</strong> These Terms (including all policies posted on 
            the Platform) constitute the entire agreement between Moccet and you regarding the 
            Platform.
          </p>
          <p>
            <strong>13.2. No Waiver.</strong> The failure of Moccet to enforce any right or 
            provision of these Terms shall not constitute a waiver of future enforcement of that 
            right or provision.
          </p>
          <p>
            <strong>13.3. Severability.</strong> If any provision of these Terms is held invalid, 
            the remainder will remain in full force and effect.
          </p>
          <p>
            <strong>13.4. Assignment.</strong> You may not assign or transfer these Terms without 
            Moccet’s prior written consent. Moccet may assign or transfer these Terms at its 
            discretion.
          </p>
          <p>
            <strong>13.5. Contact Us.</strong> If you have any questions or concerns about these 
            Terms, please contact us at <em>contact@moccet.com</em>.
          </p>
        </section>

        <section className="tsSection">
          <h2>END OF TERMS</h2>
          <p className="closingNote">
            By accessing or using Moccet LLC’s Platform, you affirm that you have read, understood, 
            and agree to these Terms &amp; Conditions, and that these Terms serve as a binding 
            contractual agreement between you and Moccet LLC.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TsAndCs;
