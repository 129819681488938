// skillCategories.js
export const skillCategories = [
    {
      category: "Software Development",
      subdomains: [
        {
          name: "Frontend Development",
          skills: [
            "HTML/CSS",
            "JavaScript",
            "React",
            "Vue",
            "Angular",
            "UI/UX",
          ],
        },
        {
          name: "Backend Development",
          skills: [
            "Node.js",
            "Express.js",
            "Python (Flask/Django)",
            "Java (Spring Boot)",
            "C# (.NET)",
            "Go",
            "SQL/Databases",
          ],
        },
        {
          name: "Mobile Development",
          skills: [
            "Swift (iOS)",
            "Kotlin (Android)",
            "Flutter/Dart",
            "React Native",
          ],
        },
        {
          name: "Data/ML",
          skills: [
            "Python (Data Analysis)",
            "TensorFlow",
            "PyTorch",
            "R Language",
            "Data Visualization (Tableau)",
            "Data Engineering (ETL)",
          ],
        },
      ],
    },
    {
      category: "Media & Design",
      subdomains: [
        {
          name: "Graphic Design",
          skills: ["Photoshop", "Illustrator", "InDesign", "Figma", "Sketch"],
        },
        {
          name: "Video Production",
          skills: [
            "Adobe Premiere",
            "Final Cut Pro",
            "After Effects",
            "Video Editing",
            "Motion Graphics",
          ],
        },
        {
          name: "3D/Animation",
          skills: ["Blender", "Maya", "Cinema 4D", "3D Modeling"],
        },
      ],
    },
    {
      category: "Business & Management",
      subdomains: [
        {
          name: "Marketing",
          skills: [
            "Digital Marketing",
            "SEO/SEM",
            "Social Media Marketing",
            "Email Marketing",
          ],
        },
        {
          name: "Project Management",
          skills: [
            "Agile/Scrum",
            "Jira",
            "Kanban",
            "Resource Planning",
          ],
        },
        {
          name: "Finance & Accounting",
          skills: ["Financial Analysis", "Bookkeeping", "Excel Modeling"],
        },
      ],
    },
    // Add more top-level categories as needed ...
  ];
  