import React, { useState, useEffect, useRef } from 'react';
import './ReferAFriend.css';
import confettiImage from '../images/dot.png'; // optional local confetti image

const ReferAFriend = () => {
  // This is the user’s referral link (if you need it)
  const [referralLink] = useState('https://moccet.com/referral?code=XYZ123');
  
  // For showing "Copied!" message
  const [copied, setCopied] = useState(false);
  
  // Track when the user has clicked the “Refer Now” button, so we can show confetti
  const [showConfetti, setShowConfetti] = useState(false);

  // Keep track of the email input
  const [friendEmail, setFriendEmail] = useState('');

  // A ref for storing a setTimeout ID (so we can remove the confetti after a while)
  const confettiTimeoutRef = useRef(null);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (confettiTimeoutRef.current) {
        clearTimeout(confettiTimeoutRef.current);
      }
    };
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error('Failed to copy text: ', err));
  };

  // This function will call the backend to send the email
  const handleSendEmail = async () => {
    if (!friendEmail) {
      alert('Please enter a valid email');
      return;
    }

    try {
      // Optionally show loading spinner or disable button here

      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: friendEmail,

          // If your backend requires other fields (like `parsedData`, `assistantResponse`, etc.), 
          // you can include them here. For a simple refer-a-friend, you could pass a simpler payload:
          parsedData: {},
          assistantResponse: '',
          ownerId: '', // or pass in your user’s ID if needed
        }),
      });

      if (!response.ok) {
        throw new Error('Error sending referral email');
      }

      // On success, show confetti for 3 seconds
      setShowConfetti(true);
      confettiTimeoutRef.current = setTimeout(() => {
        setShowConfetti(false);
      }, 3000);

      // Optionally show success message or clear the input
      setFriendEmail('');
      alert('Your referral email has been sent!');

    } catch (error) {
      console.error('Failed to send referral email:', error);
      alert('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="refer-friend-container">
      {/* Floating confetti container */}
      {showConfetti && (
        <div className="confetti-overlay">
          {/* Multiple confetti items (could also be done with CSS animation) */}
          {Array.from({ length: 20 }).map((_, i) => (
            <img
              key={i}
              src={confettiImage}
              alt="confetti"
              className="confetti"
              style={{ animationDelay: `${i * 0.1}s` }}
            />
          ))}
        </div>
      )}

      <h2 className="refer-title">/Refer a Friend</h2>
      <p className="refer-subtitle">
        Share Moccet — achieve bigger results, together
      </p>

      <div className="referral-link-container">
        <input
          type="email"
          className="referral-link-input"
          placeholder="Enter your friend's email"
          value={friendEmail}
          onChange={(e) => setFriendEmail(e.target.value)}
        />
      </div>

      <div className="refer-now-wrapper">
        <button
          className="refer-now-button"
          onClick={handleSendEmail}
        >
          / Send to their email
        </button>
      </div>
    </div>
  );
};

export default ReferAFriend;