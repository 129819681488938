import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import './ConfirmOptimization.css';
import { gsap, Power3 } from 'gsap';

import UncontrolledResizableTextarea from './UncontrolledResizableTextarea'; 
// Make sure path is correct

const API_BASE_URL =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

const ConfirmOptimization = () => {
  const location = useLocation();
  const { assistantResponse: initialAssistantResponse } = location.state || {};
  const assistantResponse = initialAssistantResponse || '';

  console.log(assistantResponse);
  // This state only updates onBlur of textareas
  const [parsedData, setParsedData] = useState({
    title: '',
    projectObjective: '',
    projectSummary: '',
    deliverables: '',
    skills: '',
    dataInsights: '',
    timeline: '',
  });

  const navigate = useNavigate();

  // Refs for GSAP
  const containerRef = useRef(null);
  const topRowRef = useRef(null);
  const fieldRefs = useRef([]); // array of 4 field-blocks
  const bottomRowRef = useRef(null);

  // Track if we've already run the animation (so it doesn't run twice in Strict Mode)
  const [animationDone, setAnimationDone] = useState(false);

  // -----------------------
  // 1) Parse Assistant Response
  // -----------------------
  const parseAssistantResponse = (responseText) => {
    const result = {};
    const headings = [
      { key: 'title', headings: ['Project Title:'] },
      { key: 'projectObjective', headings: ['Project Objective:'] },
      { key: 'projectSummary', headings: ['Project Scope:'] },
      { key: 'deliverables', headings: ['Deliverables:', 'Deliverable:'] },
      { key: 'skills', headings: ['Skills Required:'] },
      { key: 'dataInsights', headings: ['Data-Backed Insights:'] },
      { key: 'timeline', headings: ['Timeline:'] },
    ];

    headings.forEach((section, index) => {
      const startPattern = section.headings
        .map((h) => h.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
        .join('|');
      const endPattern = headings
        .slice(index + 1)
        .flatMap((s) => s.headings)
        .map((h) => h.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
        .join('|');

      const regex = new RegExp(
        '(?:' + startPattern + ')\\s*([\\s\\S]*?)\\s*(?=' + (endPattern || '$') + ')',
        'i'
      );
      const match = responseText.match(regex);
      if (match) {
        let content = match[1].trim();
        // remove asterisks
        content = content.replace(/\*/g, '');
        result[section.key] = content;
      }
    });
    return result;
  };

  // On mount, parse data if available
  useEffect(() => {
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('title') || searchParams.has('projectObjective')) {
      // parse from query
      const dataFromQuery = {
        title: searchParams.get('title') || '',
        projectObjective: searchParams.get('projectObjective') || '',
        projectSummary: searchParams.get('projectSummary') || '',
        deliverables: searchParams.get('deliverables') || '',
        skills: searchParams.get('skills') || '',
        dataInsights: searchParams.get('dataInsights') || '',
        timeline: searchParams.get('timeline') || '',
      };
      setParsedData(dataFromQuery);
    } else if (assistantResponse) {
      // parse from AI text
      const parsed = parseAssistantResponse(assistantResponse);
      setParsedData(parsed);
    }
  }, [assistantResponse, location.search]);

  // -----------------------
  // 2) GSAP Animation
  // -----------------------
  useEffect(() => {
    // Only run once (avoid double-run in React 18 Strict Mode)
    if (animationDone) return;

    const tl = gsap.timeline({ defaults: { ease: Power3.out } });

    // container fade in
    gsap.set(containerRef.current, { autoAlpha: 0, scale: 0.95 });
    tl.to(containerRef.current, { autoAlpha: 1, scale: 1, duration: 0.5 });

    // top row from top
    tl.from(
      topRowRef.current.children,
      {
        y: -50,
        opacity: 0,
        stagger: 0.15,
        duration: 0.7,
      },
      '-=0.3'
    );

    // fields cascade from bottom
    tl.from(
      fieldRefs.current,
      {
        y: 50,
        opacity: 0,
        stagger: 0.2,
        duration: 0.6,
      },
      '-=0.2'
    );

    // bottom row
    tl.from(bottomRowRef.current, {
      y: 50,
      opacity: 0,
      duration: 0.7,
      // remove clearProps to avoid removing pointer-events
      // clearProps: 'all',
    });

    setAnimationDone(true);
  }, [animationDone]);

  // -----------------------
  // 3) Step 2: Save & Navigate
  // -----------------------
  const handleNext = () => {
    localStorage.setItem(
      'projectData',
      JSON.stringify({ ...parsedData, ...location.state })
    );
    navigate('/set-budget', {
      state: {
        ...location.state,
        ...parsedData,
      },
    });
  };

  // -----------------------
  // 4) Send to Email
  // -----------------------
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSentMessage, setEmailSentMessage] = useState('');

  const handleOpenEmailPopup = () => {
    setShowEmailPopup(true);
    setEmailSentMessage('');
  };

  const handleCloseEmailPopup = () => {
    setShowEmailPopup(false);
  };

  const handleSendEmail = async () => {
    // Safeguard
    const safeParsedData = {
      title: parsedData.title || '',
      projectObjective: parsedData.projectObjective || '',
      projectSummary: parsedData.projectSummary || '',
      deliverables: parsedData.deliverables || '',
      skills: parsedData.skills || '',
      dataInsights: parsedData.dataInsights || '',
      timeline: parsedData.timeline || '',
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          parsedData: safeParsedData,
          assistantResponse: assistantResponse || '',
          ownerId: auth.currentUser?.uid || '',
        }),
      });
      if (response.ok) {
        setEmailSentMessage(
          "Congratulations, your project brief has been saved and emailed to you. Be sure to check your junk mail, so your dreams don't die!"
        );
        setShowEmailPopup(false);
      } else {
        setEmailSentMessage(
          'There was an issue sending your email. Please try again.'
        );
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setEmailSentMessage('There was an error. Please try again.');
    }
  };

  // -----------------------
  // 5) Handlers for "onBlur" from each UncontrolledTextarea
  // -----------------------
  const handleDataInsightsBlur = (value) => {
    setParsedData((prev) => ({ ...prev, dataInsights: value }));
  };
  const handleProjectSummaryBlur = (value) => {
    setParsedData((prev) => ({ ...prev, projectSummary: value }));
  };
  const handleDeliverablesBlur = (value) => {
    setParsedData((prev) => ({ ...prev, deliverables: value }));
  };
  const handleTimelineBlur = (value) => {
    setParsedData((prev) => ({ ...prev, timeline: value }));
  };

  return (
    <div className="confirm-optimization-wrapper234" ref={containerRef}>
      <div className="cont">
        {/* Top Row */}
        <div className="top-row234" ref={topRowRef}>
          <div className="brief-section234">
            <p className="brief-title234">/Your Brief</p>
            <p className="powered234">
              Powered by <span>Moccet AI</span>
            </p>
          </div>
          <div className="connect-section234">
            <p className="connect-title234">/Connect to Talent</p>
            <p className="connect-step234">Step Two</p>
          </div>
          <div className="deliver-section234">
            <p className="deliver-title234">/Deliver Project</p>
            <p className="deliver-step234">Step Three</p>
          </div>
        </div>

        {/* Fields */}
        <div className="fields-container234">
          <div
            className="field-block234"
            ref={(el) => (fieldRefs.current[0] = el)}
          >
            <p className="field-label234">/Market Data</p>
            <div className="field-input234">
              <UncontrolledResizableTextarea
                initialValue={parsedData.dataInsights}
                placeholder="Market Data"
                onBlur={handleDataInsightsBlur}
              />
            </div>
          </div>

          <div
            className="field-block234"
            ref={(el) => (fieldRefs.current[1] = el)}
          >
            <p className="field-label234">/Your Project</p>
            <div className="field-input234">
              <UncontrolledResizableTextarea
                initialValue={parsedData.projectSummary}
                placeholder="Your Project"
                onBlur={handleProjectSummaryBlur}
              />
            </div>
          </div>

          <div
            className="field-block234"
            ref={(el) => (fieldRefs.current[2] = el)}
          >
            <p className="field-label234">/Deliverables</p>
            <div className="field-input234">
              <UncontrolledResizableTextarea
                initialValue={parsedData.deliverables}
                placeholder="Deliverables"
                onBlur={handleDeliverablesBlur}
              />
            </div>
          </div>

          <div
            className="field-block234"
            ref={(el) => (fieldRefs.current[3] = el)}
          >
            <p className="field-label234">/Timeline</p>
            <div className="field-input234">
              <UncontrolledResizableTextarea
                initialValue={parsedData.timeline}
                placeholder="Timeline"
                onBlur={handleTimelineBlur}
              />
            </div>
          </div>
        </div>

        {/* Bottom Row */}
        <div className="bottom-row234" ref={bottomRowRef}>
          {/* -- Send to Email Link -- */}
          <p className="send-email-link234" onClick={handleOpenEmailPopup}>
            /Send to my Email
          </p>

          {/* -- Step 2: connect to talent -> Actually handleNext -> /set-budget */}
          <button className="connect-talent-btn234" onClick={handleNext}>
            /Connect to Talent
          </button>
        </div>

        {/* If there's a success/failure message */}
        {emailSentMessage && (
          <p className="email-sent-message234">{emailSentMessage}</p>
        )}
      </div>

      {/* Email Popup */}
      {showEmailPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Enter Your Email</h2>
            <input
              type="email"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="email-input"
            />
            <div className="popup-buttons">
              <button className="popup-button" onClick={handleSendEmail}>
                Send to Email
              </button>
              <button className="popup-button cancel" onClick={handleCloseEmailPopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmOptimization;
