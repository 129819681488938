import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { blogsData } from "./BlogsData";
import SEO from "../components/SEO"; // <-- Import your SEO component
import "./BlogDetail.css";

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // 1) Convert the id to a number
  const blogId = parseInt(id, 10);

  // 2) Always call hooks at the top-level
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // 3) Then find the blog
  const blog = blogsData.find((item) => item.id === blogId);

  // 4) If blog not found, render a fallback
  if (!blog) {
    return (
      <div className="blogDetailFullPage">
        <div className="blogDetailWrapper">
          <div className="blogDetailContainer">
            <button className="backButton" onClick={() => navigate("/blogs")}>
              ← Back to all blogs
            </button>
            <h1>Blog not found.</h1>
          </div>
        </div>
      </div>
    );
  }

  // Remove any <h1> from content so we don't duplicate the page's main <h1>
  const transformContent = (html) => {
    const removeH1Regex = /<h1[^>]*>[\s\S]*?<\/h1>/i;
    return html.replace(removeH1Regex, "");
  };

  const sanitizedContent = transformContent(blog.content);

  // ------------------------
  //       SEO SETUP
  // ------------------------

  // Provide a short description (e.g., excerpt) or fallback:
  const description = blog.summary || `Read about ${blog.title}.`;

  // Provide some keywords (if you have them in your data) or use a generic fallback:
  const keywords = blog.keywords
    ? blog.keywords.join(", ")
    : "blog, moccet, react, article";

  // JSON-LD for BlogPosting (Schema.org)
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://your-website.com/blogs/" + blog.id, // Adjust to your actual URL
    },
    headline: blog.title,
    description: description,
    articleBody: sanitizedContent,
    datePublished: blog.date,
    dateModified: blog.date, // or a different field if you track modifications
    author: {
      "@type": "Person",
      name: blog.author || "Unknown",
    },
    image: blog.image || "https://your-website.com/default-image.jpg", // If you have a blog image
  };

  return (
    <>
      {/* 
        1) Insert the SEO component *above* the page content.
        2) Pass in title, description, keywords, and structuredData.
      */}
      <SEO
        title={blog.title}
        description={description}
        keywords={keywords}
        structuredData={structuredData}
      />

      <div className="blogDetailFullPage">
        <div className="blogDetailWrapper">
          <motion.div
            className="blogDetailContainer"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            <button className="backButton" onClick={() => navigate("/blogs")}>
              ← Back to all blogs
            </button>

            <h1 className="detailTitle">{blog.title}</h1>
            <p className="blogDate">{blog.date}</p>

            <div
              className="detailContent"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
