// src/components/StudentChats/StudentChats.jsx
import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from 'firebase/firestore';
import './StudentChats.css'; // Make sure we import our updated CSS
import defaultIcon from '../../images/icon.png';

function StudentChats({ onChatSelected }) {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentInfo, setStudentInfo] = useState({ firstName: '', university: '' });

  useEffect(() => {
    const fetchChatsAndUserData = async () => {
      try {
        // 1) Check user
        const user = auth.currentUser;
        if (!user) {
          console.error('No user is signed in.');
          setLoading(false);
          return;
        }

        // 2) Fetch the student's data
        const userDocSnap = await getDoc(doc(db, 'users', user.uid));
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setStudentInfo({
            firstName: userData.firstName || '',
            university: userData.university || '',
          });
        } else {
          console.error('User document not found.');
        }

        // 3) Fetch all chatRooms for this student
        const chatsQuery = query(
          collection(db, 'chatRooms'),
          where('participants', 'array-contains', user.uid)
        );
        const chatSnapshot = await getDocs(chatsQuery);

        // 4) Build array of chats
        let chatsList = chatSnapshot.docs.map((docSnap) => {
          const chatData = docSnap.data();

          // The user’s unread count (optional)
          const unreadCount = chatData.unreadCounts?.[user.uid] || 0;

          // Find the newest message from chatData.messages
          let lastMessage = null;
          let lastMessageMs = 0; 
          let lastMessageSenderId = null;

          if (Array.isArray(chatData.messages) && chatData.messages.length > 0) {
            // "reduce" to find the newest message
            const newestMessageObj = chatData.messages.reduce((prev, curr) => {
              // Convert Firestore Timestamp to ms
              let currMs = 0;
              if (curr.timestamp?.toMillis) {
                currMs = curr.timestamp.toMillis();
              } else if (curr.timestamp instanceof Date) {
                currMs = curr.timestamp.getTime();
              }

              return currMs > prev.ms
                ? { msg: curr, ms: currMs }
                : prev;
            }, { msg: null, ms: 0 });

            lastMessage = newestMessageObj.msg?.message || null;
            lastMessageMs = newestMessageObj.ms;
            lastMessageSenderId = newestMessageObj.msg?.senderId || null;
          }

          return {
            id: docSnap.id,
            ...chatData,
            unreadCount,
            lastMessage,
            lastMessageMs,
            lastMessageSenderId,
          };
        });

        // 5) Sort chats by lastMessageMs descending
        chatsList.sort((a, b) => b.lastMessageMs - a.lastMessageMs);

        // Debug
        console.log('Sorted Student Chats =>', chatsList);

        setChats(chatsList);
      } catch (error) {
        console.error('Error fetching chats and user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChatsAndUserData();
  }, []);

  // If loading, show a spinner or text
  if (loading) return <p>Loading your chats...</p>;

  return (
    <div className="chats-container79">
      <h1>
        {studentInfo.firstName} | {studentInfo.university}
      </h1>
      <h2>Collaborate</h2>

      <div className="chat-items-container79">
        {chats.length > 0 ? (
          chats.map((chat) => {
            const {
              id,
              businessName,
              projectName,
              unreadCount,
              lastMessage,
              lastMessageMs,
              lastMessageSenderId,
            } = chat;

            // Quick function to format "time ago"
            const formatTimeAgo = (timestamp) => {
              if (!timestamp) return '';
              const now = Date.now();
              const diff = now - timestamp; // ms
              const mins = Math.floor(diff / 60000);
              if (mins < 1) return 'just now';
              if (mins < 60) return `${mins} mins`;
              const hours = Math.floor(mins / 60);
              if (hours < 24) return `${hours} hrs`;
              const days = Math.floor(hours / 24);
              return `${days} day${days > 1 ? 's' : ''}`;
            };

            // Decide who the last message is from
            // If lastMessageSenderId === current student's ID => "You:"
            // Else => "businessName:"
            let prefix = '';
            if (lastMessage) {
              if (lastMessageSenderId === auth.currentUser?.uid) {
                prefix = 'You: ';
              } else {
                prefix = (businessName || 'Business') + ': ';
              }
            }

            const lastMsgText = lastMessage ? prefix + lastMessage : '';

            return (
              <div
                key={id}
                className={`chat-item79 ${unreadCount > 0 ? 'unread' : ''}`}
                onClick={() => onChatSelected(id)}
              >
                {/* ICON BUBBLE with badge */}
                <div className="icon-bubble79">
                  <img src={defaultIcon} alt="Chat Icon" className="chat-icon79" />
                  {unreadCount > 0 && (
                    <span className="unread-badge79">
                      {unreadCount}
                    </span>
                  )}
                </div>

                <div className="chat-text-container79">
                  <div className="chat-header-row79">
                    <span className="chat-user79">
                      {businessName || 'Moccet Creator'}
                    </span>
                    <span className="chat-time79">
                      {formatTimeAgo(lastMessageMs)}
                    </span>
                  </div>
                  <div className="chat-project79">
                    {projectName || 'Unnamed Project'}
                  </div>
                  {lastMsgText && (
                    <p className="chat-last-message79">{lastMsgText}</p>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p>No chats available.</p>
        )}
      </div>
    </div>
  );
}

export default StudentChats;
