import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import rightarrow from '../../images/right-arrow.png';
import './SuccessPage.css';

const SuccessPage = () => {
  const location = useLocation();
  const { title, budget } = location.state || {};
  const navigate = useNavigate();

  return (
    <div className="success-page-container">
      <div className="success-page-content">
        <p className="live-text">
          Congratulations <br/>
          Your Project is Live on Moccet
        </p>
        <p className="experience-text">
          Experience transformative results with top-tier experts and no compromise.
        </p>
      </div>
    </div>
  );
};

export default SuccessPage;
