import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, onSnapshot, doc } from 'firebase/firestore';

import './NavBar.css';
import mImg from '../images/m.png';
import occetImg from '../images/occet.png';
import dotImg from '../images/dot.png';

import { FaUserCircle, FaEnvelope } from 'react-icons/fa';

function NavBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadChats, setUnreadChats] = useState(0);

  // Tracks whether user has scrolled past 50px
  const [isScrolled, setIsScrolled] = useState(false);

  // For user sidebar
  const [isUserSidebarOpen, setIsUserSidebarOpen] = useState(false);
  const userSidebarRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  // Listen for auth changes
  useEffect(() => {
    let unsubscribeFromAuth = null;
    let unsubscribeFromChats = null;
    let unsubscribeFromUserDoc = null;

    unsubscribeFromAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        try {
          const userDocRef = doc(db, 'users', user.uid);
          unsubscribeFromUserDoc = onSnapshot(
            userDocRef,
            (docSnapshot) => {
              if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setUserRole(userData.role);

                // If student/business, track unread chats
                if (userData.role === 'student' || userData.role === 'business') {
                  if (unsubscribeFromChats) {
                    unsubscribeFromChats();
                  }
                  unsubscribeFromChats = fetchUnreadChatsCount();
                }
              } else {
                console.log('Waiting for user document to be created...');
              }
            },
            (error) => {
              console.error('Error listening to user document:', error);
            }
          );
        } catch (error) {
          console.error('Error fetching user data from Firestore:', error);
        }
      } else {
        setIsLoggedIn(false);
        setUserRole(null);
        setUnreadChats(0);
        if (unsubscribeFromChats) unsubscribeFromChats();
        if (unsubscribeFromUserDoc) unsubscribeFromUserDoc();
      }
    });

    return () => {
      if (unsubscribeFromAuth) unsubscribeFromAuth();
      if (unsubscribeFromChats) unsubscribeFromChats();
      if (unsubscribeFromUserDoc) unsubscribeFromUserDoc();
    };
  }, []);

  // Fetch unread chats
  const fetchUnreadChatsCount = () => {
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user found.');
      return;
    }
    const chatRoomsRef = collection(db, 'chatRooms');
    const q = query(chatRoomsRef, where('participants', 'array-contains', user.uid));

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        let totalUnread = 0;
        querySnapshot.forEach((chatDoc) => {
          const chatData = chatDoc.data();
          const unreadCounts = chatData.unreadCounts || {};
          const unreadCount = unreadCounts[user.uid] || 0;
          totalUnread += unreadCount;
        });
        setUnreadChats(totalUnread);
      },
      (error) => {
        console.error('Error fetching unread chats:', error);
      }
    );
    return unsubscribe;
  };

  // ========================
  //    NAV LINK HANDLERS
  // ========================

  // Regular link click (just navigate)
  const handleLinkClick = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  // “Use Moccet AI” -> scroll to ProjectOptimization on home page
  const handleScrollToProjectOptimization = () => {
    if (location.pathname === '/') {
      setTimeout(() => {
        window.dispatchEvent(new Event('scrollToProjectOptimization'));
      }, 100);
    } else {
      navigate('/', { state: { scrollToProjectOptimization: true } });
    }
  };

  // Account link
  const handleAccountLink = () => {
    setIsMenuOpen(false);
    if (userRole === 'business') {
      navigate('/business-dashboard?section=account');
    } else if (userRole === 'student') {
      navigate('/student-dashboard?section=account');
    } else {
      navigate('/signin');
    }
  };

  // Dashboard
  const handleDashboardLink = () => {
    setIsMenuOpen(false);
    if (userRole === 'business') {
      navigate('/business-dashboard');
    } else if (userRole === 'student') {
      navigate('/student-dashboard');
    } else {
      navigate('/');
    }
  };

  // Collaborate
  const handleCollaborateLink = () => {
    setIsMenuOpen(false);
    if (userRole === 'business') {
      navigate('/business-dashboard?section=collaborate');
    } else if (userRole === 'student') {
      navigate('/student-dashboard?section=collaborate');
    } else {
      navigate('/signin');
    }
  };

  // Notifications
  const handleNotificationsLink = () => {
    setIsMenuOpen(false);
    if (userRole === 'business') {
      navigate('/notifications');
    } else if (userRole === 'student') {
      navigate('/notifications');
    } else {
      navigate('/notifications');
    }
  };

  // Logo click
  const handleLogoClick = () => {
    handleDashboardLink();
  };

  // Mobile menu toggle
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Desktop user sidebar toggle
  const toggleUserSidebar = () => {
    setIsUserSidebarOpen(!isUserSidebarOpen);
  };

  // User sidebar link
  const handleUserSidebarLinkClick = (path) => {
    navigate(path);
    setIsUserSidebarOpen(false);
  };

  // Logout
  const handleLogout = () => {
    auth.signOut();
    setIsUserSidebarOpen(false);
    navigate('/');
  };

  // Handle scrolling
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close user sidebar if click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isUserSidebarOpen &&
        userSidebarRef.current &&
        !userSidebarRef.current.contains(event.target)
      ) {
        setIsUserSidebarOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isUserSidebarOpen]);

  // ========================
  //    BUILD NAV LINKS
  // ========================
  let navLinks = [];

  if (isLoggedIn && userRole === 'student') {
    navLinks = [
      { name: 'account', action: handleAccountLink },
      { name: 'project board', path: '/project-board' },
      { name: 'dashboard', action: handleDashboardLink },
      { name: 'support', path: '/support' },
      { name: 'collaborate', action: handleCollaborateLink, showBadge: true },
      { name: 'notifications', action: handleNotificationsLink, showBadge: true },
      {
        name: 'log out',
        action: () => {
          auth.signOut();
          navigate('/');
        },
      },
    ];
  } else if (isLoggedIn && userRole === 'business') {
    navLinks = [
      { name: 'account', action: handleAccountLink },
      { name: 'upload project', path: '/project-optimization' },
      { name: 'dashboard', action: handleDashboardLink },
      { name: 'applications', path: '/applications' },
      { name: 'collaborate', action: handleCollaborateLink, showBadge: true },
      { name: 'notifications', action: handleNotificationsLink, showBadge: true },
      {
        name: 'log out',
        action: () => {
          auth.signOut();
          navigate('/');
        },
      },
    ];
  } else {
    // Not logged in
    navLinks = [
      { name: 'Use Moccet AI', styleType: 'black', action: handleScrollToProjectOptimization },
      { name: 'Blog', path: '/blogs', styleType: 'outline' },
      { name: 'Log in', path: '/signin', styleType: 'outline' },
      { name: 'Sign up', path: '/signup', styleType: 'outline' },
      { icon: <FaEnvelope />, path: '/refer-a-friend', styleType: 'outlineIcon' },
    ];
  }

  // Payment pages (nav hidden)
  const paymentPages = [
    '/payment',
    '/project-optimization',
    '/business-signup',
    '/confirm-optimization',
    '/set-budget',
  ];
  const currentPath = location.pathname.toLowerCase().replace(/\/+$/, '');
  const isPaymentPage = paymentPages.includes(currentPath);

  // Base color (if not scrolled)
  const normalNavbarColor = isPaymentPage ? 'white' : '#e6e8f8';

  // If on dashboard, we want transparent by default
  const isOnDashboard =
    currentPath === '/business-dashboard' || currentPath === '/student-dashboard';
  const navbarBackgroundColor = isOnDashboard ? 'transparent' : normalNavbarColor;

  // Final background for the navbar:
  // becomes transparent once user scrolls beyond 50px
  const finalBackgroundColor = isScrolled ? 'transparent' : navbarBackgroundColor;

  return (
    <div className="bar" style={{ backgroundColor: finalBackgroundColor }}>
      <div className="navbar" style={{ backgroundColor: finalBackgroundColor }}>
        {/* LOGO AREA */}
        <div className="logo-area" onClick={handleLogoClick}>
          <div className={`text-logo ${isScrolled ? 'scrolled' : ''}`}>
            <div className="logo-wrapper">
              <img src={mImg} alt="m" className="m-img" />
              <img src={occetImg} alt="occet" className="occet-img" />
              <img src={dotImg} alt="dot" className="dot-img" />
            </div>
          </div>
        </div>

        {/*
          ========================================
          DESKTOP NAV LINKS
          ========================================
          Hide if:
            1) It's a payment page, OR
            2) User is logged in (business/student).
          So they only show for not-logged-in, non-payment pages.
        */}
        {!isPaymentPage && !isLoggedIn && (
          <div className="links-container-desktop">
            {navLinks.map((link, index) => {
              let linkClass = '';
              if (link.styleType === 'black') linkClass = 'navbar-button-black';
              else if (link.styleType === 'outline') linkClass = 'navbar-button-outline';
              else if (link.styleType === 'outlineIcon') linkClass = 'navbar-icon-outline';

              if (link.showBadge && unreadChats > 0) {
                linkClass += ' collaborate-link unread';
              }

              const content = link.icon ? link.icon : link.name;

              return (
                <span
                  key={index}
                  onClick={() =>
                    link.action
                      ? link.action()
                      : handleLinkClick(link.path)
                  }
                  className={linkClass}
                >
                  {content}
                  {link.showBadge && unreadChats > 0 && (
                    <sup className="unread-count">{unreadChats}</sup>
                  )}
                </span>
              );
            })}
          </div>
        )}

        {/* 
          If user IS logged in, show user icon on desktop (optional).
          Clicking it opens the user sidebar.
        */}
        {isLoggedIn && (
          <div className="desktop-user-icon" onClick={toggleUserSidebar}>
            <FaUserCircle size={32} />
            {unreadChats > 0 && (
              <sup className="unread-count desktop-unread">{unreadChats}</sup>
            )}
          </div>
        )}

        {/* MOBILE MENU ICON (Hamburger) */}
        {!isPaymentPage && (
          <div className="mobile-menu-icon" onClick={toggleMenu}>
            {isMenuOpen ? (
              <span className="close-icon">&times;</span>
            ) : (
              <span className="hamburger-icon">&#9776;</span>
            )}
          </div>
        )}
      </div>

      {/* MOBILE MENU OVERLAY */}
      {!isPaymentPage && (
        <div className={`links-container ${isMenuOpen ? 'open' : ''}`}>
          <div className={`links ${isMenuOpen ? 'mobile-dropdown' : ''}`}>
            {navLinks.map((link, index) => {
              let linkClass = '';
              if (link.styleType === 'black') linkClass = 'navbar-button-black';
              else if (link.styleType === 'outline') linkClass = 'navbar-button-outline';
              else if (link.styleType === 'outlineIcon') linkClass = 'navbar-icon-outline';

              if (link.showBadge && unreadChats > 0) {
                linkClass += ' collaborate-link unread';
              }

              const content = link.icon ? link.icon : link.name;

              return (
                <p
                  key={index}
                  onClick={() =>
                    link.action
                      ? link.action()
                      : handleLinkClick(link.path)
                  }
                  className={linkClass}
                >
                  {content}
                  {link.showBadge && unreadChats > 0 && (
                    <sup className="unread-count">{unreadChats}</sup>
                  )}
                </p>
              );
            })}
          </div>
        </div>
      )}

      {/* USER SIDEBAR (slides in from right) */}
      <div
        className={`user-sidebar ${isUserSidebarOpen ? 'open' : ''}`}
        ref={userSidebarRef}
      >
        <div className="user-sidebar-header">
          <span className="close-user-sidebar" onClick={toggleUserSidebar}>
            <FaUserCircle size={32} />
          </span>
        </div>

        <div className="user-sidebar-links">
          <p
            onClick={() => {
              setIsUserSidebarOpen(false);
              handleDashboardLink();
            }}
          >
            Dashboard
          </p>
          <p
            onClick={() => {
              setIsUserSidebarOpen(false);
              handleAccountLink();
            }}
          >
            Account Settings
          </p>
          <p onClick={() => handleUserSidebarLinkClick('/blog')}>Blog</p>

          <p
            onClick={() => {
              setIsUserSidebarOpen(false);
              handleCollaborateLink();
            }}
            className={unreadChats > 0 ? 'collaborate-link unread' : ''}
          >
            Collaborate
            {unreadChats > 0 && <sup className="unread-count">{unreadChats}</sup>}
          </p>

          <p
            onClick={() => {
              setIsUserSidebarOpen(false);
              handleNotificationsLink();
            }}
            className={unreadChats > 0 ? 'collaborate-link unread' : ''}
          >
            Notifications
            {unreadChats > 0 && <sup className="unread-count">{unreadChats}</sup>}
          </p>

          <p onClick={() => handleUserSidebarLinkClick('/support')}>Support</p>
          <p onClick={handleLogout}>Logout</p>
        </div>

        <div className="user-sidebar-bottom">
          <p
            onClick={() => {
              setIsUserSidebarOpen(false);
              navigate('/terms');
            }}
          >
            Privacy Policy
          </p>
          <p
            onClick={() => {
              setIsUserSidebarOpen(false);
              navigate('/terms');
            }}
          >
            Terms &amp; Conditions
          </p>
          <p className="nothing">Moccet 2024</p>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
