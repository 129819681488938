import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./Compliance.css";

const Compliance = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="complianceContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="complianceHeaderSection">
          <h1 className="compliancePageTitle">COMPLIANCE</h1>
        </div>

        {/* Legal Foundation */}
        <section className="legalFoundationSection">
          <h2 className="sectionSubtitle">Legal Foundation</h2>
          <p className="sectionText">
            Moccet abides by U.S. federal and state laws relevant to data
            privacy, e-commerce, intellectual property, labor classification,
            and consumer protections. We track legislative updates from bodies
            like the Federal Trade Commission (FTC), the Department of Labor
            (DOL), and relevant state authorities.
          </p>
        </section>

        {/* Obligations for Users */}
        <section className="obligationsSection">
          <h2 className="sectionSubtitle">Obligations for Users</h2>

          <div className="obligationItem">
            <h3 className="obligationTitle">1. Licensing &amp; Permits</h3>
            <p className="obligationDescription">
              Users engaging in specialized tasks must confirm they hold
              required professional certifications or local permits. Moccet
              disclaims liability for unlicensed or unregistered professional
              engagements.
            </p>
          </div>

          <div className="obligationItem">
            <h3 className="obligationTitle">2. Employment Laws</h3>
            <p className="obligationDescription">
              Moccet offers technology that connects professionals to projects.
              It is neither an employer nor a joint employer. Users must comply
              with the Fair Labor Standards Act (FLSA) and any state-level labor
              laws.
            </p>
          </div>
        </section>

        {/* Export Control */}
        <section className="exportControlSection">
          <h2 className="sectionSubtitle">Export Control</h2>
          <p className="sectionText">
            Certain project data or talent relationships may fall under U.S.
            export regulations. Users must not share restricted data without
            verifying lawful permissions. Moccet disclaims liability for
            user-led infractions of the Export Administration Regulations (EAR)
            or International Traffic in Arms Regulations (ITAR).
          </p>
        </section>

        {/* No Legal Advice */}
        <section className="noLegalAdviceSection">
          <h2 className="sectionSubtitle">No Legal Advice</h2>
          <p className="sectionText">
            All compliance resources on Moccet’s site reflect general best
            practices. For a specific legal question, consult an attorney.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Compliance;
