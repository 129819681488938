import React, { useEffect, useState, useRef } from "react"
import { auth, db } from "../firebaseConfig"
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { sendPasswordResetEmail } from "firebase/auth"
import sampleProfilePic from "../images/icon.png"
import { useNavigate } from "react-router-dom"  // if you're using React Router
import "./StudentProfile.css"

const StudentProfile = () => {
  const [userDetails, setUserDetails] = useState({})
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [pastProjects, setPastProjects] = useState("")
  const [editingField, setEditingField] = useState(null)

  const [statusMessage, setStatusMessage] = useState("")
  const [statusType, setStatusType] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid))
        if (userDoc.exists()) {
          const data = userDoc.data()
          setUserDetails(data)

          const nameString = `${data.firstName || ""} ${data.lastName || ""}`.trim()
          setFullName(nameString)

          const userEmail = data.email || user.email || ""
          setEmail(userEmail)

          setPastProjects(data.pastProjects || "")
        }
      }
    })
    return () => unsubscribe()
  }, [])

  const handleResetPassword = () => {
    const user = auth.currentUser
    if (user) {
      sendPasswordResetEmail(auth, user.email)
        .then(() => alert("Password reset email sent!"))
        .catch((error) => console.error("Error sending password reset email:", error))
    }
  }

  // If the user wants to upload a CV
  const handleUploadCV = () => {
    // Navigate to the upload page
    navigate("/uploadcv")  
  }

  return (
    <div className="profile-page">
      <div className="profile-header">
        <h1>/Profile</h1>
        <div className="user-icon">
          <img src={sampleProfilePic} alt="User icon" />
        </div>
      </div>

      <div className="profile-form">
        <div className="profile-form-row">
          <label>Name</label>
          <input
            type="text"
            value={fullName}
            readOnly={editingField !== "fullName"}
            onClick={() => setEditingField("fullName")}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>

        <div className="profile-form-row">
          <label>Email</label>
          <input
            type="email"
            value={email}
            readOnly={editingField !== "email"}
            onClick={() => setEditingField("email")}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="profile-form-row">
          <label>Reset Password</label>
          <p className="profilebutton" onClick={handleResetPassword}>
            Reset Password
          </p>
        </div>

        <div className="profile-form-row">
          <label>Previous Projects</label>
          <textarea
            value={pastProjects}
            readOnly={editingField !== "pastProjects"}
            onClick={() => setEditingField("pastProjects")}
            onChange={(e) => setPastProjects(e.target.value)}
          />
        </div>

        {/* Additional fields or Save logic omitted for brevity */}

        <div className="profile-form-row">
          <p className="profilebutton" onClick={handleUploadCV}>
            Upload CV
          </p>
        </div>
      </div>
    </div>
  )
}

export default StudentProfile
