// src/pages/NotificationsPage.jsx
import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function NotificationsPage() {
  const [unreadChats, setUnreadChats] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      // If not logged in, redirect or do something appropriate
      navigate('/signin');
      return;
    }

    // 1) Fetch the user document to determine their role
    const userDocRef = doc(db, 'users', user.uid);
    getDoc(userDocRef)
      .then((snap) => {
        if (snap.exists()) {
          const data = snap.data();
          setUserRole(data.role || '');
        }
      })
      .catch((err) => {
        console.error('Error fetching userDoc:', err);
      });

    // 2) Listen to all chatRooms that involve this user
    const chatRoomsRef = collection(db, 'chatRooms');
    const q = query(chatRoomsRef, where('participants', 'array-contains', user.uid));

    const unsubscribe = onSnapshot(q, (querySnap) => {
      const unreadList = [];
      querySnap.forEach((docSnap) => {
        const chatData = docSnap.data();
        const unreadCounts = chatData.unreadCounts || {};
        const count = unreadCounts[user.uid] || 0;

        if (count > 0) {
          // This chat has unread messages for the user
          unreadList.push({
            id: docSnap.id,
            ...chatData,
            unreadCount: count,
          });
        }
      });

      setUnreadChats(unreadList);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  // Format timestamps (optional utility)
  const formatTimeAgo = (timestamp) => {
    if (!timestamp) return '';
    const now = Date.now();
    const diff = now - timestamp;
    const mins = Math.floor(diff / 60000);
    if (mins < 1) return 'just now';
    if (mins < 60) return `${mins} mins ago`;
    const hours = Math.floor(mins / 60);
    if (hours < 24) return `${hours} hrs ago`;
    const days = Math.floor(hours / 24);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  };

  // Route the user to their dashboard's "collaborate" section
  // with chatId in the query string
  const openChat = (chatId) => {
    if (userRole === 'business') {
      navigate(`/business-dashboard?section=collaborate&chatId=${chatId}`);
    } else if (userRole === 'student') {
      navigate(`/student-dashboard?section=collaborate&chatId=${chatId}`);
    } else {
      // fallback for non-logged in or unknown roles
      navigate('/signin');
    }
  };

  if (loading) {
    return <div style={{ padding: '1rem' }}>Loading notifications...</div>;
  }

  return (
    <div style={{ padding: '1rem' }}>
      <h1>Notifications</h1>

      {unreadChats.length === 0 ? (
        <p>You have no new notifications right now.</p>
      ) : (
        unreadChats.map((chat) => {
          // Show "other party" name
          const otherPartyName =
            userRole === 'business'
              ? chat.studentName || 'Unnamed Student'
              : chat.businessName || 'Unnamed Business';

          return (
            <div
              key={chat.id}
              style={{
                border: '1px solid #ccc',
                borderRadius: '6px',
                padding: '1rem',
                marginBottom: '0.75rem',
                cursor: 'pointer',
              }}
              onClick={() => openChat(chat.id)}
            >
              <h3 style={{ margin: '0 0 0.5rem' }}>{otherPartyName}</h3>
              <p style={{ margin: 0, fontSize: '0.9rem' }}>
                <strong>
                  You have {chat.unreadCount} new message
                  {chat.unreadCount > 1 ? 's' : ''}!
                </strong>
              </p>
              {chat.lastMessageTime && (
                <p style={{ margin: '0.25rem 0 0', fontSize: '0.8rem', color: '#666' }}>
                  Last message: {formatTimeAgo(chat.lastMessageTime)}
                </p>
              )}
            </div>
          );
        })
      )}
    </div>
  );
}

export default NotificationsPage;
