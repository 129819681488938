// src/StudentDash.jsx

import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import FlipMove from 'react-flip-move';

// Student-based chat components
import StudentChats from '../components/chat/StudentChats';
import StudentChatPage from '../components/chat/StudentChatPage';
import ProjectOptimization from '../components/projects/ProjectOptimization'; 
import MyStudentProjects from './MyStudentProjects';
import StudentProfile from './StudentProfile';
import MyProjectBoard from '../components/projects/ProjectBoard';

import defaultProjectIcon from '../images/icon.png';
import { blogsData } from './BlogsData';

// The CSS for this dash
import './StudentDash.css';

const StudentDash = () => {
  const [loaded, setLoaded] = useState(false);
  const [displayName, setDisplayName] = useState('Moccet Talent');
  const [expanded, setExpanded] = useState(false);
  const [activeHeading, setActiveHeading] = useState('dashboard');
  const [view, setView] = useState('dashboard');
  const [selectedChatId, setSelectedChatId] = useState(null);

  // We'll store chat docs in "ongoingProjects" to mimic your previous naming
  const [ongoingProjects, setOngoingProjects] = useState([]);

  // For the 3 blog previews
  const [latestBlogs, setLatestBlogs] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  // The sidebar headings
  const [sections, setSections] = useState([
    { id: 'dashboard', label: '/Dashboard' },
    { id: 'collaborate', label: '/Collaborate' },
    { id: 'upload', label: '/Upload a New Project' },
    { id: 'blogs', label: '/Blogs' },
    { id: 'project-board', label: '/Project Board' },
  ]);

  // ==========================
  // 1) Fetch the chatRooms => ongoingProjects
  // ==========================
  useEffect(() => {
    const fetchOngoingChats = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        // Exactly like StudentChats => find all chatRooms for this student
        const chatsQuery = query(
          collection(db, 'chatRooms'),
          where('participants', 'array-contains', user.uid)
        );
        const chatSnapshot = await getDocs(chatsQuery);

        // Build an array
        let results = chatSnapshot.docs.map((docSnap) => {
          const chatData = docSnap.data();

          // The user’s unread count (optional)
          const unreadCount = chatData.unreadCounts?.[user.uid] || 0;

          // Find the newest message
          let lastMessage = null;
          let lastMessageMs = 0;
          let lastMessageSenderId = null;

          if (Array.isArray(chatData.messages) && chatData.messages.length > 0) {
            // "reduce" to find the newest
            const newestMsgObj = chatData.messages.reduce(
              (prev, curr) => {
                let currMs = 0;
                if (curr.timestamp?.toMillis) {
                  currMs = curr.timestamp.toMillis();
                } else if (curr.timestamp instanceof Date) {
                  currMs = curr.timestamp.getTime();
                }
                return currMs > prev.ms ? { msg: curr, ms: currMs } : prev;
              },
              { msg: null, ms: 0 }
            );

            lastMessage = newestMsgObj.msg?.message || null;
            lastMessageMs = newestMsgObj.ms;
            lastMessageSenderId = newestMsgObj.msg?.senderId || null;
          }

          return {
            id: docSnap.id,
            ...chatData,
            unreadCount,
            lastMessage,
            lastMessageMs,
            lastMessageSenderId,
          };
        });

        // Sort by newest message first
        results.sort((a, b) => b.lastMessageMs - a.lastMessageMs);

        // Store in "ongoingProjects" for your dash
        setOngoingProjects(results);
      } catch (err) {
        console.error('Error fetching ongoing chatRooms in StudentDash:', err);
      }
    };

    fetchOngoingChats();
  }, []);

  // ==========================
  // 2) Format "time ago" helper
  // ==========================
  const formatTimeAgo = (timestamp) => {
    if (!timestamp) return '';
    const now = Date.now();
    const diff = now - timestamp;
    const mins = Math.floor(diff / 60000);
    if (mins < 1) return 'just now';
    if (mins < 60) return `${mins} mins`;
    const hours = Math.floor(mins / 60);
    if (hours < 24) return `${hours} hrs`;
    const days = Math.floor(hours / 24);
    return `${days} day${days > 1 ? 's' : ''}`;
  };

  // ==========================
  // 3) Handler => open a chat on project click
  // ==========================
  const handleProjectChatSelected = (proj) => {
    // If you want to replicate "collaborate" => set selectedChatId
    setSelectedChatId(proj.id); // doc ID in chatRooms
    setView('collaborate');
    setActiveHeading('collaborate');
  };

  // ==========================
  // Misc. useEffects & Helpers
  // ==========================
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const topThree = blogsData.slice(0, 3);
    setLatestBlogs(topThree);
  }, []);

  const truncateTitle = (title = '', maxWords = 5) => {
    const words = title.trim().split(/\s+/);
    if (words.length <= maxWords) return title;
    return words.slice(0, maxWords).join(' ');
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramSection = searchParams.get('section');
    if (paramSection) {
      if (sections.some((s) => s.id === paramSection)) {
        handleHeadingClick(paramSection);
      } else if (paramSection === 'account') {
        setView('account');
        setActiveHeading('');
      }
    }
    // eslint-disable-next-line
  }, [location.search]);

  // Fetch the student's name
  useEffect(() => {
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          if (data.firstName) {
            setDisplayName(data.firstName);
          } else {
            setDisplayName('Student');
          }
        }
      }
    };
    fetchUserName();
  }, []);

  // ==========================
  // 4) Sidebar heading click
  // ==========================
  const handleHeadingClick = (sectionId) => {
    setActiveHeading(sectionId);

    // reorder
    setSections((prev) => {
      const updated = [...prev];
      const index = updated.findIndex((s) => s.id === sectionId);
      if (index > -1) {
        const [item] = updated.splice(index, 1);
        updated.unshift(item);
      }
      return updated;
    });

    if (sectionId === 'blogs') {
      navigate('/blogs');
    } else if (sectionId === 'dashboard') {
      setView('dashboard');
      setSelectedChatId(null);
    } else if (sectionId === 'collaborate') {
      setView('collaborate');
      setSelectedChatId(null);
    } else if (sectionId === 'upload') {
      setView('upload');
      setSelectedChatId(null);
    } else if (sectionId === 'current') {
      setView('projects');
      setSelectedChatId(null);
    } else if (sectionId === 'project-board') {
      setView('projectBoard');
      setSelectedChatId(null);
    } else {
      setView('dashboard');
      setSelectedChatId(null);
    }
  };

  // Toggle expand
  const handleDotsClick = () => {
    setExpanded(!expanded);
  };

  // Chat selected (from StudentChats)
  const handleChatSelected = (chatId) => {
    setSelectedChatId(chatId);
    setView('collaborate');
    setActiveHeading('collaborate');
  };

  // Bottom account, terms, logout
  const handleShowAccount = () => {
    setActiveHeading('');
    setView('account');
  };
  const handleShowTerms = () => {
    navigate('/terms');
  };
  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  // ==========================
  // Return JSX
  // ==========================
  return (
    <div
      className={`business-dash-container67 ${loaded ? 'loaded67' : ''} ${
        expanded ? 'expanded-mode67' : ''
      }`}
    >
      {/* LEFT SIDEBAR */}
      <aside className={`sidebar67 ${expanded ? 'collapsed67' : ''}`}>
        <FlipMove typeName={null} duration={400} easing="ease-in-out">
          {sections.map((section) => (
            <div key={section.id} className="section67">
              <h2
                className={`slash-heading67 ${
                  activeHeading === section.id ? 'clicked67' : ''
                }`}
                onClick={() => handleHeadingClick(section.id)}
              >
                {section.label}
              </h2>

              {section.id === 'blogs' && (
                <div className="blog-previews67">
                  {latestBlogs.map((blog, index) => (
                    <p
                      key={blog.id}
                      className={`blog-preview-item67 ${
                        index === 1 ? 'second-item67' : ''
                      } ${index === 2 ? 'third-item67' : ''}`}
                      onClick={() => navigate(`/blog/${blog.id}`)}
                    >
                      /{truncateTitle(blog.title, 5)}
                    </p>
                  ))}
                </div>
              )}

              {section.id === 'upload' && (
                <button
                  className="card-button3167"
                  onClick={() => handleHeadingClick('upload')}
                >
                  Use Moccet AI
                </button>
              )}
            </div>
          ))}
        </FlipMove>
      </aside>

      {/* RIGHT CONTENT */}
      <section className={`main-content67 ${expanded ? 'fullwidth67' : ''}`}>
        <div className="nav-dots67" onClick={handleDotsClick}>
          <div className="dot67 purple-dot67" />
          <div className="dot67 grey-dot67" />
          <div className="dot67 grey-dot67" />
        </div>

        {/* COLLABORATE => StudentChats or StudentChatPage */}
        {view === 'collaborate' && (
          <>
            {!selectedChatId ? (
              <div className="chats-fullwidth67">
                <StudentChats onChatSelected={handleChatSelected} />
              </div>
            ) : (
              <div className="chats-fullwidth67">
                <StudentChatPage chatId={selectedChatId} />
              </div>
            )}
          </>
        )}

        {/* UPLOAD => ProjectOptimization */}
        {view === 'upload' && (
          <div className="project-fullwidth67">
            <ProjectOptimization />
          </div>
        )}

        {/* PROJECTS => MyStudentProjects */}
        {view === 'projects' && <MyStudentProjects />}

        {/* ACCOUNT => StudentProfile */}
        {view === 'account' && (
          <div className="account-fullwidth67">
            <StudentProfile />
          </div>
        )}

        {/* DASHBOARD => default view */}
        {view === 'dashboard' && (
          <>
            <h2 className="welcome-title67">Welcome back, {displayName}</h2>

            <div className="ongoing-projects-section67">
              <h3 className="ongoing-title167">Upload a project</h3>
            </div>
            <button
              className="card-button31267"
              onClick={() => handleHeadingClick('upload')}
            >
              Use Moccet AI
            </button>

            {/* Ongoing Projects => same data from chatRooms */}
            <div className="ongoing-projects-section67">
              <h3 className="ongoing-title67">Your ongoing projects</h3>
              {ongoingProjects.length > 0 ? (
                ongoingProjects.map((proj) => {
                  const {
                    id,
                    businessName,
                    projectName,
                    unreadCount,
                    lastMessage,
                    lastMessageMs,
                    lastMessageSenderId,
                  } = proj;

                  // Same "prefix" logic as StudentChats
                  let prefix = '';
                  if (lastMessage) {
                    if (lastMessageSenderId === auth.currentUser?.uid) {
                      prefix = 'You: ';
                    } else {
                      prefix = (businessName || 'Business') + ': ';
                    }
                  }
                  const finalLastMessage = lastMessage ? prefix + lastMessage : '';

                  return (
                    <div
                      key={id}
                      className="chat-item67"
                      onClick={() => handleProjectChatSelected(proj)}
                    >
                      <img
                        src={defaultProjectIcon}
                        alt="Project Icon"
                        className="chat-icon67"
                      />
                      <div className="chat-text-container67">
                        <div className="chat-header-row67">
                          <span className="chat-user67">
                            {businessName || 'Moccet Creator'}
                          </span>
                          <span className="chat-time67">
                            {formatTimeAgo(lastMessageMs)}
                          </span>
                        </div>
                        <div className="chat-project67">
                          {projectName || 'Unnamed Project'}
                        </div>
                        <p className="chat-last-message67">
                          {finalLastMessage || 'Tap to see project'}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No ongoing projects found.</p>
              )}
            </div>
          </>
        )}

        {/* PROJECT BOARD => MyProjectBoard */}
        {view === 'projectBoard' && (
          <div className="projectBoard-fullwidth67">
            <MyProjectBoard />
          </div>
        )}
      </section>
    </div>
  );
};

export default StudentDash;
