import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './HomePage.css';

import ProjectOptimization from '../components/projects/ProjectOptimization';
import HeroTwo from '../components/home/HeroTwo';
import ValueProposition from '../components/home/ValueProposition';
import Testimonial from '../components/home/Testimonials';
import MarketingVid from '../components/home/MarketingVid';
import Footer from '../components/Footer';
import loadingVideoDesktop from '../images/background2.mp4';
import loadingVideoMobile from '../images/background3.mp4';

function HomePage() {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  // Refs for each section
  const projectOptimizationRef = useRef(null);
  const valuePropositionRef = useRef(null);

  // Video ref to attempt programmatic play
  const videoRef = useRef(null);

  // Decide which video to load
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // AOS init (if you use it for animations)
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const videoSrc = isMobile ? loadingVideoMobile : loadingVideoDesktop;

  // Attempt to autoplay video on mount (especially for iOS)
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;          // Ensure muted
      videoRef.current.playsInline = true;    // For iOS inline
      // Some older Safari needs webkit-playsinline set as an attribute
      videoRef.current.setAttribute('webkit-playsinline', 'true');
      // Attempt to play programmatically
      videoRef.current.play().catch((err) => {
        console.log('Autoplay was prevented:', err);
      });
    }
  }, [videoSrc]);

  // End loading overlay after video finishes
  const handleVideoEnd = () => {
    setFadeOut(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  // ====== SCROLL FUNCTIONS ======
  const scrollToProjectOptimization = () => {
    if (projectOptimizationRef.current) {
      projectOptimizationRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToValueProposition = () => {
    if (valuePropositionRef.current) {
      const elementPosition = valuePropositionRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 250;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  };

  // ====== REACT ROUTER LOGIC ======
  const location = useLocation();

  // If we came from NavBar -> navigate('/', { state: { scrollToProjectOptimization: true } })
  useEffect(() => {
    if (location.state && location.state.scrollToProjectOptimization) {
      scrollToProjectOptimization();
    }
  }, [location.state]);

  // If user was already on '/', the NavBar triggers a custom event
  useEffect(() => {
    const handleCustomScrollEvent = () => {
      scrollToProjectOptimization();
    };
    window.addEventListener('scrollToProjectOptimization', handleCustomScrollEvent);
    return () => {
      window.removeEventListener('scrollToProjectOptimization', handleCustomScrollEvent);
    };
  }, []);

  return (
    <>
      {isLoading && (
        <div className={`loading-overlay ${fadeOut ? 'fade-out' : ''}`}>
          <video
            ref={videoRef}
            className="loading-video"
            src={videoSrc}
            autoPlay
            muted
            playsInline
            onEnded={handleVideoEnd}
            // helpful attributes on iOS:
            webkit-playsinline="true"
            preload="auto"
          />
        </div>
      )}

      {!isLoading && (
        <div className="home-page-container">
          <div className="home-page">
            <div className="page12">
              <HeroTwo
                onScrollToProjectOptimization={scrollToProjectOptimization}
                onScrollToValueProposition={scrollToValueProposition}
              />
            </div>

            <div className="page123" ref={projectOptimizationRef}>
              <ProjectOptimization />
            </div>

            <div className="page435">
              <Testimonial />
            </div>

            <div ref={valuePropositionRef}>
              <ValueProposition />
              <MarketingVid />
            </div>

            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default HomePage;
