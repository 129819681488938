import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./Careers.css";

const Careers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="careersContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="careersHeaderSection">
          <h1 className="careersPageTitle">CAREERS</h1>
        </div>

        {/* Who We Are */}
        <section className="careersIntroSection">
          <h2 className="sectionSubtitle">Who We Are</h2>
          <p className="sectionText">
            Moccet fosters a culture of bold creativity balanced with robust
            legal and ethical compliance. We champion a future of AI-driven
            solutions, with roles for software engineers, data scientists,
            operations leaders, compliance officers, and more.
          </p>
        </section>

        {/* Career Opportunities */}
        <section className="careersOpportunitiesSection">
          <h2 className="sectionSubtitle">Career Opportunities</h2>

          <div className="careerOpportunity">
            <h3 className="opportunityTitle">1. AI &amp; Research</h3>
            <p className="opportunityDescription">
              Develop generative models that expedite project scoping and
              resource coordination, in compliance with relevant AI ethical
              guidelines (e.g., the White House’s Blueprint for an AI Bill of
              Rights, if eventually enacted into law).
            </p>
          </div>

          <div className="careerOpportunity">
            <h3 className="opportunityTitle">2. Product &amp; Engineering</h3>
            <p className="opportunityDescription">
              Collaborate on building a user-centric experience underpinned by
              secure infrastructure.
            </p>
          </div>

          <div className="careerOpportunity">
            <h3 className="opportunityTitle">3. Operations &amp; Compliance</h3>
            <p className="opportunityDescription">
              Oversee data privacy initiatives, labor classification concerns,
              and ensure Moccet remains aligned with evolving U.S. tech
              regulations.
            </p>
          </div>
        </section>

        {/* Equal Opportunity Statement */}
        <section className="equalOpportunitySection">
          <h2 className="sectionSubtitle">Equal Opportunity Statement</h2>
          <p className="sectionText">
            We uphold all anti-discrimination mandates under Title VII of the
            Civil Rights Act and the Americans with Disabilities Act. Every
            candidate is evaluated on merit, never on personal characteristics
            or protected status.
          </p>
        </section>

        {/* How to Apply */}
        <section className="applySection">
          <h2 className="sectionSubtitle">How to Apply</h2>
          <p className="sectionText">
            Email <a href="mailto:careers@moccet.com">careers@moccet.com</a> with
            your résumé, portfolio, or relevant background. This communication
            does not constitute a binding contract; any official employment
            offer will be contingent on additional legal checks.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Careers;
