// src/pages/StudentChatPage.jsx
import React, { useState, useEffect, useRef } from 'react';
import { db, auth, storage } from '../../firebaseConfig'; 
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  setDoc,
  onSnapshot,
  query,
  where,
  increment,
  getDocs,
  collection,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import './StudentChatPage.css';

// Example icons/images
import paperclipIcon from '../../images/paperclip.png';
import sendIcon from '../../images/send.png';
import brandPlaceholder from '../../images/icon1.png';   // business avatar
import studentPlaceholder from '../../images/icon.png';  // student avatar

function StudentChatPage({ chatId }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  // For file input + preview
  const fileInputRef = useRef(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewDataUrl, setPreviewDataUrl] = useState('');

  // “Reply to” logic
  const [replyingTo, setReplyingTo] = useState(null);

  // Show time + “Reply” on bubble click
  const [activeMessageIndex, setActiveMessageIndex] = useState(null);

  // Student info
  const [currentUserFirstName, setCurrentUserFirstName] = useState('');
  // Other user (business) info
  const [otherUserFirstName, setOtherUserFirstName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [projectName, setProjectName] = useState('');

  // For auto-scrolling
  const messagesEndRef = useRef(null);

  // Fullscreen image overlay + zoom
  const [expandedImageUrl, setExpandedImageUrl] = useState(null);
  const [zoomScale, setZoomScale] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);

  // Drag & drop overlay
  const [dragOver, setDragOver] = useState(false);

  // ================================
  // TIME FORMAT
  // ================================
  const formatRelativeTime = (dateObj) => {
    if (!dateObj) return '';
    const now = new Date();
    const diffMs = now - dateObj;
    const diffMins = Math.floor(diffMs / 60000);

    if (diffMins < 1) {
      return 'sent just now';
    } else if (diffMins < 60) {
      return `sent ${diffMins} minute${diffMins !== 1 ? 's' : ''} ago`;
    } else {
      const diffHours = Math.floor(diffMins / 60);
      if (diffHours < 24) {
        return `sent ${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
      } else {
        const diffDays = Math.floor(diffHours / 24);
        if (diffDays < 7) {
          return diffDays === 1 ? 'sent 1 day ago' : `sent ${diffDays} days ago`;
        } else {
          return dateObj.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          });
        }
      }
    }
  };

  // Identify the other user (business)
  const getOtherUserId = () => {
    const user = auth.currentUser;
    if (!user) return null;
    const parts = chatId?.split('_') || [];
    return parts.find((id) => id !== user.uid);
  };

  // Check if message is last in group
  const isLastInSenderGroup = (msgs, index) => {
    if (index === msgs.length - 1) return true;
    const currentSender = msgs[index].senderId;
    const nextSender = msgs[index + 1].senderId;
    return currentSender !== nextSender;
  };

  // Show time + “Reply” for 3s if user clicks
  const handleMessageClick = (index) => {
    setActiveMessageIndex(index);
    setTimeout(() => {
      setActiveMessageIndex(null);
    }, 3000);
  };

  // ================================
  // FETCH CHAT DETAILS
  // ================================
  const fetchChatDetails = async (chatData = {}) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      // Current (student) user’s firstName
      const userDocSnap = await getDoc(doc(db, 'users', user.uid));
      if (userDocSnap.exists()) {
        setCurrentUserFirstName(userDocSnap.data().firstName || '');
      }

      let otherUserId = null;
      const messagesArray = chatData.messages || [];
      if (messagesArray.length > 0) {
        const firstMsg = messagesArray[0];
        if (firstMsg.senderId && firstMsg.senderId !== user.uid) {
          otherUserId = firstMsg.senderId;
        }
      }
      if (!otherUserId) {
        const parts = chatId?.split('_') || [];
        otherUserId = parts.find((id) => id !== user.uid);
      }

      // If we have a business user ID, fetch details
      if (otherUserId) {
        const otherUserDocSnap = await getDoc(doc(db, 'users', otherUserId));
        if (otherUserDocSnap.exists()) {
          setOtherUserFirstName(otherUserDocSnap.data().firstName || '');
          setBusinessName(otherUserDocSnap.data().businessName || 'Unnamed Business');
        }

        // Possibly fetch project name from “applications”
        const appsRef = collection(db, 'applications');
        const q = query(
          appsRef,
          where('studentId', '==', user.uid),
          where('businessId', '==', otherUserId),
          where('isCollaborating', '==', true)
        );
        const appsSnapshot = await getDocs(q);
        if (!appsSnapshot.empty) {
          const appData = appsSnapshot.docs[0].data();
          setProjectName(appData.projectName || 'Unnamed Project');
          setBusinessName(appData.businessName || 'Unnamed Business');

          // Optionally write it back to the chat doc
          const chatRef = doc(db, 'chatRooms', chatId);
          await updateDoc(chatRef, {
            projectName: appData.projectName,
            businessName: appData.businessName,
          });
        } else {
          setProjectName('Unnamed Project');
        }
      }
    } catch (error) {
      console.error('Error fetching chat details:', error);
    }
  };

  // ================================
  // USEEFFECT: LISTEN TO CHAT
  // ================================
  useEffect(() => {
    if (!chatId) return;

    const chatRef = doc(db, 'chatRooms', chatId);
    const unsubscribe = onSnapshot(chatRef, async (docSnap) => {
      if (!docSnap.exists()) {
        await setDoc(chatRef, { messages: [], unreadCounts: {} });
        return;
      }

      const chatData = docSnap.data() || {};
      setMessages(chatData.messages || []);

      // Mark unread=0 for the student
      const user = auth.currentUser;
      if (user) {
        await updateDoc(chatRef, {
          [`unreadCounts.${user.uid}`]: 0,
        });
      }

      // If doc has projectName + businessName => set them, else fetch
      if (chatData.projectName && chatData.businessName) {
        setProjectName(chatData.projectName);
        setBusinessName(chatData.businessName);
      } else {
        fetchChatDetails(chatData);
      }
    });

    return () => unsubscribe();
  }, [chatId]);

  // ================================
  // REPLY LOGIC
  // ================================
  const handleReply = (message, idx) => {
    setReplyingTo({
      id: idx,
      text: message.message,
      senderName:
        message.senderId === auth.currentUser?.uid
          ? currentUserFirstName
          : otherUserFirstName,
    });
  };

  // ================================
  // SEND TEXT MESSAGE
  // ================================
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const user = auth.currentUser;
    if (!user) return;

    const chatRef = doc(db, 'chatRooms', chatId);
    const msgObj = {
      senderId: user.uid,
      message: newMessage,
      timestamp: new Date(),
      replyTo: replyingTo
        ? {
            id: replyingTo.id,
            text: replyingTo.text,
            senderName: replyingTo.senderName,
          }
        : null,
    };

    try {
      const otherUserId = getOtherUserId();
      await updateDoc(chatRef, {
        messages: arrayUnion(msgObj),
      });

      if (otherUserId) {
        await updateDoc(chatRef, {
          [`unreadCounts.${otherUserId}`]: increment(1),
        });
      }

      setNewMessage('');
      setReplyingTo(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // ================================
  // DRAG & DROP, IMAGE PREVIEW, ETC.
  // ================================
  const [dragOver2, setDragOver2] = useState(false);

  // local states for image preview
  const [previewFile2, setPreviewFile2] = useState(null);
  const [previewDataUrl2, setPreviewDataUrl2] = useState('');

  // expand/zoom image
  const [expandedImageUrl2, setExpandedImageUrl2] = useState(null);
  const [zoomScale2, setZoomScale2] = useState(1);
  const [translateX2, setTranslateX2] = useState(0);
  const [translateY2, setTranslateY2] = useState(0);
  const [isDragging2, setIsDragging2] = useState(false);
  const [dragStartX2, setDragStartX2] = useState(0);
  const [dragStartY2, setDragStartY2] = useState(0);

  // handle send image
  const handleSendImage2 = async (file) => {
    try {
      const user = auth.currentUser;
      if (!user || !file) return;

      const safeName = file.name || `imageFile`;
      const storageRef = ref(storage, `chatImages/${chatId}/${safeName}-${Date.now()}`);

      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      const chatRef = doc(db, 'chatRooms', chatId);
      const msgObj = {
        senderId: user.uid,
        imageUrl: downloadURL,
        timestamp: new Date(),
        replyTo: replyingTo
          ? {
              id: replyingTo.id,
              text: replyingTo.text,
              senderName: replyingTo.senderName,
            }
          : null,
      };

      const otherUserId = getOtherUserId();
      await updateDoc(chatRef, {
        messages: arrayUnion(msgObj),
      });
      if (otherUserId) {
        await updateDoc(chatRef, {
          [`unreadCounts.${otherUserId}`]: increment(1),
        });
      }

      setReplyingTo(null);
    } catch (err) {
      console.error('Error sending image:', err);
    }
  };

  // handle drag + drop
  const handleDragOver2 = (e) => {
    e.preventDefault();
    setDragOver2(true);
  };
  const handleDragEnter2 = (e) => {
    e.preventDefault();
    setDragOver2(true);
  };
  const handleDragLeave2 = (e) => {
    if (e.target === e.currentTarget) {
      setDragOver2(false);
    }
  };
  const handleDrop2 = (e) => {
    e.preventDefault();
    setDragOver2(false);
    if (!e.dataTransfer.files || !e.dataTransfer.files.length) return;
    const file = e.dataTransfer.files[0];
    if (!file) return;

    setPreviewFile2(file);
    const reader = new FileReader();
    reader.onload = (evt) => {
      setPreviewDataUrl2(evt.target.result);
    };
    reader.readAsDataURL(file);
  };

  // handle attach click
  const fileInputAttachRef2 = useRef(null);
  const handleAttachClick2 = () => {
    if (fileInputAttachRef2.current) {
      fileInputAttachRef2.current.click();
    }
  };

  // handle file input
  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewFile2(file);
      const reader = new FileReader();
      reader.onload = (evt) => {
        setPreviewDataUrl2(evt.target.result);
      };
      reader.readAsDataURL(file);
    }
    e.target.value = null;
  };

  // Cancel or confirm preview
  const handleCancelPreview2 = () => {
    setPreviewFile2(null);
    setPreviewDataUrl2('');
  };
  const handleConfirmPreview2 = () => {
    if (!previewFile2) return;
    handleSendImage2(previewFile2);
    setPreviewFile2(null);
    setPreviewDataUrl2('');
  };

  // Expand/zoom image
  const handleImageClick2 = (url) => {
    setZoomScale2(1);
    setTranslateX2(0);
    setTranslateY2(0);
    setExpandedImageUrl2(url);
  };
  const closeExpandedImage2 = () => {
    setExpandedImageUrl2(null);
  };
  const toggleZoom2 = () => {
    setZoomScale2((prev) => (prev === 1 ? 2 : 1));
    if (zoomScale2 === 2) {
      setTranslateX2(0);
      setTranslateY2(0);
    }
  };
  const handleImageMouseDown2 = (e) => {
    if (zoomScale2 === 1) return;
    setIsDragging2(true);
    setDragStartX2(e.clientX - translateX2);
    setDragStartY2(e.clientY - translateY2);
  };
  const handleImageMouseMove2 = (e) => {
    if (!isDragging2) return;
    setTranslateX2(e.clientX - dragStartX2);
    setTranslateY2(e.clientY - dragStartY2);
  };
  const handleImageMouseUpOrLeave2 = () => {
    setIsDragging2(false);
  };

  // Scroll to bottom on new messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div
      className={`business-chat-container313 ${dragOver2 ? 'drag-over' : ''}`}
      onDragOver={handleDragOver2}
      onDragEnter={handleDragEnter2}
      onDragLeave={handleDragLeave2}
      onDrop={handleDrop2}
    >
      {/* DRAG OVERLAY */}
      {dragOver2 && (
        <div className="drag-overlay">
          <div className="drag-overlay-content">
            <div className="drag-plus-sign">+</div>
            <p>Drag and drop your image</p>
          </div>
        </div>
      )}

      <div className="business-chat-header313">
        {businessName}
      </div>

      <div className="business-messages-wrapper313">
        {messages.map((msg, idx) => {
          const isSender = msg.senderId === auth.currentUser?.uid;

          let dateObj = null;
          if (msg.timestamp?.toDate) {
            dateObj = msg.timestamp.toDate();
          } else if (msg.timestamp) {
            dateObj = new Date(msg.timestamp);
          }
          const dateString = dateObj ? formatRelativeTime(dateObj) : '';

          const rowClass = `chat-item313 message-row313 ${
            isSender ? 'sender313' : 'recipient313'
          }`;

          // If it's last in consecutive group or user clicked => show meta
          const showMetaByDefault = isLastInSenderGroup(messages, idx);
          const showMeta = showMetaByDefault || activeMessageIndex === idx;

          return (
            <div key={idx} className={rowClass}>
              {!isSender && (
                <img
                  src={brandPlaceholder}
                  alt="Business"
                  className="chat-icon313"
                />
              )}
              {isSender && (
                <img
                  src={studentPlaceholder}
                  alt="Student"
                  className="chat-icon313"
                />
              )}

              <div className="chat-message-wrapper313">
                <div
                  className="chat-bubble313"
                  onClick={() => {
                    setActiveMessageIndex(idx);
                    setTimeout(() => {
                      setActiveMessageIndex(null);
                    }, 3000);
                  }}
                >
                  <span className="chat-user313">
                    {isSender ? currentUserFirstName : otherUserFirstName}
                  </span>

                  {msg.replyTo && (
                    <div className="quoted-message313">
                      <strong>{msg.replyTo.senderName}:</strong> {msg.replyTo.text}
                    </div>
                  )}

                  {/* If there's an image, show it and make clickable */}
                  {msg.imageUrl && (
                    <img
                      src={msg.imageUrl}
                      alt="Attachment"
                      className="chat-image-attachment"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleImageClick2(msg.imageUrl);
                      }}
                    />
                  )}

                  {/* If there's text */}
                  {msg.message && (
                    <p className="chat-message-text313">{msg.message}</p>
                  )}
                </div>

                {showMeta && (
                  <div className="chat-meta-row313">
                    <div className="chat-date313">{dateString}</div>
                    <p
                      className="reply-btn313"
                      onClick={() => handleReply(msg, idx)}
                    >
                      Reply
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {/* If replying */}
      {replyingTo && (
        <div className="replying-to-bar313">
          <div className="replying-to-text313">
            Replying to <strong>{replyingTo.senderName}:</strong> “{replyingTo.text}”
          </div>
          <button
            className="cancel-reply-btn313"
            onClick={() => setReplyingTo(null)}
          >
            &times;
          </button>
        </div>
      )}

      {/* Sticky input bar => max-width 1000px, centered */}
      <div className="business-chat-input-bar313">
        <div className="input-bar-inner313">
          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputAttachRef2}
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange2}
          />

          <p className="attach-btn313" type="button" onClick={handleAttachClick2}>
            <img src={paperclipIcon} alt="Attach" />
          </p>

          {/* If preview => show preview container */}
          {previewDataUrl2 ? (
            <div className="preview-container313">
              <img src={previewDataUrl2} alt="Preview" className="preview-image313" />
              <div className="preview-buttons313">
                <button onClick={handleConfirmPreview2}>Send</button>
                <button onClick={handleCancelPreview2}>Cancel</button>
              </div>
            </div>
          ) : (
            <>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={`Chat with ${businessName}...`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
              />
              <p className="send-btn313" type="button" onClick={handleSendMessage}>
                <img src={sendIcon} alt="Send" />
              </p>
            </>
          )}
        </div>
      </div>

      {/* Fullscreen overlay for expanded image */}
      {expandedImageUrl2 && (
        <div className="image-modal-overlay" onClick={closeExpandedImage2}>
          <div
            className="image-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={expandedImageUrl2}
              alt="Full size"
              className="image-modal-img"
              style={{
                transform: `scale(${zoomScale2}) translate(${translateX2}px, ${translateY2}px)`,
                cursor: zoomScale2 > 1 ? 'grab' : 'zoom-in',
              }}
              onClick={toggleZoom2}
              onMouseDown={(e) => {
                if (zoomScale2 > 1) {
                  setIsDragging2(true);
                  setDragStartX2(e.clientX - translateX2);
                  setDragStartY2(e.clientY - translateY2);
                }
              }}
              onMouseMove={(e) => {
                if (!isDragging2) return;
                setTranslateX2(e.clientX - dragStartX2);
                setTranslateY2(e.clientY - dragStartY2);
              }}
              onMouseUp={() => setIsDragging2(false)}
              onMouseLeave={() => setIsDragging2(false)}
            />
            <button className="image-modal-close" onClick={closeExpandedImage2}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default StudentChatPage;
