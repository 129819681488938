import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';
import logo from '../images/logo6.png';

// Import your social images
import xIcon from '../images/x.png';
import youtubeIcon from '../images/youtube.png';
import instagramIcon from '../images/instagram.png';
import linkedinIcon from '../images/linkedin.png';

const Footer = () => {
  const navigate = useNavigate();

  // Helper function: click logo => navigate home + scroll top
  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer">
      {/** Left side: Logo **/}
      <div className="footer-left">
        <div className="footer-logo" onClick={handleLogoClick}>
          <img src={logo} alt="Moccet Logo" />
        </div>
      </div>

      {/** Center: Social media images **/}
      <div className="footer-center">
        {/** Replace # with actual social links **/}
        <a href="https://www.x.com/moccet?s=11&t=qkm95Z77uwqWsEbA0Y5Flw" aria-label="X (formerly Twitter)">
          <img src={xIcon} alt="X" />
        </a>
        <a href="https://www.youtube.com/@moccet" aria-label="YouTube">
          <img src={youtubeIcon} alt="YouTube" />
        </a>
        <a href="https://www.instagram.com/m.occet" aria-label="Instagram">
          <img src={instagramIcon} alt="Instagram" />
        </a>
        <a href="https://www.linkedin.com/feed/update/urn:li:activity:7278897073502990336/?actorCompanyId=105261965" aria-label="LinkedIn">
          <img src={linkedinIcon} alt="LinkedIn" />
        </a>
      </div>

      {/** Right side: Grid columns **/}
      <div className="footer-right">
        <div className="footer-column">
          <h3>Platform</h3>
          <ul>
            <li><a href="/#/features">Features</a></li>
            <li><a href="/#/integrations">Integrations</a></li>
            <li><a href="/#/API-reference">API Reference</a></li>
            <li><a href="/#/case-studies">Case Studies</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Resources</h3>
          <ul>
            <li><a href="/#/documentation">Documentation</a></li>
            <li><a href="/#/Blogs">Blog</a></li>
            <li><a href="/#/guides">Guides and Tutorials</a></li>
            <li><a href="/#/status">Status</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Company</h3>
          <ul>
            <li><a href="/#/about">About Moccet</a></li>
            <li><a href="/#/press">Press& News</a></li>
            <li><a href="/#/careers">Careers</a></li>
            <li><a href="/#/partners">Partners</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Support</h3>
          <ul>
            <li><a href="/#/contact-us">Contact Us</a></li>
            <li><a href="/#/security">Security</a></li>
            <li><a href="/#/responsible">Responsible Use</a></li>
            <li><a href="/#/compliance">Compliance</a></li>
          </ul>
        </div>
      </div>

      {/** Bottom row **/}
      <div className="footer-bottom">
        <p>© 2024 Moccet. All rights reserved.</p>
        <div className="bottom-links">
          <p onClick={() => navigate('/terms')}>Terms of Service</p>
          <p onClick={() => navigate('/privacy')}>Privacy Policy</p>
          <p onClick={() => navigate('/usage-policy')}>Usage Policy</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
