import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './ValueProposition.css';
import backgroundVideo from '../../videos/moccet.mp4';

gsap.registerPlugin(ScrollTrigger);

export default function ValueProposition() {
  // Refs
  const containerRef = useRef(null);
  const headingRef = useRef(null);
  const subheadingRef = useRef(null);
  const stepsRef = useRef([]);
  const videoContainerRef = useRef(null);

  // Data for the steps
  const steps = [
    {
      number: '/Step One',
      title: 'Create your Brief',
      description: 'Moccet’s AI shapes your idea into a clear plan—instantly.',
    },
    {
      number: '/Step Two',
      title: 'Connect to Talent',
      description:
        'We match you with the ideal expert or micro-team—no guesswork.',
    },
    {
      number: '/Step Three',
      title: 'Project Delivered',
      description:
        'Track transparent milestones, then receive exactly what you envisioned.',
    },
  ];

  // =========================================
  // Split "How it Works" into words & letters
  // while preserving spaces between words.
  // =========================================
  const headingText = 'How it Works'; 
  // Split into words: ["How", "it", "Works"]
  const words = headingText.split(' ');

  // For each word, we further split into letters
  // and then add a trailing space (if it's not the last word).
  const wordLetters = words.map((word, wordIdx) => {
    // Split each word into letters
    const lettersArray = word.split('').map((char, charIdx) => (
      <span key={`word-${wordIdx}-char-${charIdx}`} className="letter">
        {char}
      </span>
    ));

    // Return an array of letters plus a space after each word (except the last)
    return (
      <React.Fragment key={wordIdx}>
        {lettersArray}
        {wordIdx < words.length - 1 && (
          <span className="letter-space">&nbsp;</span>
        )}
      </React.Fragment>
    );
  });

  useEffect(() => {
    // ==================================================
    // 1) Parallax-like background & container fade
    // ==================================================
    gsap.fromTo(
      containerRef.current,
      { backgroundColor: '#e6e8f8', opacity: 0 },
      {
        backgroundColor: '#f8f9fd',
        opacity: 1,
        duration: 1.5,
        ease: 'power1.inOut',
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 80%',
          onUpdate: (self) => {
            // Parallax trick: adjusting backgroundPositionY based on scroll progress
            const progress = self.progress.toFixed(2); // 0 -> 1
            containerRef.current.style.backgroundPositionY = `${progress * 50}px`;
          },
        },
      }
    );

    // ========================================
    // 2) Letter-by-letter Heading Reveal
    // ========================================
    // We can select all spans with the class .letter or .letter-space
    const letterSpans = headingRef.current.querySelectorAll('.letter, .letter-space');
    gsap.fromTo(
      letterSpans,
      { y: 40, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.6,
        stagger: 0.05,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: headingRef.current,
          start: 'top 85%',
        },
      }
    );

    // ========================================
    // 3) Subheading Fade + Slide
    // ========================================
    gsap.fromTo(
      subheadingRef.current,
      { y: 40, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.0,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: subheadingRef.current,
          start: 'top 85%',
        },
      }
    );

    // ========================================
    // 4) Steps 3D Flip & Fade
    // ========================================
    stepsRef.current.forEach((step, index) => {
      const stepNumberEl = step.querySelector('.hiw-step-number');
      const stepTitleEl = step.querySelector('.hiw-step-title');
      const stepDescEl = step.querySelector('.hiw-step-description');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: step,
          start: 'top 90%',
        },
      });

      // Step container flip
      tl.fromTo(
        step,
        { opacity: 0, rotateY: 90 },
        {
          opacity: 1,
          rotateY: 0,
          transformOrigin: 'left center',
          duration: 0.8,
          ease: 'back.out(1.7)',
        }
      );

      // Step number
      tl.fromTo(
        stepNumberEl,
        { y: 20, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
        },
        '-=0.4'
      );

      // Step title
      tl.fromTo(
        stepTitleEl,
        { y: 20, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
        },
        '-=0.3'
      );

      // Step description
      tl.fromTo(
        stepDescEl,
        { y: 20, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
        },
        '-=0.3'
      );
    });

    // ========================================
    // 5) Video Container Scale-In
    // ========================================
    gsap.fromTo(
      videoContainerRef.current,
      { scale: 0.8, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        duration: 1.2,
        ease: 'expo.out',
        scrollTrigger: {
          trigger: videoContainerRef.current,
          start: 'top 90%',
        },
      }
    );
  }, []);

  return (
    <div className="container1234" ref={containerRef}>
      <section className="how-it-works">
        <h2 className="hiw-heading" ref={headingRef}>
          {wordLetters}
        </h2>

        <p className="hiw-subheading" ref={subheadingRef}>
          Moccet is the world’s most advanced project completion platform.
        </p>

        <div className="hiw-steps">
          {steps.map((step, i) => (
            <div
              className="hiw-step"
              key={i}
              ref={(el) => (stepsRef.current[i] = el)}
            >
              <h3 className="hiw-step-number">{step.number}</h3>
              <h4 className="hiw-step-title">{step.title}</h4>
              <p className="hiw-step-description">{step.description}</p>
            </div>
          ))}
        </div>

        <div className="hiw-video-container" ref={videoContainerRef}>
          <video autoPlay loop muted playsInline className="hiw-video">
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </div>
      </section>
    </div>
  );
}
