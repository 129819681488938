import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./Partners.css";

const Partners = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="partnersContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="partnersHeaderSection">
          <h1 className="partnersPageTitle">PARTNERS</h1>
        </div>

        {/* Overview */}
        <section className="partnersOverviewSection">
          <h2 className="sectionSubtitle">Overview</h2>
          <p className="sectionText">
            Through strategic partnerships, Moccet integrates with or
            complements other solutions. By aligning with like-minded entities,
            we seek to broaden the scope and scale of AI-driven project
            execution.
          </p>
        </section>

        {/* Partnership Types */}
        <section className="partnershipTypesSection">
          <h2 className="sectionSubtitle">Partnership Types</h2>

          <div className="partnershipItem">
            <h3 className="partnershipTitle">1. Technology Alliances</h3>
            <p className="partnershipDescription">
              Joint R&amp;D or co-marketing with major software vendors.
            </p>
          </div>

          <div className="partnershipItem">
            <h3 className="partnershipTitle">
              2. Consultancies &amp; Integrators
            </h3>
            <p className="partnershipDescription">
              Firms that embed Moccet’s capabilities in their service offerings.
            </p>
          </div>

          <div className="partnershipItem">
            <h3 className="partnershipTitle">
              3. Educational &amp; Research Institutions
            </h3>
            <p className="partnershipDescription">
              Academic labs that refine AI algorithms for scoping, scheduling,
              and synergy analysis.
            </p>
          </div>
        </section>

        {/* Partner Accountability */}
        <section className="partnerAccountabilitySection">
          <h2 className="sectionSubtitle">Partner Accountability</h2>
          <ul className="partnerAccountabilityList">
            <li>
              Moccet disclaims liability for misrepresentations made by partners
              to end users. Our brand usage guidelines must be followed to avoid
              trademark dilution.
            </li>
            <li>
              In the event of a partner breach or noncompliance, Moccet can
              suspend or terminate collaboration without incurring liability for
              partner or user damages.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Partners;
