import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";              // <-- Import GSAP
import "./Features.css";              // <-- Import your stylesheet

const Features = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);
  const featureCardsRef = useRef([]); // Will store refs for each feature card

  // Simulate a loading period (2 seconds here)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Once loading is done, animate content into view
  useEffect(() => {
    if (!isLoading) {
      // Fade in the entire main content
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );

      // Stagger the feature cards individually
      gsap.fromTo(
        featureCardsRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
          stagger: 0.1, // each card animates 0.1s apart
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="featuresContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (initially hidden, then animated with GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="featuresHeaderSection">
          <h1 className="featuresPageTitle">FEATURES</h1>
        </div>

        {/* Overview */}
        <section className="featuresOverviewSection">
          <h2 className="sectionSubtitle">Overview</h2>
          <p className="sectionText">
            Moccet’s platform merges advanced AI capabilities with an exclusive network
            of elite talent, all in one centralized environment. Our system
            incorporates project scoping, budgeting, automated recruitment,
            real-time collaboration, and tracked deliverables. By providing
            these features under a single platform, we aim to streamline the
            entire project lifecycle for our users.
          </p>
        </section>

        {/* Key Features */}
        <section className="featuresListSection">
          <h2 className="sectionSubtitle">
            Key Features (With Protective Language)
          </h2>

          {/* Feature 1 */}
          <div
            className="featureCard"
            ref={(el) => (featureCardsRef.current[0] = el)}
          >
            <h3 className="featureTitle">1. AI-Led Project Scoping</h3>
            <p className="featureDescription">
              Moccet’s artificial intelligence engine analyzes your initial ideas or
              briefs, then generates an actionable plan with timelines, key
              milestones, and estimated budgets.
            </p>
            <p className="protectiveDisclaimer">
              <strong>Protective Disclaimer:</strong> While the AI leverages
              industry data and best practices, actual outcomes may vary
              significantly based on user inputs, third-party constraints, and
              market fluctuations. Moccet provides these scoping guidelines “as
              is,” without warranties of any kind, and disclaims any liability
              for project failures, delays, or unforeseen costs.
            </p>
          </div>

          {/* Feature 2 */}
          <div
            className="featureCard"
            ref={(el) => (featureCardsRef.current[1] = el)}
          >
            <h3 className="featureTitle">2. Precision Talent Matching</h3>
            <p className="featureDescription">
              Our system ranks and recommends suitable professionals based on
              specialized algorithms that factor in domain expertise, track
              records, and up-to-date availability.
            </p>
            <p className="protectiveDisclaimer">
              <strong>Protective Disclaimer:</strong> Moccet does not guarantee
              the availability of specific professionals, nor does it warrant
              that the chosen expert (or experts) will deliver any particular
              result or meet external compliance standards. All engagements
              remain between the user and the engaged parties. Moccet merely
              provides facilitative technology.
            </p>
          </div>

          {/* Feature 3 */}
          <div
            className="featureCard"
            ref={(el) => (featureCardsRef.current[2] = el)}
          >
            <h3 className="featureTitle">
              3. Real-Time Collaboration &amp; Dashboards
            </h3>
            <p className="featureDescription">
              Moccet centralizes communication, resource-sharing, and
              AI-generated insights in a unified dashboard. Users can assign
              tasks, track progress, and refine deliverables in real time.
            </p>
            <p className="protectiveDisclaimer">
              <strong>Protective Disclaimer:</strong> Although Moccet strives to
              maintain a secure and reliable environment, we disclaim liability
              for data breaches, lost files, or unauthorized access caused by
              hacking attempts, user negligence (e.g., password sharing), or any
              other unforeseen circumstances.
            </p>
          </div>

          {/* Feature 4 */}
          <div
            className="featureCard"
            ref={(el) => (featureCardsRef.current[3] = el)}
          >
            <h3 className="featureTitle">
              4. Milestone Management &amp; Budget Oversight
            </h3>
            <p className="featureDescription">
              Our built-in budgeting tool helps approximate costs linked to
              scope changes or added deliverables, while milestone tracking
              keeps everyone accountable.
            </p>
            <p className="protectiveDisclaimer">
              <strong>Protective Disclaimer:</strong> Moccet cannot guarantee
              that these budget or milestone estimates reflect your eventual
              real-world expenses or legal responsibilities. Users acknowledge
              that unexpected expenses or legal obligations may arise. Moccet
              disclaims liability for any financial or legal shortfalls
              incurred.
            </p>
          </div>
        </section>

        {/* Legal Compliance & Data Handling */}
        <section className="legalSection">
          <h2 className="sectionSubtitle">Legal Compliance &amp; Data Handling</h2>
          <ul className="legalPoints">
            <li>
              <strong>Data Privacy:</strong> All personal data processing
              aligns with U.S. federal law, including the Electronic
              Communications Privacy Act (ECPA) and, where applicable, state
              data privacy legislation (such as the California Consumer Privacy
              Act, CCPA).
            </li>
            <li>
              <strong>Disclaimer of Performance Guarantees:</strong> Users
              accept that no feature is a guarantee of success. Moccet is not
              responsible for missed deadlines, scope miscalculations, or
              incomplete deliverables—particularly in cases of force majeure or
              user error.
            </li>
          </ul>
        </section>

        {/* Limitation of Liability */}
        <section className="liabilitySection">
          <h2 className="sectionSubtitle">Limitation of Liability</h2>
          <p className="sectionText">
            To the fullest extent permissible under U.S. law, we disclaim
            liability for:
          </p>
          <ul className="liabilityPoints">
            <li>Indirect or consequential damages</li>
            <li>Economic losses or lost profits</li>
            <li>
              Non-fulfillment of project expectations due to third-party
              dependencies
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Features;
