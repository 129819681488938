import React, { useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { getDoc, setDoc, doc } from 'firebase/firestore';
import './MergedSignUp.css';
import appleLogo from '../../images/apple.png';
import googleLogo from '../../images/google.png';

function MergedSignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const projectData = location.state || {};

  const [isStudent, setIsStudent] = useState(false); // Toggle between Student (Harvard/MIT) and Business
  const [step, setStep] = useState(1); // Track the current step

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  // Student-specific fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [university, setUniversity] = useState('');
  const [degree, setDegree] = useState('');
  const [year, setYear] = useState('');
  const [skills, setSkills] = useState('');
  const [previousProjects, setPreviousProjects] = useState('');

  const handleShowTerms = () => {
    setShowTermsPopup(true);
  };

  const handleCloseTerms = () => {
    setShowTermsPopup(false);
  };

  const handleNext = () => {
    setError('');
    // Step logic:
    // step 1: email -> validate email not empty
    // step 2: password -> validate password not empty
    // step 3 (only if isStudent): student fields
    // step 4: final sign up attempt (handleSignUp)

    if (step === 1) {
      if (email.trim().length === 0) {
        setError('Please enter your email.');
        return;
      }
      setStep(2);
    } else if (step === 2) {
      if (password.trim().length === 0) {
        setError('Please enter your password.');
        return;
      }
      if (isStudent) {
        setStep(3);
      } else {
        // If business, go directly to step 4 (final sign up)
        setStep(4);
      }
    } else if (step === 3) {
      // Student additional fields step
      // Move directly to final step
      setStep(4);
    } else if (step === 4) {
      // Final step: sign up
      handleSignUp();
    }
  };

  const handleSignUp = async () => {
    setError('');
    setMessage('');
    if (!termsAccepted) {
      setError('You must agree to the Terms & Conditions prior to sign up.');
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      const userInfo = {
        email,
        role: isStudent ? 'student' : 'business',
        roleAssigned: false,
      };
  
      if (isStudent) {
        userInfo.firstName = firstName;
        userInfo.lastName = lastName;
        userInfo.university = university;
        userInfo.degree = degree;
        userInfo.year = year;
        userInfo.skills = skills;
        userInfo.previousProjects = previousProjects;
      }
  
      await setDoc(doc(db, 'users', user.uid), userInfo);
  
      // Immediately log in the user
      await signInWithEmailAndPassword(auth, email, password);
  
      // Navigate to the Stripe payment page
      navigate('/payment', {
        state: {
          ...projectData,
        },
      });
    } catch (error) {
      console.error('Error signing up:', error);
  
      // Check if email is already in use
      if (error.code === 'auth/email-already-in-use') {
        setError(
          <div>
            <p>This email is already in use.</p>
            <p>
              <Link to="/signin">Sign in</Link> instead.
            </p>
          </div>
        );
      } else {
        setError('Error signing up. Please try again.');
      }
    }
  };
  

  const handleGoogleSignIn = async () => {
    setError('');
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        const userInfo = {
          email: user.email,
          role: isStudent ? 'student' : 'business',
          roleAssigned: false,
        };
        await setDoc(userDocRef, userInfo);
      }

      navigate('/payment', {
        state: {
          ...projectData,
        },
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Error signing in with Google. Please try again.');
    }
  };

  const handleAppleSignIn = async () => {
    setError('');
    try {
      const provider = new OAuthProvider('apple.com');
      await signInWithPopup(auth, provider);

      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (!userDocSnap.exists()) {
          const userInfo = {
            email: user.email,
            role: isStudent ? 'student' : 'business',
            roleAssigned: false,
          };
          await setDoc(userDocRef, userInfo);
        }
      }

      navigate('/payment');
    } catch (error) {
      console.error('Error signing in with Apple:', error);
      setError('Error signing in with Apple. Please try again.');
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>

      {/* Toggle between Harvard/MIT (Student) and Business */}
      <div style={{ marginBottom: '20px', fontSize: '1.2rem', display: 'flex', gap: '20px' }}>
        <span
          style={{ cursor: 'pointer', fontWeight: isStudent ? 'bold' : 'normal' }}
          onClick={() => setIsStudent(true)}
        >
          Harvard/MIT
        </span>
        <span
          style={{ cursor: 'pointer', fontWeight: !isStudent ? 'bold' : 'normal' }}
          onClick={() => setIsStudent(false)}
        >
          Business
        </span>
      </div>

      {/* Step 1: Email */}
      {step >= 1 && (
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      )}

      {/* Step 2: Password */}
      {step >= 2 && (
        <input
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      )}

      {/* Step 3 (only if student): Additional fields */}
      {isStudent && step >= 3 && (
        <>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <input
            type="text"
            placeholder="University"
            value={university}
            onChange={(e) => setUniversity(e.target.value)}
          />
          <input
            type="text"
            placeholder="Degree"
            value={degree}
            onChange={(e) => setDegree(e.target.value)}
          />
          <input
            type="text"
            placeholder="Graduation Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
          <input
            type="text"
            placeholder="Skills"
            value={skills}
            onChange={(e) => setSkills(e.target.value)}
          />
          <input
            type="text"
            placeholder="Previous Projects"
            value={previousProjects}
            onChange={(e) => setPreviousProjects(e.target.value)}
          />
        </>
      )}

      {/* Terms and Conditions always visible */}
      <div className="terms-checkbox" style={{ marginTop: '20px' }}>
        <label>
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          I agree to Moccet’s&nbsp;<span className="terms-link" onClick={handleShowTerms}>Terms & Conditions</span>.
        </label>
      </div>

      {/* Continue button always visible, proceeds through steps or final sign up */}
      <button className="continue-button" onClick={handleNext}>
        {step < 4 ? 'Continue' : 'Continue'}
      </button>
      
      {error && <p className="error-text">{error}</p>}
      {message && <p className="success-text">{message}</p>}

      <hr className="divider" />

      {/* Apple and Google sign-in always visible */}
      <button className="social-button apple-button" onClick={handleAppleSignIn}>
        <img src={appleLogo} alt="Apple Logo" className="social-logo" />
        Continue with Apple
      </button>
      <button className="social-button google-button" onClick={handleGoogleSignIn}>
        <img src={googleLogo} alt="Google Logo" className="social-logo" />
        Continue with Google
      </button>

      {showTermsPopup && (
        <div className="terms-popup">
          <div className="terms-popup-content">
            <div className="terms-header">
              <h2>Terms and Conditions</h2>
              <span className="close-popup" onClick={handleCloseTerms}>&times;</span>
            </div>
            <div className="terms-content">
              {/* Paste the terms and conditions here */}
              <h2>Business Terms and Conditions</h2>
              <p>(Place your terms and conditions here)</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MergedSignUp;
