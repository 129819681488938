import React from "react";
import { motion } from "framer-motion";
import "./LoaderOverlay.css"; // We'll define minimal CSS below

const LoaderOverlay = () => {
  // Framer Motion variants for the overlay backdrop
  const backdropVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.4 } },
    exit: { opacity: 0, transition: { duration: 0.4 } },
  };

  // Framer Motion variants for the logo
  const logoVariants = {
    initial: { scale: 0.8, opacity: 0 },
    animate: {
      scale: [0.8, 1, 0.9, 1],
      opacity: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,   // or remove if you only want it once
        repeatType: "reverse",
      },
    },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.2 } },
  };

  return (
    <motion.div
      className="loaderBackdrop"
      variants={backdropVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <motion.div className="loaderLogoContainer" variants={logoVariants}>
        <img
          src={require("../images/logo5.png")} /* or "images/myLogo.png" depending on your setup */
          alt="My Logo"
          className="loaderLogoImage"
        />
      </motion.div>
    </motion.div>
  );
};

export default LoaderOverlay;
