import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./Security.css";

const Security = () => {
  const [isLoading, setIsLoading] = useState(true);
  const mainContentRef = useRef(null);

  // Simulate a loading period (2 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Animate content once loading completes
  useEffect(() => {
    if (!isLoading) {
      gsap.fromTo(
        mainContentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        }
      );
    }
  }, [isLoading]);

  return (
    <div className="securityContainer">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="loadingSpinner" />
        </div>
      )}

      {/* Main Content (animated via GSAP) */}
      <div className="mainContent" ref={mainContentRef}>
        {/* Page Header */}
        <div className="securityHeaderSection">
          <h1 className="securityPageTitle">SECURITY</h1>
        </div>

        {/* Security Measures */}
        <section className="securityMeasuresSection">
          <h2 className="sectionSubtitle">Security Measures</h2>
          <p className="sectionText">
            Moccet invests heavily in cybersecurity. We frequently patch
            vulnerabilities, run penetration tests, and encrypt data at rest and
            in transit. Our policies reflect guidelines from the National
            Institute of Standards and Technology (NIST).
          </p>
        </section>

        {/* Shared Responsibility */}
        <section className="sharedResponsibilitySection">
          <h2 className="sectionSubtitle">Shared Responsibility</h2>
          <ul className="sharedResponsibilityList">
            <li>
              Users are responsible for creating robust passwords, safeguarding
              login credentials, and ensuring their own local devices meet basic
              security standards (anti-malware, firewalls, etc.).
            </li>
            <li>
              We disclaim liability for user lapses, external hacking incidents,
              or catastrophic security breaches that are beyond our immediate
              control.
            </li>
          </ul>
        </section>

        {/* Incident Response */}
        <section className="incidentResponseSection">
          <h2 className="sectionSubtitle">Incident Response</h2>
          <p className="sectionText">
            If a breach significantly impacts user data, we will notify affected
            parties per state or federal data-breach notification laws. We
            disclaim liability for reputational harm, lost profits, or financial
            losses stemming from any breach, insofar as permissible by law.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Security;
